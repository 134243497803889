import { Chip } from '@mui/material';
import { useMemo } from 'react';

import { ContentTypeStatus } from '../../services/models/content-type-status';

function ContentStatusChip({
  content,
}: {
  content: {
    status?: ContentTypeStatus;
  };
}) {
  const color = useMemo(() => {
    switch (content.status) {
      case ContentTypeStatus.Draft:
        return 'warning';
      case ContentTypeStatus.Deleted:
      case ContentTypeStatus.Deprecated:
        return 'error';
      case ContentTypeStatus.Released:
        return 'primary';
      case ContentTypeStatus.Final:
        return 'success';
    }

    return undefined;
  }, [content.status]);

  return <Chip sx={{ minWidth: 92 }} label={content.status?.toUpperCase()} color={color} />;
}

export default ContentStatusChip;
