import { faAngleLeft, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppBar, Toolbar, IconButton, Button, Stack, Menu, MenuItem, Box } from '@mui/material';
import clsx from 'clsx';
import { useConfirm } from 'material-ui-confirm';
import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { confirmLogout } from '../../../components/logout';
import { useSelectorUserData } from '../../../redux/selector';
import { setSidebarCollapsed } from '../../../redux/slices/sidebar-collapse.slice';
import { clearUserData } from '../../../redux/slices/user-data.slice';
import { RootState } from '../../../redux/store';
import { AuthService } from '../../../services/auth.service';
import { AppBarHeaderMinHeight } from '../../../styles/app-bar';
import GlobalSearch from './global-search';

import './index.scss';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface HeaderProps {}

// eslint-disable-next-line no-empty-pattern
const MainHeader: FunctionComponent<HeaderProps> = ({ ...props }) => {
  const [rotated, setRotate] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { sidebarCollapsed } = useSelector((state: RootState) => state.sidebarCollapsed);
  const { userData } = useSelectorUserData();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const authService = useMemo(() => new AuthService(), []);

  const openMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const closeMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onLogout = useCallback(async () => {
    const { dialog } = await confirmLogout(confirm);
    dialog
      .then(async () => {
        closeMenu();
        await authService.logout();
        dispatch(clearUserData());
        navigate('/', { replace: true });
      })
      .catch(() => {
        closeMenu();
        /* ... */
      });
  }, [authService, closeMenu, confirm, dispatch, navigate]);

  const gotoProfile = useCallback(() => {
    closeMenu();
    navigate('/user/profile');
  }, [closeMenu, navigate]);

  const toggleCollapse = useCallback(() => {
    dispatch(setSidebarCollapsed(!sidebarCollapsed));
    setRotate(!rotated);
  }, [dispatch, sidebarCollapsed, rotated]);

  return (
    <AppBar
      position='static'
      color='default'
      sx={{ maxHeight: AppBarHeaderMinHeight, bgcolor: 'background.paper' }}
      elevation={0}
    >
      <Toolbar disableGutters sx={{ height: AppBarHeaderMinHeight }}>
        <Button onClick={toggleCollapse} size='small'>
          <FontAwesomeIcon
            width='1rem'
            icon={faAngleLeft}
            className={clsx({ rotateArrow: rotated, 'arrow-button': true })}
          />
        </Button>
        <Box sx={{ mx: 2, width: '100%', justifyContent: 'center', display: 'flex' }}>
          <GlobalSearch />
        </Box>
        <Stack alignContent='center' sx={{ mx: 1 }}>
          <IconButton
            size='large'
            aria-label='account of current user'
            aria-controls='menu-appbar'
            aria-haspopup='true'
            onClick={openMenu}
            color='inherit'
          >
            <FontAwesomeIcon icon={faUserCircle} />
          </IconButton>
          <Menu
            id='menu-appbar'
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={Boolean(anchorEl)}
            onClose={closeMenu}
          >
            <MenuItem
              key='profile'
              onClick={() => {
                gotoProfile();
              }}
            >
              Profile
            </MenuItem>
            <MenuItem key='logout' onClick={() => onLogout()}>
              Logout
            </MenuItem>
          </Menu>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
export default MainHeader;
