import { useConfirm } from 'material-ui-confirm';
import { FunctionComponent, useCallback } from 'react';

import { ContentTable } from '../../components/content-table';
import { RiskDto } from '../../services/dto/risks/risk.dto';
import { RulesetDto } from '../../services/dto/rulesets/ruleset.dto';
import { confirmTrashRulesets } from './delete-dialogs';
import { RulesetsOrderBy, sortRulesets } from './rulesets-table-head';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RulesetsTableProps {
  rulesets: RulesetDto[] | null;
  totalCount: number;
  massDelete: (selectedIds: string[]) => void;
  openCreateRuleset: () => void;
  openEditRuleset: (ruleset: RulesetDto) => void;
  openCreateNewVersionRuleset: (data: RulesetDto) => void;
  openTrashRuleset: (data: RulesetDto) => void;
  openRestoreRuleset: (data: RulesetDto) => void;
  loadChildren?: (data: RulesetDto) => Promise<RiskDto[]>;
}

export const RulesetsTable: FunctionComponent<RulesetsTableProps> = ({
  rulesets,
  totalCount,
  massDelete,
  openCreateRuleset,
  openEditRuleset,
  openTrashRuleset,
  openRestoreRuleset,
  openCreateNewVersionRuleset,
  loadChildren,
  /*...props*/
}) => {
  const confirm = useConfirm();

  const onMassTrash = useCallback(
    async (rulesetIds: string[]) => {
      const { dialog } = await confirmTrashRulesets(confirm, rulesetIds);
      return dialog
        .then(() => {
          massDelete(rulesetIds);
          return true;
        })
        .catch(() => {
          return false;
        });
    },
    [confirm, massDelete],
  );

  const getRulesetUrl = useCallback((ruleset: RulesetDto) => {
    return `/rulesets/${ruleset.id}`;
  }, []);

  return (
    <ContentTable<RulesetsOrderBy, RulesetDto, RiskDto>
      tableToolbarTitle='Rulesets'
      totalCount={totalCount}
      rows={rulesets}
      sortTable={sortRulesets}
      openCreate={openCreateRuleset}
      createButtonTitle='Create Ruleset'
      openCreateNewVersion={openCreateNewVersionRuleset}
      openEdit={openEditRuleset}
      onTrash={openTrashRuleset}
      onRestore={openRestoreRuleset}
      getDetailsUrl={getRulesetUrl}
      loadChildren={loadChildren}
      defaultOrderBy='name'
      massTrash={{
        onMassTrash: onMassTrash,
        tooltipTitle: 'Trash selected',
        iconType: 'delete',
      }}
    />
  );
};
