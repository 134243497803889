import { FunctionComponent } from 'react';
import { PropsWithChildren } from 'react';
import './index.scss';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FooterProps {}

const MainFooter: FunctionComponent<PropsWithChildren<FooterProps>> = ({ children }) => {
  return <footer className='footer-container container-fluid'>{children}</footer>;
};
export default MainFooter;
