import { FunctionComponent, HTMLAttributes, ReactNode } from 'react';
import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ReactComponent as SidebarLogo } from '../../../assets/img/header-logo-big.svg';
import logo_small from '../../../assets/img/header-logo-small.png';
//import { useSelectorUserData } from '../../../redux/selector';
import { RootState } from '../../../redux/store';

import './sidebar-header.scss';

interface SidebarHeaderProps extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  rtl?: boolean;
}

const SidebarHeader: FunctionComponent<SidebarHeaderProps> = ({ children, rtl /*...props*/ }) => {
  const { sidebarCollapsed } = useSelector((state: RootState) => state.sidebarCollapsed);
  //const { userData } = useSelectorUserData();
  const bigIconSize = '50%';
  const smallIconSize = '100%';
  return (
    <>
      {sidebarCollapsed && (
        <Container className='sidebar-header'>
          <Link to='/dashboard'>
            <img width={smallIconSize} height={smallIconSize} src={logo_small} />
          </Link>
        </Container>
      )}
      {!sidebarCollapsed && (
        <Container className='sidebar-header' style={{ alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ height: bigIconSize, width: bigIconSize }}>
            <Link to='/dashboard'>
              <SidebarLogo />
            </Link>
          </div>
        </Container>
      )}
    </>
  );
};

export default SidebarHeader;
