import { Breadcrumbs, Link, Typography } from '@mui/material';
import { FunctionComponent } from 'react';

import { RulesetDto } from '../../services/dto/rulesets/ruleset.dto';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RulesetBreadcrumbsProps {
  ruleset: RulesetDto;
}

const RulesetBreadcrumbs: FunctionComponent<RulesetBreadcrumbsProps> = ({ ruleset /*...props*/ }) => {
  return (
    <Breadcrumbs separator='›' aria-label='breadcrumb'>
      <Link underline='hover' color='inherit' href={`/rulesets`}>
        Rulesets
      </Link>
      <Typography color='text.primary'>{ruleset.name}</Typography>
    </Breadcrumbs>
  );
};

export default RulesetBreadcrumbs;
