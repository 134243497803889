import { GridColDef, GridFilterInputValue, GridFilterItem, getGridStringOperators } from '@mui/x-data-grid';

export function extendStringFilterOperators() {
  return [
    ...getGridStringOperators(),
    {
      value: 'doesntContain',
      getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
        if (!filterItem.value) {
          return null;
        }

        const filterRegex = new RegExp(filterItem.value, 'i');
        return (params: any): boolean => {
          return !filterRegex.test(params.value?.toString() || '');
        };
      },
      InputComponent: GridFilterInputValue,
    },
    {
      value: 'doesntEqual',
      getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
        if (!filterItem.value) {
          return null;
        }

        return (params: any): boolean => {
          return params.value?.toString() !== filterItem.value;
        };
      },
      InputComponent: GridFilterInputValue,
    },
  ];
}
