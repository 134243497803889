import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  DialogActions,
  GridSize,
  Autocomplete,
  Box,
  Button,
  Typography,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  FormControlLabel,
} from '@mui/material';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import {
  useForm,
  FieldValues,
  UseFormRegister,
  FieldErrors,
  Controller,
  Control,
  UseFormSetValue,
} from 'react-hook-form';

import { ContentTypeStatus } from '../../services/models/content-type-status';
import { TemplateApplyButtonMinHeight } from '../../styles/content-tables';
import { childrenListMaxHeight } from '../../styles/create-update-modal';
import CreateEditModalActionButtons from '../create-edit-modal-action-buttons';

export interface ContentBaseDto {
  name: string;
  displayName: string;
  description: string;
  id: string;
}
interface CreateBaseDto {
  name: string;
  displayName: string;
  description: string;
}
export interface CreateFormData {
  name?: string;
  displayName?: string;
  description?: string;
}
interface OriginChildData {
  name: string;
  displayName: string;
  id: string;
  status: ContentTypeStatus;
}

interface CreateModalProps<
  ContentDto extends ContentBaseDto,
  FormData extends CreateFormData = any,
  ChildData extends OriginChildData | undefined = undefined,
> {
  title: string;
  open: boolean;
  onClose: () => void;
  onCreate: (data: FieldValues, origin: ContentDto | undefined, checkedChildren: string[] | undefined) => void;
  templates?: ContentDto[];
  extend?: {
    extendForm: (
      register: UseFormRegister<FormData | Record<string, any>>,
      control: Control<FormData | Record<string, any>>,
      errors: FieldErrors<FormData | Record<string, any>>,
      selectedTemplate: ContentDto | null,
    ) => { xs?: boolean | GridSize; field: ReactNode }[];
    defaultValues: FormData;
    onTemplateApplied: (
      selectedTemplate: ContentDto,
      register: UseFormRegister<FormData | Record<string, any>>,
      setValue: UseFormSetValue<FormData | Record<string, any>>,
    ) => void;
  };
  loadChildren?: (originData: ContentDto) => Promise<ChildData[]>;
  childrenTitle?: string;
}

export function CreateModal<
  ContentDto extends ContentBaseDto,
  CreateDto extends CreateBaseDto,
  FormData extends CreateFormData = any,
  ChildData extends OriginChildData | undefined = undefined,
>({
  title,
  open,
  onCreate,
  templates,
  loadChildren,
  childrenTitle,
  extend,
  ...props
}: CreateModalProps<ContentDto, FormData, ChildData>) {
  const [selectedTemplate, setSelectedTemplate] = useState<ContentDto | null>(null);
  const [origin, setOrigin] = useState<ContentDto | undefined>(undefined);
  const [templateSearch, setTemplateSearch] = useState('');
  const [children, setChildren] = useState<ChildData[] | undefined>(undefined);
  const [checkedChildren, setCheckedChildren] = useState<string[]>([]);

  const {
    register,
    setValue,
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm<FormData | Record<string, any>>({
    defaultValues: {
      name: '',
      displayName: '',
      description: '',
      keepReference: true,
      ...extend?.defaultValues,
    },
  });

  const onClose = useCallback(async () => {
    props.onClose();
    reset();
    setSelectedTemplate(null);
    setChildren(undefined);
  }, [props, reset]);

  const onSubmit = useCallback(
    async (data: FieldValues) => {
      onCreate(data, origin, checkedChildren);
      onClose();
      reset();
      setSelectedTemplate(null);
      setOrigin(undefined);
    },
    [onCreate, origin, checkedChildren, onClose, reset],
  );

  const handleTemplateSelection = useCallback(() => {
    if (selectedTemplate) {
      setValue('name', '');
      setValue('displayName', selectedTemplate.displayName);
      setValue('description', selectedTemplate.description);
      setOrigin(selectedTemplate);
      if (extend) {
        extend.onTemplateApplied(selectedTemplate, register, setValue);
      }
    }
  }, [selectedTemplate, setValue, extend, register]);

  const toggleChildren = useCallback((child: { id: string }) => {
    setCheckedChildren((checkedChildren) => {
      const currentIndex = checkedChildren.indexOf(child.id);
      const newChecked = [...checkedChildren];

      if (currentIndex === -1) {
        newChecked.push(child.id);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      return newChecked;
    });
  }, []);

  const ExtendForm = useMemo(() => {
    return extend?.extendForm(register, control, errors, selectedTemplate).map((field, index) => {
      return (
        <Grid item xs={field.xs ?? 12} key={index}>
          {field.field}
        </Grid>
      );
    });
  }, [extend, register, control, errors, selectedTemplate]);

  const fetchChildren = useCallback(async () => {
    if (loadChildren && origin) {
      setChildren(await loadChildren(origin));
    }
  }, [origin, loadChildren]);

  useEffect(() => {
    fetchChildren();
  }, [fetchChildren]);

  useEffect(() => {
    setCheckedChildren((children?.map((child) => child?.id).filter((v) => v) as string[] | undefined) ?? []);
  }, [children]);

  const registerOptions = {
    name: { required: true },
    displayName: { required: true },
    description: undefined,
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            {templates && (
              <>
                <Grid item xs={9}>
                  <Autocomplete
                    sx={{ mt: 1 }}
                    value={selectedTemplate}
                    onChange={(_, newTemplate: ContentDto | null) => {
                      if (!newTemplate) {
                        setOrigin(undefined);
                      }
                      setSelectedTemplate(newTemplate);
                    }}
                    inputValue={templateSearch}
                    onInputChange={(_, templateSearch) => {
                      setTemplateSearch(templateSearch);
                    }}
                    options={templates}
                    autoHighlight
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option) => (
                      <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        {option.name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{ shrink: true }}
                        label='Available Templates'
                        placeholder={'Select a Template'}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'template', // disable autocomplete and autofill
                        }}
                        error={!!errors.name}
                        helperText={errors.name?.message?.toString()}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Button
                    sx={{ mt: 1, minHeight: TemplateApplyButtonMinHeight, width: '100%' }}
                    disabled={!selectedTemplate}
                    variant='contained'
                    color='success'
                    startIcon={<FontAwesomeIcon icon={faCheck} />}
                    onClick={handleTemplateSelection}
                  >
                    Apply
                  </Button>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Controller
                name='name'
                control={control}
                rules={registerOptions.name}
                render={({ field }) => (
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    margin='dense'
                    label='ID*'
                    fullWidth
                    error={!!errors.name}
                    helperText={errors.name?.message?.toString()}
                    {...field}
                    onChange={(event) => {
                      field.onChange(event.target.value.toUpperCase().trim());
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name='displayName'
                control={control}
                rules={registerOptions.displayName}
                render={({ field }) => (
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    margin='dense'
                    label='Name*'
                    fullWidth
                    placeholder={selectedTemplate?.displayName}
                    error={!!errors.displayName}
                    helperText={errors.displayName?.message?.toString()}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name='description'
                control={control}
                rules={registerOptions.description}
                render={({ field }) => (
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    multiline={true}
                    minRows={3}
                    margin='dense'
                    label='Description'
                    fullWidth
                    error={!!errors.description}
                    helperText={errors.description?.message?.toString()}
                    {...field}
                  />
                )}
              />
            </Grid>
            {ExtendForm}
            {children?.length && (
              <Grid item xs={12}>
                <Typography variant='h6' gutterBottom>
                  {childrenTitle}
                </Typography>
                <List sx={{ width: '100%', maxHeight: childrenListMaxHeight, overflow: 'auto' }}>
                  {children.map((child) => {
                    if (child) {
                      const labelId = `checkbox-list-label-${child.id}`;

                      return (
                        <ListItem key={child.id} disablePadding>
                          <ListItemButton
                            role={undefined}
                            onClick={() => {
                              toggleChildren(child);
                            }}
                            dense
                          >
                            <ListItemIcon>
                              <Checkbox
                                edge='start'
                                checked={checkedChildren.includes(child.id)}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': labelId }}
                              />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={child.displayName || child.name} />
                          </ListItemButton>
                        </ListItem>
                      );
                    }
                    return <></>;
                  })}
                </List>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions sx={{ display: 'flex', alignItems: 'flex-start' }}>
          {origin && (
            <FormControlLabel
              control={
                <Controller
                  name='keepReference'
                  control={control}
                  render={({ field: { name, value, onChange } }) => (
                    <Checkbox
                      name={name}
                      checked={value}
                      onChange={(e) => {
                        onChange(e.target.checked);
                      }}
                    />
                  )}
                />
              }
              label='Keep Reference'
            />
          )}
          <CreateEditModalActionButtons onClose={onClose} />
        </DialogActions>
      </form>
    </Dialog>
  );
}
