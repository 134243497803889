import { Alert, DialogContentText, Stack } from '@mui/material';
import { ConfirmOptions } from 'material-ui-confirm';

import { RiskDto } from '../../services/dto/risks/risk.dto';
import { RulesetDto } from '../../services/dto/rulesets/ruleset.dto';
import { ContentTypeStatus } from '../../services/models/content-type-status';
import { finalizeDialogOptions, releaseDialogOptions } from '../dialog-action-buttons-options';

export async function confirmFinalizeRuleset(
  confirm: (options?: ConfirmOptions) => Promise<void>,
  ruleset: RulesetDto,
  risks: RiskDto[],
) {
  const canFinalize = risks.length > 0;
  const draftRisks = risks.filter((risk) => risk.status === ContentTypeStatus.Draft);
  const title = canFinalize ? 'Finalize the draft?' : 'Error';

  const content = (() => {
    if (canFinalize) {
      if (draftRisks.length > 0) {
        return (
          <DialogContentText>
            <Stack spacing={1}>
              <Alert severity='warning'>You wont be able to change the values of this version afterwards.</Alert>
              <Alert severity='info'>
                This Ruleset contains <strong>{draftRisks.length}</strong> Risks, which are still in draft. <br />
                These will be finalized in the process, if you wish to proceed.
              </Alert>

              {/* @TODO: show detailed list on demand (collapsible)
               Risks to be finalized:
              <List dense={true}>
          {hasDraftRisks.map((risk) => (
            <ListItem key={risk.id}>
              <ListItemText primary={risk.name} />
            </ListItem>
          ))}
        </List> */}
            </Stack>
          </DialogContentText>
        );
      }
      return (
        <DialogContentText>
          <Alert severity='warning'>You wont be able to change the values of this version afterwards.</Alert>
        </DialogContentText>
      );
    }
    return <Alert severity='error'>The Ruleset must contain at least one Risk.</Alert>;
  })();

  return {
    canFinalize,
    dialog: confirm({
      title: title,
      content: content,
      ...finalizeDialogOptions(canFinalize),
    }),
  };
}

export async function confirmReleaseRuleset(
  confirm: (options?: ConfirmOptions) => Promise<void>,
  ruleset: RulesetDto,
  risks: RiskDto[],
) {
  const canRelease = risks.length > 0;
  const finalRisks = risks.filter((risk) => risk.status === ContentTypeStatus.Final);
  const title = canRelease ? 'Release Content?' : 'Error';

  const content = (() => {
    if (canRelease) {
      if (finalRisks.length > 0) {
        return (
          <DialogContentText>
            <Stack spacing={1}>
              <Alert severity='warning'>You wont be able to change the values of this version afterwards.</Alert>
              <Alert severity='info'>
                This Ruleset contains <strong>{finalRisks.length}</strong>. <br />
                These will be released in the process, if you wish to proceed.
              </Alert>
            </Stack>
          </DialogContentText>
        );
      }
      return (
        <DialogContentText>
          <Alert severity='warning'>You wont be able to change the values of this version afterwards.</Alert>
        </DialogContentText>
      );
    }
    return <Alert severity='error'>The Ruleset must contain at least one Risk.</Alert>;
  })();

  return {
    canRelease,
    dialog: confirm({
      title: title,
      content: content,
      ...releaseDialogOptions(canRelease),
    }),
  };
}
