import { useConfirm } from 'material-ui-confirm';
import { FunctionComponent, useCallback, useMemo } from 'react';

import { RiskFunctionDto } from '../../../services/dto/functions/function.dto';
import { RiskDto } from '../../../services/dto/risks/risk.dto';
import { RiskService } from '../../../services/risk.service';
import { ContentTable } from '../../content-table';
import { confirmDeleteRisks } from '../delete-dialogs';
import { RiskContentTableExtraCells, RiskContentTableExtraHeads, RisksOrderBy, sortRisks } from '../risk-table-head';

interface GlobalRisksTableProps {
  risks: RiskDto[] | null;
  totalCount: number;
  massDelete: (selectedIds: string[]) => void;
  openEditRisk: (data: RiskDto) => void;
  openCreateNewVersionRisk: (data: RiskDto) => void;
  openCreateRisk: () => void;
  openTrashRisk: (data: RiskDto) => void;
  getRiskFunctionsFromRisk?: (data: RiskDto) => Promise<RiskFunctionDto[]>;
}

const GlobalRisksTable: FunctionComponent<GlobalRisksTableProps> = ({
  risks,
  totalCount,
  massDelete,
  openEditRisk,
  openCreateRisk,
  openCreateNewVersionRisk,
  openTrashRisk,
  getRiskFunctionsFromRisk,
  /*...props*/
}) => {
  const confirm = useConfirm();
  const riskService = useMemo(() => new RiskService(), []);

  const onMassDelete = useCallback(
    async (riskIds: string[]) => {
      const { dialog } = await confirmDeleteRisks(confirm, riskIds);
      return dialog
        .then(() => {
          massDelete(riskIds);
          return true;
        })
        .catch(() => {
          return false;
        });
    },
    [confirm, massDelete],
  );

  const getRiskUrl = useCallback((risk: RiskDto) => {
    return `/risks/${risk.id}`;
  }, []);

  return (
    <ContentTable<RisksOrderBy, RiskDto, RiskFunctionDto>
      tableToolbarTitle='Risks'
      totalCount={totalCount}
      rows={risks}
      sortTable={sortRisks}
      openCreate={openCreateRisk}
      createButtonTitle='Create Risk'
      extraCells={RiskContentTableExtraCells}
      extraHeads={RiskContentTableExtraHeads}
      openCreateNewVersion={openCreateNewVersionRisk}
      openEdit={openEditRisk}
      onDelete={openTrashRisk}
      getDetailsUrl={getRiskUrl}
      loadChildren={getRiskFunctionsFromRisk}
      massDeletion={{
        onMassDelete: onMassDelete,
        tooltipTitle: 'Delete selected',
        iconType: 'delete',
      }}
    />
  );
};

export default GlobalRisksTable;
