import { MenuItem, TextField } from '@mui/material';
import { FunctionComponent, useMemo } from 'react';
import { Control, Controller, FieldErrors, FieldValues, UseFormRegister, UseFormSetValue } from 'react-hook-form';

import { RiskDto } from '../../services/dto/risks/risk.dto';
import { UpdateRiskDto } from '../../services/dto/risks/update-risk.dto';
import UpdateModal from '../update-modal';

interface RiskFormData {
  name?: string;
  displayName?: string;
  description?: string;
  riskType?: string;
  criticality: number;
}

interface UpdateRiskModalProps {
  risk: RiskDto;
  currentOpenModal: 'create' | 'update' | 'createNewVersion' | 'trash' | 'restore' | undefined;
  closeModal: () => void;
  onRiskUpdate: (id: string, data: FieldValues, risk: RiskDto) => void;
}

const UpdateRiskModal: FunctionComponent<UpdateRiskModalProps> = ({
  risk,
  currentOpenModal,
  closeModal,
  onRiskUpdate,
  /*...props*/
}) => {
  const registerOptions = {
    riskType: { required: true },
    criticality: { required: true },
  };

  const extendRiskUpdateModal = useMemo(() => {
    return {
      extendForm: (
        register: UseFormRegister<RiskFormData | Record<string, any>>,
        control: Control<RiskFormData | Record<string, any>>,
        errors: FieldErrors<RiskFormData | Record<string, any>>,
      ) => {
        return [
          {
            xs: 6,
            field: (
              <Controller
                name='riskType'
                control={control}
                rules={registerOptions.riskType}
                defaultValue={risk.riskType}
                render={({ field }) => (
                  <TextField
                    key={'riskType'}
                    margin='dense'
                    select
                    label='Type'
                    fullWidth
                    error={!!errors.riskType}
                    helperText={errors.riskType?.message?.toString()}
                    {...field}
                  >
                    <MenuItem key={'CA'} value={'CA'}>
                      CA
                    </MenuItem>
                    <MenuItem key={'SOD'} value={'SOD'}>
                      SOD
                    </MenuItem>
                  </TextField>
                )}
              />
            ),
          },
          {
            xs: 6,
            field: (
              <Controller
                name='criticality'
                control={control}
                rules={registerOptions.criticality}
                defaultValue={risk.criticality}
                render={({ field }) => (
                  <TextField
                    placeholder={risk.criticality.toString()}
                    key={'criticality'}
                    margin='dense'
                    label='Risk Level'
                    fullWidth
                    type='number'
                    error={!!errors.criticality}
                    helperText={errors.criticality?.message?.toString()}
                    {...field}
                  />
                )}
              />
            ),
          },
        ];
      },
      defaultValues: {
        name: risk.name,
        displayName: risk.displayName,
        description: risk.description,
        riskType: risk.riskType,
        criticality: risk.criticality,
      } as RiskFormData,
      initForm: (
        data: RiskDto,
        register: UseFormRegister<RiskFormData | Record<string, any>>,
        setValue: UseFormSetValue<RiskFormData | Record<string, any>>,
      ) => {
        setValue('riskType', data.riskType);
        setValue('criticality', data.criticality);
      },
    };
  }, [
    registerOptions.criticality,
    registerOptions.riskType,
    risk.criticality,
    risk.description,
    risk.displayName,
    risk.name,
    risk.riskType,
  ]);

  return (
    <UpdateModal<RiskDto, UpdateRiskDto, RiskFormData>
      title='Update Risk'
      data={risk}
      open={currentOpenModal === 'update'}
      onClose={closeModal}
      onUpdate={onRiskUpdate}
      extend={extendRiskUpdateModal}
    />
  );
};

export default UpdateRiskModal;
