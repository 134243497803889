import { BusinessProcessDto } from './dto/business-process/business-process.dto';
import { HttpService } from './http.service';

export class BusinessProcessService {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(private httpService: HttpService = new HttpService('business-processes')) {}

  async getBusinessProcesses(params?: {
    search?: string;
    page?: {
      offset?: number;
      limit?: number;
    };
    //sort?: string;
  }) {
    const response = await this.httpService.get<BusinessProcessDto[]>(undefined, params);
    return response.data;
  }
}
