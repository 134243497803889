import { FunctionComponent } from 'react';
import { PropsWithChildren } from 'react';
import { Outlet } from 'react-router-dom';

import './index.scss';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface MainProps {}

const MainContent: FunctionComponent<PropsWithChildren<MainProps>> = ({ children }) => {
  return (
    <main className='content-container m-3 '>
      <Outlet />
    </main>
  );
};
export default MainContent;
