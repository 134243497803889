import { useConfirm } from 'material-ui-confirm';
import { FunctionComponent, useCallback } from 'react';

import { RiskFunctionDto } from '../../../services/dto/functions/function.dto';
import { ContentTable } from '../../content-table';
import { confirmDeleteRiskFunctions } from '../delete-dialogs';
import { RiskFunctionsOrderBy, sortRiskFunctions } from '../function-table-head';

interface GlobalRiskFunctionsTableProps {
  riskFunctions: RiskFunctionDto[] | null;
  totalCount: number;
  massDelete: (selectedIds: string[]) => void;
  openEditRiskFunction: (data: RiskFunctionDto) => void;
  openTrashRiskFunction: (data: RiskFunctionDto) => void;
  openCreateNewVersionRiskFunction: (data: RiskFunctionDto) => void;
  openRestoreRiskFunction: (data: RiskFunctionDto) => void;
  openCreateRiskFunction: () => void;
}

const GlobalRiskFunctionsTable: FunctionComponent<GlobalRiskFunctionsTableProps> = ({
  riskFunctions,
  totalCount,
  massDelete,
  openEditRiskFunction,
  openCreateNewVersionRiskFunction,
  openCreateRiskFunction,
  openTrashRiskFunction,
  openRestoreRiskFunction,
  /*...props*/
}) => {
  const confirm = useConfirm();
  //const riskFunctionService = useRiskFunctionService();

  const onMassDelete = useCallback(
    async (riskFunctionIds: string[]) => {
      const { dialog } = await confirmDeleteRiskFunctions(confirm, riskFunctionIds);
      return dialog
        .then(() => {
          massDelete(riskFunctionIds);
          return true;
        })
        .catch(() => {
          return false;
          /* ... */
        });
    },
    [confirm, massDelete],
  );

  const getRiskFunctionUrl = useCallback((riskFunction: RiskFunctionDto) => {
    return `/functions/${riskFunction.id}`;
  }, []);

  return (
    <ContentTable<RiskFunctionsOrderBy, RiskFunctionDto>
      tableToolbarTitle='Functions'
      totalCount={totalCount}
      rows={riskFunctions}
      sortTable={sortRiskFunctions}
      openCreate={openCreateRiskFunction}
      createButtonTitle='Create Function'
      openCreateNewVersion={openCreateNewVersionRiskFunction}
      openEdit={openEditRiskFunction}
      onDelete={openTrashRiskFunction}
      onRestore={openRestoreRiskFunction}
      getDetailsUrl={getRiskFunctionUrl}
      defaultOrderBy='name'
      massDeletion={{
        onMassDelete: onMassDelete,
        tooltipTitle: 'Delete selected',
        iconType: 'delete',
      }}
    />
  );
};

export default GlobalRiskFunctionsTable;
