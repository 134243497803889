import { faCloudUpload, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { MuiFileInput } from 'mui-file-input';
import { ChangeEvent, FunctionComponent, useCallback, useMemo, useState } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';

import { ImportService } from '../../services/import.service';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ImportRulesetProps {}

const ImportRulesetPage: FunctionComponent<ImportRulesetProps> = ({ ...props }) => {
  const {
    register,
    setValue,
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm<{
    cad: File | null;
    catt: File | null;
    cavd: File | null;
    cavtt: File | null;
    ccd: File | null;
    cctt: File | null;
    ccvd: File | null;
    ccvtt: File | null;
    name?: string;
    displayName?: string;
  }>({
    defaultValues: {
      cad: null,
      catt: null,
      cavd: null,
      cavtt: null,
      ccd: null,
      cctt: null,
      ccvd: null,
      ccvtt: null,
      name: undefined,
      displayName: undefined,
    },
  });
  const [sending, setSending] = useState(false);
  const [alert, setAlert] = useState<{ success?: string; error?: string }>({});
  const importService = useMemo(() => new ImportService(), []);

  const [combineRuleset, setCombineRuleset] = useState(false);
  const handleChangeCombineRuleset = (event: ChangeEvent<HTMLInputElement>) => {
    setCombineRuleset(event.target.checked);
  };

  const onSubmit = useCallback(
    (data: FieldValues) => {
      if (combineRuleset && (!data.name || !data.displayName)) {
        setAlert({ error: 'ID and Name must be set together' });
        setSending(false);
        return;
      }

      if ((data.cad && !data.catt) || (data.catt && !data.cad)) {
        setAlert({ error: 'Insufficient data. Please provide header & definition files in pairs.' });
        setSending(false);
        return;
      }
      if ((data.cavd && !data.cavtt) || (data.cavtt && !data.cavd)) {
        setAlert({ error: 'Insufficient data. Please provide header & definition files in pairs.' });
        setSending(false);
        return;
      }
      if ((data.ccd && !data.cctt) || (data.cctt && !data.ccd)) {
        setAlert({ error: 'Insufficient data. Please provide header & definition files in pairs.' });
        setSending(false);
        return;
      }
      if ((data.ccvd && !data.ccvtt) || (data.ccvtt && !data.ccvd)) {
        setAlert({ error: 'Insufficient data. Please provide header & definition files in pairs.' });
        setSending(false);
        return;
      }
      if (
        !data.cad &&
        !data.catt &&
        !data.cavd &&
        !data.cavtt &&
        !data.ccd &&
        !data.cctt &&
        !data.ccvd &&
        !data.ccvtt
      ) {
        setAlert({ error: 'Please provide any header & definition files in pairs.' });
        setSending(false);
        return;
      }

      const sendImport = async () => {
        if (combineRuleset) {
          return importService.importCFCombo({
            cad: data.cad,
            catt: data.catt,
            cavd: data.cavd,
            cavtt: data.cavtt,
            ccd: data.ccd,
            cctt: data.cctt,
            ccvd: data.ccvd,
            ccvtt: data.ccvtt,
            name: data.name,
            displayName: data.displayName,
          });
        }
        return importService.importCF({
          cad: data.cad,
          catt: data.catt,
          cavd: data.cavd,
          cavtt: data.cavtt,
          ccd: data.ccd,
          cctt: data.cctt,
          ccvd: data.ccvd,
          ccvtt: data.ccvtt,
        });
      };
      setAlert({});
      setSending(true);
      sendImport()
        .then((res: any) => {
          setAlert({ success: 'Import successful' });
          setSending(false);
        })
        .catch((err: any) => {
          //console.log(err);
          setAlert({ error: 'Import Error' });
          setSending(false);
        });
    },
    [combineRuleset, importService],
  );

  const registerOptions = {
    cad: undefined,
    catt: undefined,
    cavd: undefined,
    cavtt: undefined,
    ccd: undefined,
    cctt: undefined,
    ccvd: undefined,
    ccvtt: undefined,
    name: undefined,
    displayName: undefined,
  };
  const combineRulesetRegisterOptions = {
    name: { required: true },
    displayName: { required: true },
  };

  return (
    <Stack spacing={3}>
      <Paper sx={{ p: 2 }}>
        <Typography sx={{ flex: '1 1 100%', mb: 2 }} variant='h5' id='formTitle' component='div'>
          Import
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            <Box component='fieldset'>
              <legend>Authorization ID</legend>
              <Stack spacing={1} direction='row'>
                <Controller
                  name='cad'
                  control={control}
                  rules={registerOptions.cad}
                  render={({ field }) => (
                    <MuiFileInput
                      label='CAD (definition)'
                      fullWidth
                      error={!!errors.cad}
                      helperText={errors.cad?.message?.toString()}
                      placeholder='Select CAD File ...'
                      inputProps={{ accept: 'text/csv' }}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name='catt'
                  control={control}
                  rules={registerOptions.catt}
                  render={({ field }) => (
                    <MuiFileInput
                      label='CATT (header)'
                      fullWidth
                      error={!!errors.catt}
                      helperText={errors.catt?.message?.toString()}
                      placeholder='Select CATT File ...'
                      inputProps={{ accept: 'text/csv' }}
                      {...field}
                    />
                  )}
                />
              </Stack>
            </Box>
            <Box component='fieldset'>
              <legend>Authorization variant</legend>
              <Stack spacing={1} direction='row'>
                <Controller
                  name='cavd'
                  control={control}
                  rules={registerOptions.cavd}
                  render={({ field }) => (
                    <MuiFileInput
                      label='CAVD (definition)'
                      fullWidth
                      error={!!errors.cavd}
                      helperText={errors.cavd?.message?.toString()}
                      placeholder='Select CAVD File ...'
                      inputProps={{ accept: 'text/csv' }}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name='cavtt'
                  control={control}
                  rules={registerOptions.cavtt}
                  render={({ field }) => (
                    <MuiFileInput
                      label='CAVTT (header)'
                      fullWidth
                      error={!!errors.cavtt}
                      helperText={errors.cavtt?.message?.toString()}
                      placeholder='Select CAVTT File ...'
                      inputProps={{ accept: 'text/csv' }}
                      {...field}
                    />
                  )}
                />
              </Stack>
            </Box>
            <Box component='fieldset'>
              <legend>Combination ID</legend>
              <Stack spacing={1} direction='row'>
                <Controller
                  name='ccd'
                  control={control}
                  rules={registerOptions.ccd}
                  render={({ field }) => (
                    <MuiFileInput
                      label='CCD (definition)'
                      fullWidth
                      error={!!errors.ccd}
                      helperText={errors.ccd?.message?.toString()}
                      placeholder='Select CCD File ...'
                      inputProps={{ accept: 'text/csv' }}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name='cctt'
                  control={control}
                  rules={registerOptions.cctt}
                  render={({ field }) => (
                    <MuiFileInput
                      label='CCTT (header)'
                      fullWidth
                      error={!!errors.cctt}
                      helperText={errors.cctt?.message?.toString()}
                      placeholder='Select CCTT File ...'
                      inputProps={{ accept: 'text/csv' }}
                      {...field}
                    />
                  )}
                />
              </Stack>
            </Box>
            <Box component='fieldset'>
              <legend>Combination variant</legend>
              <Stack spacing={1} direction='row'>
                <Controller
                  name='ccvd'
                  control={control}
                  rules={registerOptions.ccvd}
                  render={({ field }) => (
                    <MuiFileInput
                      label='CCVD (definition)'
                      fullWidth
                      error={!!errors.ccvd}
                      helperText={errors.ccvd?.message?.toString()}
                      placeholder='Select CCVD File ...'
                      inputProps={{ accept: 'text/csv' }}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name='ccvtt'
                  control={control}
                  rules={registerOptions.ccvtt}
                  render={({ field }) => (
                    <MuiFileInput
                      label='CCVTT (header)'
                      fullWidth
                      error={!!errors.ccvtt}
                      helperText={errors.ccvtt?.message?.toString()}
                      placeholder='Select CCVTT File ...'
                      inputProps={{ accept: 'text/csv' }}
                      {...field}
                    />
                  )}
                />
              </Stack>
            </Box>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={combineRuleset} onChange={handleChangeCombineRuleset} />}
                label='Combine Rulesets'
              />
              {combineRuleset && (
                <Controller
                  name='name'
                  control={control}
                  rules={combineRuleset ? combineRulesetRegisterOptions.name : registerOptions.name}
                  render={({ field }) => (
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      margin='dense'
                      label='ID*'
                      fullWidth
                      error={!!errors.name}
                      helperText={errors.name?.message?.toString()}
                      placeholder='New ID for combined Ruleset'
                      {...field}
                      onChange={(event) => {
                        field.onChange(event.target.value.toUpperCase().trim());
                      }}
                    />
                  )}
                />
              )}
              {combineRuleset && (
                <Controller
                  name='displayName'
                  control={control}
                  rules={combineRuleset ? combineRulesetRegisterOptions.displayName : registerOptions.displayName}
                  render={({ field }) => (
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      margin='dense'
                      label='Name*'
                      fullWidth
                      error={!!errors.displayName}
                      helperText={errors.displayName?.message?.toString()}
                      placeholder='New Name for combined Ruleset'
                      {...field}
                    />
                  )}
                />
              )}
            </FormGroup>
            {alert.success && <Alert severity='success'>{alert.success}</Alert>}
            {alert.error && <Alert severity='error'>{alert.error}</Alert>}
            <Button
              type='submit'
              variant='contained'
              disabled={sending}
              startIcon={sending ? <FontAwesomeIcon icon={faSpinner} spin /> : <FontAwesomeIcon icon={faCloudUpload} />}
              sx={{ mt: 2 }}
            >
              Upload
            </Button>
          </Stack>
        </form>
      </Paper>
    </Stack>
  );
};

export default ImportRulesetPage;
