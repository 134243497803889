import { Alert, DialogContentText, List, ListItem, ListItemText, Stack } from '@mui/material';
import { ConfirmOptions } from 'material-ui-confirm';

import { RiskFunctionDto } from '../../services/dto/functions/function.dto';
import { RiskFunctionService } from '../../services/risk-function.service';
import { dialogActionButtonOptions } from '../dialog-action-buttons-options';

export async function confirmDeleteRiskFunction(
  confirm: (options?: ConfirmOptions) => Promise<void>,
  riskFunctionService: RiskFunctionService,
  riskFunction: RiskFunctionDto,
) {
  const risksFromRiskFunction = await riskFunctionService.getRisksFromFunction(riskFunction.id);
  const content = (() => {
    if (risksFromRiskFunction.data.length === 0) {
      return <DialogContentText></DialogContentText>;
    }
    return (
      <Stack spacing={2}>
        <DialogContentText>
          <Stack spacing={1}>
            <Alert severity='warning'>The Function will be removed from all Risks.</Alert>
            <Alert severity='info'>Only the selected version will be deleted.</Alert>
          </Stack>
        </DialogContentText>
        <DialogContentText>
          It is currently being used in the following Risks:
          <List dense>
            {risksFromRiskFunction.data.map((risk) => (
              <ListItem key={risk.id}>
                <ListItemText primary={risk.name} />
              </ListItem>
            ))}
          </List>
        </DialogContentText>
      </Stack>
    );
  })();

  return {
    dialog: confirm({
      title: `Are you sure you want to delete ${riskFunction.name}?`,
      content: content,
      ...dialogActionButtonOptions,
    }),
  };
}

export async function confirmDeleteRiskFunctions(
  confirm: (options?: ConfirmOptions) => Promise<void>,
  riskFunctionIds: string[],
) {
  const content = (() => {
    return <Alert severity='info'>Only the selected versions will be deleted.</Alert>;
  })();

  return {
    dialog: confirm({
      content: content,
      title: `Are you sure you want to delete ${riskFunctionIds.length} Functions?`,
      ...dialogActionButtonOptions,
    }),
  };
}
