import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Stack,
  TextField,
  createFilterOptions,
} from '@mui/material';
import { AxiosError } from 'axios';
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';

import { useSelectorUserData } from '../../../redux/selector';
import { JsonApiErrorResult } from '../../../services/dto/error-result.dto';
import { UserCompanyDto } from '../../../services/dto/users/user-company.dto';
import { UserDto } from '../../../services/dto/users/user.dto';
import { UserState } from '../../../services/models/user-state';
import { UserService } from '../../../services/user.service';

export interface InputUserCompanyDto {
  id?: string;
  name?: string;
  displayName: string;
  inputValue?: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface UserModalProps {
  mode: 'Create' | 'Edit';
  title?: string;
  open: boolean;
  onClose: () => void;
  onUserCreated?: (data: UserDto) => void;
  onUserUpdated?: (userId: string, data: UserDto) => void;
  user?: UserDto;
  users: UserDto[];
  companies: UserCompanyDto[];
}

const UserModal: FunctionComponent<UserModalProps> = ({
  mode,
  open,
  onClose,
  onUserCreated,
  onUserUpdated,
  user,
  users,
  companies,
  ...props
}) => {
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    reset,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: user?.username,
      email: user?.email,
      firstname: user?.profile.firstname,
      lastname: user?.profile.lastname,
      password: '',
      newPasswordConfirm: '',
      role: user?.role.name,
      company: user?.company.name,
    },
  });
  const { userData } = useSelectorUserData();
  const [selectedCompany, setSelectedCompany] = useState<InputUserCompanyDto | null>(null);
  const companiesFilter = createFilterOptions<InputUserCompanyDto>();
  const userService = useMemo(() => new UserService(), []);

  const title = useMemo(() => {
    if (props.title) {
      return props.title;
    }
    switch (mode) {
      case 'Create':
        return 'Create New User';
      case 'Edit':
        return 'Edit User';
    }
    return props.title;
  }, [props.title, mode]);

  // TODO: error handling from backend with setError
  const handleRequestError = useCallback(
    (status?: number, result?: JsonApiErrorResult) => {
      result?.errors.forEach((error) => {
        switch (error.code) {
          case 'user_already_exist':
            setError('username', { type: error.status.toString(), message: error.title });
            break;
        }
      });
    },
    [setError],
  );

  const onCancel = useCallback(() => {
    onClose();
    reset();
    clearErrors();
    setSelectedCompany(null);
  }, [clearErrors, onClose, reset]);

  const onSubmit = useCallback(
    async (data: FieldValues) => {
      const onCreate = () => {
        const newUser = {
          username: data.username,
          email: data.email,
          state: UserState.Active, ///< TODO: set status on create only for testing (must be pending in future)
          password: data.password,
          role: data.role,
          firstname: data.firstname,
          lastname: data.lastname,
          companyId: selectedCompany?.id,
          company:
            selectedCompany && !selectedCompany.id && selectedCompany.name
              ? {
                  name: selectedCompany.name,
                  displayName: selectedCompany.displayName ?? selectedCompany.name,
                }
              : undefined,
        };
        userService
          .createUser(newUser)
          .then((res) => {
            if (onUserCreated) {
              onUserCreated(res.data);
            }
            onClose();
            reset();
            clearErrors();
            setSelectedCompany(null);
          })
          .catch((err) => {
            handleRequestError(err.response.status, err.response.data);
          });
      };
      const onUpdate = () => {
        if (user) {
          const updateUser = {
            username: user.username !== data.username ? data.username : undefined,
            email: user.email !== data.email ? data.email : undefined,
            firstname: user.profile.firstname !== data.firstname ? data.firstname : undefined,
            lastname: user.profile.lastname !== data.lastname ? data.lastname : undefined,
            password: data.password ? data.password : undefined,
            role: user.role.name !== data.role ? data.role : undefined,
          };
          userService
            .updateUser(user.id, updateUser)
            .then((res) => {
              if (onUserUpdated) {
                onUserUpdated(user.id, res.data);
              }
              onClose();
              reset();
              clearErrors();
              setSelectedCompany(null);
            })
            .catch((err: AxiosError<JsonApiErrorResult>) => {
              handleRequestError(err.response?.status, err.response?.data);
            });
        }
      };
      if (data.password) {
        if (data.newPasswordConfirm !== data.password) {
          setError('newPasswordConfirm', {
            type: 'manual',
            message: 'Password must match',
          });
          return;
        }
      }

      switch (mode) {
        case 'Create':
          onCreate();
          break;
        case 'Edit':
          onUpdate();
          break;
      }
    },
    [
      mode,
      selectedCompany,
      userService,
      onUserCreated,
      onClose,
      reset,
      clearErrors,
      handleRequestError,
      user,
      onUserUpdated,
      setError,
    ],
  );

  useEffect(() => {
    setValue('username', user?.username);
    setValue('password', '');
    setValue('email', user?.email);
    setValue('role', user?.role.name);
    setValue('firstname', user?.profile.firstname);
    setValue('lastname', user?.profile.lastname);
    setValue('company', user?.company.name);
    setSelectedCompany(user?.company ?? null);
  }, [setValue, user]);

  return (
    <Dialog open={open} onClose={onCancel}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12}>
              <Autocomplete
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                freeSolo
                value={selectedCompany}
                readOnly={mode !== 'Create'}
                {...register('company', { required: true })}
                onChange={(event, newValue) => {
                  if (typeof newValue === 'string') {
                    const name = newValue.toLowerCase().replace(/\s+/g, '-').trim();
                    setSelectedCompany({
                      id: undefined,
                      name: name,
                      displayName: newValue,
                      inputValue: newValue,
                    });
                    setValue('company', name);

                    if (companies.some((company) => company.name === name)) {
                      setError('company', { type: 'custom', message: 'Company already exist' });
                    } else {
                      clearErrors('company');
                    }
                  } else if (newValue?.inputValue) {
                    // Create a new value from the user input
                    const name = newValue.inputValue.toLowerCase().replace(/\s+/g, '-').trim();
                    setSelectedCompany({
                      id: undefined,
                      name: name,
                      displayName: newValue.inputValue,
                    });
                    setValue('company', name);

                    if (companies.some((company) => company.name === name)) {
                      setError('company', { type: 'custom', message: 'Company already exist' });
                    } else {
                      clearErrors('company');
                    }
                  } else {
                    setSelectedCompany(newValue);
                    setValue('company', newValue?.name);
                    clearErrors('company');
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = companiesFilter(options, params);

                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = options.some(
                    (option) =>
                      (!option.inputValue && option.name?.toLowerCase().includes(inputValue.toLowerCase())) ||
                      option.displayName.toLowerCase().includes(inputValue.toLowerCase()),
                  );
                  if (inputValue && !isExisting) {
                    filtered.push({
                      displayName: `Add "${inputValue}"`,
                      inputValue: inputValue,
                      name: inputValue,
                    });
                  }

                  return filtered;
                }}
                options={companies as InputUserCompanyDto[]}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === 'string') {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  // Regular option
                  return option.displayName;
                }}
                renderOption={(props, option) => (
                  <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    {option.displayName ?? option.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin='dense'
                    label='Company'
                    fullWidth
                    variant='standard'
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-company', // disable autocomplete and autofill
                    }}
                    error={!!errors.company}
                    helperText={errors.company?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin='dense'
                label='Username'
                fullWidth
                variant='standard'
                {...register('username', { required: true })}
                error={!!errors.username}
                helperText={errors.username?.message}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin='dense'
                label='E-Mail'
                type='email'
                fullWidth
                variant='standard'
                {...register('email', { required: true })}
                error={!!errors.email}
                helperText={errors.email?.message}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin='dense'
                label='Password'
                type='password'
                fullWidth
                variant='standard'
                {...register('password', { required: mode == 'Create' })}
                error={!!errors.password}
                helperText={errors.password?.message}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin='dense'
                label='New Password (Confirm)'
                type='password'
                fullWidth
                variant='standard'
                {...register('newPasswordConfirm', { required: mode == 'Create' })}
                error={!!errors.newPasswordConfirm}
                helperText={errors.newPasswordConfirm?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant='standard'
                className='select-menu'
                select
                label='User Type'
                defaultValue={
                  mode === 'Create'
                    ? userData?.role.permissions.includes('users:create:role:admin')
                      ? 'admin'
                      : 'client'
                    : undefined
                }
                value={user?.role.name}
                {...register('role', { required: true })}
                error={!!errors.role}
                helperText={errors.role?.message}
                inputProps={{ readOnly: mode === 'Edit' }}
              >
                {((mode === 'Create' && userData?.role.permissions.includes('users:create:role:superadmin')) ||
                  mode === 'Edit') && (
                  <MenuItem
                    disabled={mode === 'Edit' && !userData?.role.permissions.includes('users:update:role:superadmin')}
                    key='superadmin'
                    value='superadmin'
                  >
                    Super Admin
                  </MenuItem>
                )}
                {((mode === 'Create' && userData?.role.permissions.includes('users:create:role:admin')) ||
                  mode === 'Edit') && (
                  <MenuItem
                    disabled={mode === 'Edit' && !userData?.role.permissions.includes('users:update:role:admin')}
                    key='admin'
                    value='admin'
                  >
                    Admin
                  </MenuItem>
                )}
                {((mode === 'Create' && userData?.role.permissions.includes('users:create:role:client')) ||
                  mode === 'Edit') && (
                  <MenuItem
                    disabled={mode === 'Edit' && !userData?.role.permissions.includes('users:update:role:client')}
                    key='client'
                    value='client'
                  >
                    Client
                  </MenuItem>
                )}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin='dense'
                label='First Name'
                fullWidth
                variant='standard'
                {...register('firstname')}
                error={!!errors.firstname}
                helperText={errors.firstname?.message}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin='dense'
                label='Last Name'
                fullWidth
                variant='standard'
                {...register('lastname')}
                error={!!errors.lastname}
                helperText={errors.lastname?.message}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Stack direction={'row'} spacing={1} marginX={2} marginBottom={2}>
            <Button
              type='button'
              onClick={() => {
                onCancel();
              }}
              variant='contained'
              color='error'
            >
              Cancel
            </Button>
            <Button type='submit' variant='contained' color='success'>
              Save
            </Button>
          </Stack>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UserModal;
