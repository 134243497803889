import { RiskOwnersRisksReportDto } from '@/services/dto/reports/risk-owners-risks-report.dto';
import { Box, Grid, List, ListItem, Paper, Typography } from '@mui/material';
import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RiskOwnerRiskCountListProps {
  riskOwnersRisksReportData: RiskOwnersRisksReportDto[] | undefined;
  height?: string | number;
}

const RiskOwnerRiskCountList = ({ riskOwnersRisksReportData, height }: RiskOwnerRiskCountListProps) => {
  return (
    <Paper elevation={3} sx={{ p: 2 }}>
      <Typography sx={{ flex: '1 1 100%', mb: 2 }} variant='body1' id='RiskOwnerRiskCountTitle' component='div'>
        Risk Owner/ Risk Count
      </Typography>
      <Box sx={{ height: height, width: '100%' }}>
        <List sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          {riskOwnersRisksReportData?.map((item, index) => (
            <ListItem key={index}>
              <Grid container sx={{ display: 'flex', alignContent: 'space-around', alignItems: 'center' }}>
                <Grid item md={6}>
                  <Typography sx={{ flex: '1 1 100%' }} variant='subtitle1' id='tableTitle3' component='div'>
                    {`${index + 1}. ${item.riskOwner}`}
                  </Typography>
                </Grid>
                <Grid item md={6} sx={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column' }}>
                  <Typography sx={{ flex: '1 1 100%' }} variant='h5' id='tableTitle3' component='div'>
                    {item.riskCount}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
          ))}
        </List>
      </Box>
    </Paper>
  );
};

export default RiskOwnerRiskCountList;
