import { ConfirmOptions } from 'material-ui-confirm';

import { UserDto } from '../../services/dto/users/user.dto';

export async function confirmDeleteUser(confirm: (options?: ConfirmOptions) => Promise<void>, user: UserDto) {
  return {
    dialog: confirm({
      title: `Delete User`,
      description: `Are you sure you want to delete ${user.username}?`,
      confirmationText: 'Yes',
      cancellationText: 'Cancel',
      confirmationButtonProps: { variant: 'contained', color: 'success' },
      cancellationButtonProps: { variant: 'contained', color: 'error' },
    }),
  };
}
