import { HttpService } from '../http.service';

export class GrcExportService {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(private httpService: HttpService = new HttpService('export/grc')) {}

  async exportBp(rulesetIds: string[], functionContentTypeName: string) {
    const response = await this.httpService.postAny<string>(`bp`, { rulesetIds, functionContentTypeName }, 'text');
    return new Blob([response.data], { type: 'text/txt' });
  }

  async exportFunc(rulesetIds: string[], functionContentTypeName: string) {
    const response = await this.httpService.postAny<string>(`func`, { rulesetIds, functionContentTypeName }, 'text');
    return new Blob([response.data], { type: 'text/txt' });
  }

  async exportFuncBp(rulesetIds: string[], functionContentTypeName: string) {
    const response = await this.httpService.postAny<string>(`func/bp`, { rulesetIds, functionContentTypeName }, 'text');
    return new Blob([response.data], { type: 'text/txt' });
  }

  async exportFuncAct(rulesetIds: string[], functionContentTypeName: string) {
    const response = await this.httpService.postAny<string>(
      `func/act`,
      { rulesetIds, functionContentTypeName },
      'text',
    );
    return new Blob([response.data], { type: 'text/txt' });
  }

  async exportFuncPerm(rulesetIds: string[], functionContentTypeName: string) {
    const response = await this.httpService.postAny<string>(
      `func/perm`,
      { rulesetIds, functionContentTypeName },
      'text',
    );
    return new Blob([response.data], { type: 'text/txt' });
  }

  async exportRules(rulesetIds: string[], functionContentTypeName: string) {
    const response = await this.httpService.postAny<string>(`rules`, { rulesetIds, functionContentTypeName }, 'text');
    return new Blob([response.data], { type: 'text/txt' });
  }

  async exportRisks(rulesetIds: string[], functionContentTypeName: string) {
    const response = await this.httpService.postAny<string>(`risks`, { rulesetIds, functionContentTypeName }, 'text');
    return new Blob([response.data], { type: 'text/txt' });
  }

  async exportRiskDesc(rulesetIds: string[], functionContentTypeName: string) {
    const response = await this.httpService.postAny<string>(
      `risk/desc`,
      { rulesetIds, functionContentTypeName },
      'text',
    );
    return new Blob([response.data], { type: 'text/txt' });
  }

  async exportRiskRuleset(rulesetIds: string[], functionContentTypeName: string) {
    const response = await this.httpService.postAny<string>(
      `risk/ruleset`,
      { rulesetIds, functionContentTypeName },
      'text',
    );
    return new Blob([response.data], { type: 'text/txt' });
  }

  async exportRiskOwn(rulesetIds: string[], functionContentTypeName: string) {
    const response = await this.httpService.postAny<string>(
      `risk/own`,
      { rulesetIds, functionContentTypeName },
      'text',
    );
    return new Blob([response.data], { type: 'text/txt' });
  }

  async exportZip(rulesetIds: string[], functionContentTypeName: string) {
    const response = await this.httpService.postAny<string>(
      `all/zip`,
      { rulesetIds, functionContentTypeName },
      'arraybuffer',
    );
    return new Blob([response.data], { type: 'application/zip' });
  }
}
