import {
  faHome,
  faUsers,
  faUserLock,
  faInfoCircle,
  faUsersGear,
  faCubes,
  faTriangleExclamation,
  faFunction,
  faHammer,
  faCircleQuestion,
  faEnvelope,
  faRocket,
  faTrash,
} from '@awesome.me/kit-6741fca89c/icons/classic/light';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AboutPage from '../pages/about';
import ContactSupportPage from '../pages/contact-support';
import DashboardPage from '../pages/dashboard';
import ExportRulesetPage from '../pages/export-ruleset';
import FaqPage from '../pages/faq';
import FunctionDetailsPage from '../pages/function-details';
import FunctionsPage from '../pages/functions';
import FunctionsBuilderPage from '../pages/functions-builder';
import FunctionsPermissionsPage from '../pages/functions-permissions';
import ImportRulesetPage from '../pages/import-ruleset';
import ProfilePage from '../pages/profile';
import RiskDetailsPage from '../pages/risk-details';
import RisksPage from '../pages/risks';
import RisksBuilderPage from '../pages/risks-builder';
import RisksFunctionsPage from '../pages/risks-functions';
import RulesetDetailsPage from '../pages/ruleset-details';
import RulesetsPage from '../pages/rulesets';
import TrashPage from '../pages/trash';
import UserRolesPage from '../pages/user-roles';
import UsersPage from '../pages/users';
import { UserDto } from '../services/dto/users/user.dto';
import mockupRoutes from './mockup-routes';
import { RouteMenuElement } from './route-menu-element';

const mainRoutes: RouteMenuElement[] = [
  {
    sidebarProps: {
      text: 'Menu',
    },
  },
  {
    path: '/dashboard',
    element: <DashboardPage />,
    sidebarProps: {
      title: 'Dashboard',
      icon: <FontAwesomeIcon icon={faHome} />,
    },
  },
  {
    // User Management
    access: {
      permissions: ['users:create', 'users:read', 'users:update', 'users:delete'],
    },
    sidebarProps: {
      title: 'User Management',
      icon: <FontAwesomeIcon icon={faUsersGear} />,
    },
    children: [
      {
        path: '/users',
        element: <UsersPage />,
        sidebarProps: {
          title: 'Users',
          icon: <FontAwesomeIcon icon={faUsers} />,
        },
        access: {
          permissions: ['users:create', 'users:read', 'users:update', 'users:delete'],
        },
      },
      {
        path: '/users/roles',
        element: <UserRolesPage />,
        sidebarProps: {
          title: 'User Roles',
          icon: <FontAwesomeIcon icon={faUserLock} />,
        },
        access: {
          permissions: ['users:create:role:admin', 'users:update:role:admin'],
        },
      },
    ],
  },
  {
    sidebarProps: {
      text: 'Rulesets',
    },
    access: {
      permissions: ['rulesets:create', 'rulesets:read', 'rulesets:update', 'rulesets:delete'],
    },
  },
  {
    path: '/rulesets',
    element: <RulesetsPage />,
    sidebarProps: {
      title: 'Rulesets',
      icon: <FontAwesomeIcon icon={faCubes} />,
    },
    access: {
      permissions: ['rulesets:create', 'rulesets:read', 'rulesets:update', 'rulesets:delete'],
    },
  },
  {
    // Risks
    access: {
      permissions: ['rulesets:read:risks', 'risks:create', 'risks:read', 'risks:update', 'risks:delete'],
    },
    sidebarProps: {
      title: 'Risks',
      icon: <FontAwesomeIcon icon={faTriangleExclamation} />,
    },
    children: [
      {
        path: '/risks',
        element: <RisksPage />,
        sidebarProps: {
          title: 'Risks',
          icon: <FontAwesomeIcon icon={faUsers} />,
        },
        access: {
          permissions: ['rulesets:read:risks', 'risks:create', 'risks:read', 'risks:update', 'risks:delete'],
        },
      },
      {
        path: '/risks/builder',
        element: <RisksBuilderPage />,
        sidebarProps: {
          title: 'Risk Builder',
          icon: <FontAwesomeIcon icon={faHammer} />,
        },
        access: {
          permissions: ['rulesets:read:risks', 'risks:create', 'risks:read', 'risks:update', 'risks:delete'],
        },
      },
      {
        path: '/risks/functions',
        element: <RisksFunctionsPage />,
        sidebarProps: {
          title: 'Risks Functions',
          icon: <FontAwesomeIcon icon={faUserLock} />,
        },
        access: {
          permissions: [
            'rulesets:read:risks:functions',
            'risks:create:functions',
            'risks:read:functions',
            'risks:update:functions',
            'risks:delete:functions',
          ],
        },
      },
    ],
  },
  {
    // Functions
    access: {
      permissions: [
        'rulesets:read:functions',
        'functions:create',
        'functions:read',
        'functions:update',
        'functions:delete',
      ],
    },
    sidebarProps: {
      title: 'Functions',
      icon: <FontAwesomeIcon icon={faFunction} />,
    },
    children: [
      {
        path: '/functions',
        element: <FunctionsPage />,
        sidebarProps: {
          title: 'Functions',
          icon: <FontAwesomeIcon icon={faFunction} />,
        },
        access: {
          permissions: [
            'rulesets:read:functions',
            'functions:create',
            'functions:read',
            'functions:update',
            'functions:delete',
          ],
        },
      },
      {
        path: '/functions/builder',
        element: <FunctionsBuilderPage />,
        sidebarProps: {
          title: 'Functions Builder',
          icon: <FontAwesomeIcon icon={faHammer} />,
        },
        access: {
          permissions: [
            'rulesets:read:functions',
            'functions:create',
            'functions:read',
            'functions:update',
            'functions:delete',
          ],
        },
      },
      {
        path: '/functions/permissions',
        element: <FunctionsPermissionsPage />,
        sidebarProps: {
          title: 'Functions Permissions ',
          icon: <FontAwesomeIcon icon={faUserLock} />,
        },
        access: {
          permissions: [
            'rulesets:read:functions',
            'functions:create',
            'functions:read',
            'functions:update',
            'functions:delete',
          ],
        },
      },
    ],
  },
  {
    // Import/Export
    sidebarProps: {
      title: 'Import / Export',
      icon: <FontAwesomeIcon icon={faRocket} />,
    },
    access: {
      permissions: ['import:create', 'export:read'],
    },
    children: [
      {
        path: '/import/ruleset',
        element: <ImportRulesetPage />,
        sidebarProps: {
          title: 'Import Ruleset',
          icon: <FontAwesomeIcon icon={faUsers} />,
        },
      },
      {
        path: '/export/ruleset',
        element: <ExportRulesetPage />,
        sidebarProps: {
          title: 'Export Ruleset',
          icon: <FontAwesomeIcon icon={faUserLock} />,
        },
      },
    ],
  },
  {
    path: '/trash',
    element: <TrashPage />,
    sidebarProps: {
      title: 'Trash',
      icon: <FontAwesomeIcon icon={faTrash} />,
    },
    access: {
      permissions: [
        'rulesets:read',
        'rulesets:update',
        'rulesets:delete',
        'rulesets:read:risks',
        'risks:read',
        'risks:update',
        'risks:delete',
        'rulesets:read:functions',
        'functions:read',
        'functions:update',
        'functions:delete',
      ],
    },
  },
  {
    sidebarProps: {
      text: 'Support',
    },
  },
  {
    path: '/faq',
    element: <FaqPage />,
    sidebarProps: {
      title: 'FAQs',
      icon: <FontAwesomeIcon icon={faCircleQuestion} />,
    },
  },
  {
    path: '/contact',
    element: <ContactSupportPage />,
    sidebarProps: {
      title: 'Contact Support',
      icon: <FontAwesomeIcon icon={faEnvelope} />,
    },
  },
  {
    path: '/about',
    element: <AboutPage />,
    sidebarProps: {
      title: 'About',
      icon: <FontAwesomeIcon icon={faInfoCircle} />,
    },
  },
  {
    path: '/user/profile',
    element: <ProfilePage />,
    access: {
      permissions: ['self:read', 'self:update'],
    },
  },
  // Ruleset Details
  {
    path: '/rulesets/:rulesetId',
    element: <RulesetDetailsPage />,
    access: {
      permissions: ['rulesets:read:risks', 'risks:create', 'risks:update', 'risks:read', 'risks:delete'],
    },
  },
  // Risk Details
  {
    path: '/rulesets/:rulesetId/risks/:riskId',
    element: <RiskDetailsPage />,
    access: {
      permissions: [
        'rulesets:read:risks',
        'risks:read:functions',
        'functions:create',
        'functions:update',
        'functions:read',
        'functions:delete',
      ],
    },
  },
  {
    path: '/risks/:riskId',
    element: <RiskDetailsPage />,
    access: {
      permissions: [
        'risks:read:functions',
        'functions:create',
        'functions:update',
        'functions:read',
        'functions:delete',
      ],
    },
  },
  // Function Details
  {
    path: '/rulesets/:rulesetId/risks/:riskId/functions/:riskFunctionId',
    element: <FunctionDetailsPage />,
    access: {
      permissions: [
        'rulesets:read:risks',
        'risks:read:functions',
        'functions:create',
        'functions:update',
        'functions:read',
        'functions:delete',
      ],
    },
  },
  {
    path: '/risks/:riskId/functions/:riskFunctionId',
    element: <FunctionDetailsPage />,
    access: {
      permissions: [
        'risks:read:functions',
        'functions:create',
        'functions:update',
        'functions:read',
        'functions:delete',
      ],
    },
  },
  {
    path: '/functions/:riskFunctionId',
    element: <FunctionDetailsPage />,
    access: {
      permissions: ['functions:create', 'functions:update', 'functions:read', 'functions:delete'],
    },
  },
];

export function getAllMainRoutes() {
  return [...mainRoutes, ...mockupRoutes];
}

export function getMainMenuElements(userData: UserDto) {
  return getAllMainRoutes().filter((route) => {
    return (
      (!route.access?.roles || route.access.roles.includes(userData.role.name)) &&
      (!route.access?.permissions ||
        route.access.permissions.some((permission) => userData.role.permissions.includes(permission)))
    );
  });
}

export default mainRoutes;
