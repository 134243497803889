import { Breadcrumbs, Link, Typography } from '@mui/material';
import { FunctionComponent } from 'react';

import { RiskDto } from '../../services/dto/risks/risk.dto';
import { RulesetDto } from '../../services/dto/rulesets/ruleset.dto';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RiskBreadcrumbsProps {
  ruleset?: RulesetDto;
  risk: RiskDto;
}

const RiskBreadcrumbs: FunctionComponent<RiskBreadcrumbsProps> = ({ ruleset, risk /*...props*/ }) => {
  return (
    <Breadcrumbs separator='›' aria-label='breadcrumb'>
      {!ruleset && (
        <Link underline='hover' color='inherit' href={`/risks`}>
          Risks
        </Link>
      )}
      {ruleset && (
        <Link underline='hover' color='inherit' href={`/rulesets/${ruleset.id}`}>
          {ruleset.name}
        </Link>
      )}
      <Typography color='text.primary'>{risk.name}</Typography>
    </Breadcrumbs>
  );
};

export default RiskBreadcrumbs;
