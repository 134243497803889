import { RisksRiskLevelsReportDto } from '@/services/dto/reports/risks-risk-levels-report.dto';
import { faFileCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, CircularProgress, Paper, Stack, Typography } from '@mui/material';
import { Chart as ChartJS } from 'chart.js';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Pie, getElementAtEvent } from 'react-chartjs-2';

import { DASHBOARD_DOUBLE_CLICK_DEPLAY } from './constants';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RiskLevelChartBoxProps {
  risksRiskLevelsReportData?: RisksRiskLevelsReportDto;
  height?: string | number;
  setRiskLevelFilter: (riskLevel: string, riskLevelDisplayName: string) => void;
  onDoubleClick: (extraParam?: { key: string; value: string }) => void;
}

const RiskLevelChartBox = ({
  risksRiskLevelsReportData,
  height,
  setRiskLevelFilter,
  onDoubleClick,
}: RiskLevelChartBoxProps) => {
  const risksForRiskLevelChartDatasetsLabels = useMemo(() => ['Low', 'Mid', 'High', 'Crit'], []);
  const risksForRiskLevelChartLabels = useMemo(() => ['Draft', 'Final', 'Released'], []);
  const risksForRiskLevelChartDatasets = useMemo(
    () => [
      {
        label: risksForRiskLevelChartDatasetsLabels[0],
        data: [
          risksRiskLevelsReportData?.riskLevels.draft.low,
          risksRiskLevelsReportData?.riskLevels.final.low,
          risksRiskLevelsReportData?.riskLevels.released.low,
        ],
        backgroundColor: ['#81c784', '#66bb6a', '#388e3c'],
        borderColor: ['rgba(0, 0, 0, 0)'],
        borderWidth: 1,
        datalabels: {
          labels: {
            value: {
              align: 'center',
              borderWidth: 2,
              borderRadius: 4,
              color: (ctx: any) => {
                const value = ctx.dataset.data[ctx.dataIndex];
                return value > 0 ? 'white' : 'rgba(0, 0, 0, 0)';
              },
              formatter: (value: any) => {
                return value;
              },
              padding: 4,
            },
          },
        },
      },
      {
        label: risksForRiskLevelChartDatasetsLabels[1],
        data: [
          risksRiskLevelsReportData?.riskLevels.draft.mid,
          risksRiskLevelsReportData?.riskLevels.final.mid,
          risksRiskLevelsReportData?.riskLevels.released.mid,
        ],
        backgroundColor: ['#faf697', '#f6f052', '#f3ea0c'],
        borderColor: ['rgba(0, 0, 0, 0)'],
        borderWidth: 1,
        datalabels: {
          labels: {
            value: {
              align: 'center',
              borderWidth: 2,
              borderRadius: 4,
              color: (ctx: any) => {
                const value = ctx.dataset.data[ctx.dataIndex];
                return value > 0 ? 'black' : 'rgba(0, 0, 0, 0)';
              },
              formatter: (value: any) => {
                return value;
              },
              padding: 4,
            },
          },
        },
      },
      {
        label: risksForRiskLevelChartDatasetsLabels[2],
        data: [
          risksRiskLevelsReportData?.riskLevels.draft.high,
          risksRiskLevelsReportData?.riskLevels.final.high,
          risksRiskLevelsReportData?.riskLevels.released.high,
        ],
        backgroundColor: ['#ffb74d', '#ffa726', '#f57c00'],
        borderColor: ['rgba(0, 0, 0, 0)'],
        borderWidth: 1,
        datalabels: {
          labels: {
            value: {
              align: 'center',
              borderWidth: 2,
              borderRadius: 4,
              color: (ctx: any) => {
                const value = ctx.dataset.data[ctx.dataIndex];
                return value > 0 ? 'white' : 'rgba(0, 0, 0, 0)';
              },
              formatter: (value: any) => {
                return value;
              },
              padding: 4,
            },
          },
        },
      },
      {
        label: risksForRiskLevelChartDatasetsLabels[3],
        data: [
          risksRiskLevelsReportData?.riskLevels.draft.crit,
          risksRiskLevelsReportData?.riskLevels.final.crit,
          risksRiskLevelsReportData?.riskLevels.released.crit,
        ],
        backgroundColor: ['#e57373', '#f44336', '#d32f2f'],
        borderColor: ['rgba(0, 0, 0, 0)'],
        borderWidth: 1,
        datalabels: {
          labels: {
            value: {
              align: 'center',
              borderWidth: 2,
              borderRadius: 4,
              color: (ctx: any) => {
                const value = ctx.dataset.data[ctx.dataIndex];
                return value > 0 ? 'white' : 'rgba(0, 0, 0, 0)';
              },
              formatter: (value: any) => {
                return value;
              },
              padding: 4,
            },
          },
        },
      },
    ],
    [
      risksForRiskLevelChartDatasetsLabels,
      risksRiskLevelsReportData?.riskLevels.draft.crit,
      risksRiskLevelsReportData?.riskLevels.draft.high,
      risksRiskLevelsReportData?.riskLevels.draft.low,
      risksRiskLevelsReportData?.riskLevels.draft.mid,
      risksRiskLevelsReportData?.riskLevels.final.crit,
      risksRiskLevelsReportData?.riskLevels.final.high,
      risksRiskLevelsReportData?.riskLevels.final.low,
      risksRiskLevelsReportData?.riskLevels.final.mid,
      risksRiskLevelsReportData?.riskLevels.released.crit,
      risksRiskLevelsReportData?.riskLevels.released.high,
      risksRiskLevelsReportData?.riskLevels.released.low,
      risksRiskLevelsReportData?.riskLevels.released.mid,
    ],
  );
  const risksForRiskLevelChartData = useMemo(() => {
    return {
      labels: risksForRiskLevelChartLabels,
      datasets: risksForRiskLevelChartDatasets,
    };
  }, [risksForRiskLevelChartDatasets, risksForRiskLevelChartLabels]);

  //riskLevel onClick event
  const risksRiskLevelCountChartRef = useRef<ChartJS | any>();
  const risksRiskLevelChartSingleClick = useCallback(
    (event: React.MouseEventHandler<HTMLCanvasElement> | any) => {
      /// @FIXME: onClick event type vs. getElementAtEvent type (use any)
      const elementClicked = risksRiskLevelCountChartRef.current
        ? getElementAtEvent(risksRiskLevelCountChartRef.current, event)
        : undefined;
      if (elementClicked && elementClicked.length > 0) {
        const index = elementClicked[0].index;
        const datasetIndex = elementClicked[0].datasetIndex;

        switch (datasetIndex) {
          case 0:
            setRiskLevelFilter('low', risksForRiskLevelChartDatasetsLabels[0]);
            break;
          case 1:
            setRiskLevelFilter('mid', risksForRiskLevelChartDatasetsLabels[1]);
            break;
          case 2:
            setRiskLevelFilter('high', risksForRiskLevelChartDatasetsLabels[2]);
            break;
          case 3:
            setRiskLevelFilter('crit', risksForRiskLevelChartDatasetsLabels[3]);
            break;
        }
      }
    },
    [risksForRiskLevelChartDatasetsLabels, setRiskLevelFilter],
  );
  const risksRiskLevelChartDoubleClick = useCallback(
    (event: React.MouseEventHandler<HTMLCanvasElement> | any) => {
      /// @FIXME: onClick event type vs. getElementAtEvent type (use any)
      const elementClicked = risksRiskLevelCountChartRef.current
        ? getElementAtEvent(risksRiskLevelCountChartRef.current, event)
        : undefined;
      if (elementClicked && elementClicked.length > 0) {
        //const index = elementClicked[0].index;
        const datasetIndex = elementClicked[0].datasetIndex;

        switch (datasetIndex) {
          case 0:
            onDoubleClick({ key: 'riskLevel', value: 'low' });
            break;
          case 1:
            onDoubleClick({ key: 'riskLevel', value: 'mid' });
            break;
          case 2:
            onDoubleClick({ key: 'riskLevel', value: 'high' });
            break;
          case 3:
            onDoubleClick({ key: 'riskLevel', value: 'crit' });
            break;
        }
      }
    },
    [onDoubleClick],
  );

  const [clickCounter, setClickCounter] = useState<{
    event: React.MouseEventHandler<HTMLCanvasElement> | any | undefined;
    counter: number;
  }>({ event: undefined, counter: 0 });
  const risksRiskLevelChartOnClick = useCallback((event: React.MouseEventHandler<HTMLCanvasElement> | any) => {
    setClickCounter((c) => {
      // inc. click counter and pass event
      return { event, counter: c.counter + 1 };
    });
  }, []);

  useEffect(() => {
    // has click event
    if (clickCounter.event && clickCounter.counter > 0) {
      const timer = setTimeout(() => {
        if (clickCounter.counter === 1) risksRiskLevelChartSingleClick(clickCounter.event);
        else if (clickCounter.counter === 2) risksRiskLevelChartDoubleClick(clickCounter.event);
        setClickCounter({ event: undefined, counter: 0 });
      }, DASHBOARD_DOUBLE_CLICK_DEPLAY);

      return () => {
        clearTimeout(timer);
      };
    }
  });

  const options = useMemo(() => {
    return {
      responsive: true,
      scales: {
        x: {
          display: false,
        },
        y: {
          display: false,
        },
      },
      plugins: {
        legend: {
          display: true,
          labels: {
            generateLabels: (/*chart: any*/) => {
              return [
                {
                  datasetIndex: 0,
                  fillStyle: '#66bb6a',
                  fontColor: '#666',
                  hidden: false,
                  index: 0,
                  lineWidth: 1,
                  pointStyle: undefined,
                  strokeStyle: 'rgba(0, 0, 0, 0)',
                  text: 'Low',
                },
                {
                  datasetIndex: 0,
                  fillStyle: '#f6f052',
                  fontColor: '#666',
                  hidden: false,
                  index: 1,
                  lineWidth: 1,
                  pointStyle: undefined,
                  strokeStyle: 'rgba(0, 0, 0, 0)',
                  text: 'Mid',
                },
                {
                  datasetIndex: 0,
                  fillStyle: '#ffa726',
                  fontColor: '#666',
                  hidden: false,
                  index: 2,
                  lineWidth: 1,
                  pointStyle: undefined,
                  strokeStyle: 'rgba(0, 0, 0, 0)',
                  text: 'High',
                },
                {
                  datasetIndex: 0,
                  fillStyle: '#f44336',
                  fontColor: '#666',
                  hidden: false,
                  index: 3,
                  lineWidth: 1,
                  pointStyle: undefined,
                  strokeStyle: 'rgba(0, 0, 0, 0)',
                  text: 'Crit',
                },
              ];
            },
          },
          onClick: (mouseEvent: any, legendItem: any) => {
            switch (legendItem.index) {
              case 0:
                setRiskLevelFilter('low', risksForRiskLevelChartDatasetsLabels[0]);
                break;
              case 1:
                setRiskLevelFilter('mid', risksForRiskLevelChartDatasetsLabels[1]);
                break;
              case 2:
                setRiskLevelFilter('high', risksForRiskLevelChartDatasetsLabels[2]);
                break;
              case 3:
                setRiskLevelFilter('crit', risksForRiskLevelChartDatasetsLabels[3]);
                break;
            }
          },
        },
      },
    };
  }, [risksForRiskLevelChartDatasetsLabels, setRiskLevelFilter]);

  const loadingState = useMemo(() => {
    if (risksRiskLevelsReportData === undefined) {
      return 'loading';
    }

    let countRiskLevels = 0;
    Object.entries(risksRiskLevelsReportData.riskLevels).forEach(([key, value]) => {
      countRiskLevels += value.crit + value.high + value.mid + value.low;
    });

    if (countRiskLevels > 0) {
      return 'loaded';
    }

    return 'empty';
  }, [risksRiskLevelsReportData]);

  return (
    <Paper elevation={3} sx={{ p: 2 }}>
      <Typography sx={{ flex: '1 1 100%', mb: 2 }} variant='body1' id='riskLevelChartBoxTitle' component='div'>
        Risk Level
      </Typography>
      <Box style={{ height: height, display: 'flex', justifyContent: 'center' }}>
        {loadingState === 'loading' && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {loadingState === 'empty' && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Stack spacing={1}>
              <Typography align='center'>No Data</Typography>
              <FontAwesomeIcon size='5x' icon={faFileCircleXmark} />
            </Stack>
          </Box>
        )}
        {risksRiskLevelsReportData && loadingState === 'loaded' && (
          <Pie
            data={risksForRiskLevelChartData as any}
            options={options}
            ref={risksRiskLevelCountChartRef}
            onClick={risksRiskLevelChartOnClick}
          />
        )}
      </Box>
    </Paper>
  );
};

export default RiskLevelChartBox;
