import { Paper, Stack } from '@mui/material';
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';

import { CompanyService } from '../../services/company.service';
import { UserCompanyDto } from '../../services/dto/users/user-company.dto';
import { UserDto } from '../../services/dto/users/user.dto';
import { UserService } from '../../services/user.service';
import UserModal from './user-modal';
import UsersTable from './users-table';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface UsersPageProps {}

const UsersPage: FunctionComponent<UsersPageProps> = ({ ...props }) => {
  const [users, setUsers] = useState<UserDto[]>([]);
  const [companies, setCompanies] = useState<UserCompanyDto[]>([]);
  const [editUser, setUserEdit] = useState<UserDto | undefined>(undefined);
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [userModalMode, setUserModalMode] = useState<'Create' | 'Edit'>('Create');
  const userService = useMemo(() => new UserService(), []);
  const companyService = useMemo(() => new CompanyService(), []);

  const fetchUsers = useCallback(async () => {
    const users = await userService.getUsers();
    setUsers(users.data);
  }, [userService]);

  const fetchCompanies = useCallback(async () => {
    const companies = await companyService.getCompanies();
    setCompanies(companies.data);
  }, [companyService]);

  const openAddUser = useCallback(() => {
    setUserEdit(undefined);
    setUserModalMode('Create');
    setUserModalOpen(true);
  }, []);

  const openEditUser = useCallback((user: UserDto) => {
    setUserEdit(user);
    setUserModalMode('Edit');
    setUserModalOpen(true);
  }, []);

  const onUserCreated = useCallback(
    (newUser: UserDto) => {
      setUsers((users) => [newUser, ...users]);
      setUserEdit(undefined);
    },
    [setUsers],
  );

  const onUserUpdated = useCallback(
    async (userId: string, updatedUser: UserDto) => {
      setUsers(
        users.map((user) => {
          if (user.id === userId) {
            return updatedUser;
          }
          return user;
        }),
      );
      setUserEdit(undefined);
    },
    [users],
  );

  const deleteUser = useCallback(
    async (userId: string) => {
      await userService.deleteUser(userId);
      setUsers((users) => users.filter((user) => user.id !== userId));
    },
    [userService],
  );

  useEffect(() => {
    fetchUsers();
    fetchCompanies();
  }, [fetchCompanies, fetchUsers]);

  return (
    <Stack spacing={3}>
      <Paper sx={{ p: 2 }}>
        <UsersTable users={users} deleteUser={deleteUser} openEditUser={openEditUser} openAddUser={openAddUser} />
      </Paper>
      <UserModal
        open={userModalOpen}
        onClose={() => {
          setUserModalOpen(false);
        }}
        onUserCreated={onUserCreated}
        onUserUpdated={onUserUpdated}
        mode={userModalMode}
        user={editUser}
        users={users}
        companies={companies}
      />
    </Stack>
  );
};

export default UsersPage;
