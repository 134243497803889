import { faAtom, faCubes, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, CardContent, CardHeader, Divider, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import { FunctionComponent } from 'react';

import { useSelectorUserData } from '../../redux/selector';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ProfileDetailsProps {}

const ProfileDetailsComponent: FunctionComponent<ProfileDetailsProps> = ({ ...props }) => {
  const { userData } = useSelectorUserData();

  return (
    <>
      <CardHeader title='Personal Information' />
      <CardContent>
        <Box>
          <Grid container spacing={5}>
            <Grid xs={12} md={6}>
              <Typography variant='body1' gutterBottom>
                <strong>Full name:</strong> {userData?.profile.firstname} {userData?.profile.lastname}
              </Typography>
            </Grid>
            <Grid xs={12} md={6}>
              <Typography variant='body1' gutterBottom>
                <strong>E-Mail:</strong> {userData?.email}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
      <div className='divider-box'></div>
      <Divider></Divider>
      <CardHeader title='Access & Authorizations' />
      <CardContent>
        <Box>
          <Grid container spacing={5}>
            <Grid xs={12} md={4} display='flex' justifyContent='center' alignItems='center'>
              <FontAwesomeIcon size='2xl' icon={faCubes} />
              <Typography variant='body1'>
                <b>Rulesets:</b> 200
              </Typography>
            </Grid>
            <Grid xs={12} md={4} display='flex' justifyContent='center' alignItems='center'>
              <FontAwesomeIcon size='2xl' icon={faTriangleExclamation} />
              <Typography variant='body1'>
                <b>Risks:</b> 150
              </Typography>
            </Grid>
            <Grid xs={12} md={4} display='flex' justifyContent='center' alignItems='center'>
              <FontAwesomeIcon size='2xl' icon={faAtom} />
              <Typography variant='body1'>
                <b>Functions:</b> 20
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </>
  );
};

export default ProfileDetailsComponent;
