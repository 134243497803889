import { Breadcrumbs, Link, Typography } from '@mui/material';
import { FunctionComponent } from 'react';

import { RiskFunctionDto } from '../../services/dto/functions/function.dto';
import { RiskDto } from '../../services/dto/risks/risk.dto';
import { RulesetDto } from '../../services/dto/rulesets/ruleset.dto';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RiskFunctionBreadcrumbsProps {
  ruleset?: RulesetDto;
  risk?: RiskDto;
  riskFunction: RiskFunctionDto;
}

const RiskFunctionBreadcrumbs: FunctionComponent<RiskFunctionBreadcrumbsProps> = ({
  ruleset,
  risk,
  riskFunction,
  /*...props*/
}) => {
  return (
    <Breadcrumbs separator='›' aria-label='breadcrumb'>
      {!ruleset && !risk && (
        <Link underline='hover' color='inherit' href={`/functions`}>
          Functions
        </Link>
      )}
      {ruleset && (
        <Link underline='hover' color='inherit' href={`/rulesets/${ruleset.id}`}>
          {ruleset.name}
        </Link>
      )}
      {ruleset && risk && (
        <Link underline='hover' color='inherit' href={`/rulesets/${ruleset.id}/risks/${risk.id}`}>
          {risk.name}
        </Link>
      )}
      {!ruleset && risk && (
        <Link underline='hover' color='inherit' href={`/risks/${risk.id}`}>
          {risk.name}
        </Link>
      )}
      <Typography color='text.primary'>
        <strong>{riskFunction.name}</strong>
      </Typography>
    </Breadcrumbs>
  );
};

export default RiskFunctionBreadcrumbs;
