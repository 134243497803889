import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { GridSlotsComponentsProps } from '@mui/x-data-grid';

declare module '@mui/x-data-grid' {
  interface FooterPropsOverrides {
    selected: number;
    onApplyMassDelete: () => void;
  }
}

export function FunctionDefinitionsDataGridFooter({
  selected,
  onApplyMassDelete,
}: NonNullable<GridSlotsComponentsProps['footer']>) {
  return (selected ?? 0) > 0 ? (
    <Stack direction={'row'} alignItems={'center'} spacing={1} sx={{ m: 1 }}>
      <Typography color='inherit' variant='body2' component='div'>
        {selected} selected Rows
      </Typography>
      <Tooltip title='Delete selected'>
        <IconButton
          size='small'
          onClick={() => {
            if (onApplyMassDelete) {
              onApplyMassDelete();
            }
          }}
        >
          <FontAwesomeIcon icon={faTrash} />
        </IconButton>
      </Tooltip>
    </Stack>
  ) : (
    <Stack direction={'row'} alignItems={'center'}></Stack>
  );
}
