import { Alert } from '@mui/material';
import { ConfirmOptions } from 'material-ui-confirm';

import { dialogActionButtonOptions } from '../../components/dialog-action-buttons-options';
import { RulesetDto } from '../../services/dto/rulesets/ruleset.dto';

export async function confirmTrashRuleset(confirm: (options?: ConfirmOptions) => Promise<void>, ruleset: RulesetDto) {
  const content = (() => {
    return <Alert severity='info'>Only the selected version will be trashed.</Alert>;
  })();

  return {
    dialog: confirm({
      title: `Are you sure you want to trash ${ruleset.name}?`,
      content: content,
      ...dialogActionButtonOptions,
    }),
  };
}

export async function confirmTrashRulesets(confirm: (options?: ConfirmOptions) => Promise<void>, rulesetIds: string[]) {
  const content = (() => {
    return <Alert severity='info'>Only the selected versions will be trashed.</Alert>;
  })();

  return {
    dialog: confirm({
      title: `Are you sure you want to trash ${rulesetIds.length} Rulesets`,
      content: content,
      ...dialogActionButtonOptions,
    }),
  };
}
