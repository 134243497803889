import { FunctionComponent, ReactNode, useCallback, useEffect } from 'react';
import { PropsWithChildren } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';

import { useSelectorUserData } from '../../redux/selector';
import { setAppPage } from '../../redux/slices/app-page.slice';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface PageProps {
  pageKey?: string;
  redirectPath: string;
  children?: ReactNode;
  roles?: string[];
  permissions?: string[];
}

const ProtectedRoute: FunctionComponent<PropsWithChildren<PageProps>> = ({
  pageKey,
  redirectPath,
  children,
  roles,
  permissions,
  /*...props*/
}) => {
  const dispatch = useDispatch();
  const { userData } = useSelectorUserData();

  const canAccess = useCallback(() => {
    /// @TODO: move to auth service or something, checking permissions
    if (!userData) {
      return false;
    }
    if (roles) {
      return roles.includes(userData.role.name);
    }
    if (permissions) {
      return permissions.some((permission) => userData.role.permissions.includes(permission));
    }
    return true;
  }, [userData, roles, permissions]);

  useEffect(() => {
    if (canAccess()) {
      if (pageKey) {
        dispatch(setAppPage(pageKey));
      }
    }
  }, [dispatch, canAccess, pageKey]);

  if (canAccess()) {
    return <div className='main-page-content'>{children ? children : <Outlet />}</div>;
  }
  return <div className='main-page-content'></div>;
};

export default ProtectedRoute;
