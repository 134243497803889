import { BusinessProcessDto } from '@/services/dto/business-process/business-process.dto';
import {
  faMinus,
  faPlus,
  faTrash,
  faPen,
  faAngleDown,
  faAngleRight,
  faArrowUp,
  faFileCircleXmark,
  faRotateLeft,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  TableContainer,
  TableBody,
  TableCell,
  Table,
  Stack,
  Button,
  Grid,
  TablePagination,
  Box,
  IconButton,
  Collapse,
  Paper,
  AutocompleteRenderGroupParams,
  Checkbox,
  Tooltip,
  Autocomplete,
  styled,
  lighten,
  darken,
  TextField,
  CircularProgress,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import clsx from 'clsx';
import { ChangeEvent, ReactNode, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ContentAuthorDto } from '../../services/dto/common/author.dto';
import { ContentTypeStatus } from '../../services/models/content-type-status';
import { extendStringFilterOperators } from '../../shared/data-grid';
import { searchStarFilter } from '../../shared/utils';
import {
  ContentRowsPerPageDefault,
  ContentRowsPerPageOptions,
  MainTableMinWidth,
  TableAutocompleteFieldWidth,
} from '../../styles/content-tables';
import { ChildrenTable } from '../children-table';
import { ContentTableHeader, TableHeadCell, TableOrder } from '../content-table-head';
import { ContentTableToolbar } from '../content-table-toolbar';
import ContentStatusChip from './content-status';
import ContentTableRow from './content-table-row';

export const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

export const GroupItems = styled('ul')({
  padding: 0,
});

export interface BaseContentType {
  id: string;
  name: string;
  displayName: string;
  riskType?: string;
  criticality?: number;
  status: ContentTypeStatus;
  latestUpdateAt: Date;
  trashedAt?: Date;
  businessProcess?: BusinessProcessDto | null;
  description: string;
  author: ContentAuthorDto;
}
type BaseContentOrderBy = 'name' | 'displayName' | 'status' | 'latestUpdateAt' | 'author';

interface ContentTableProps<
  OrderBy extends BaseContentOrderBy | string | number | symbol,
  Parent extends BaseContentType | undefined,
  Children extends BaseContentType | undefined,
  Row extends BaseContentType,
> {
  parent?: Parent;
  rows: Row[] | null;
  totalCount: number;
  extraHeads?: TableHeadCell<OrderBy>[];
  extraCells?: Record<string, (row: Row, key: string) => ReactNode>;
  tableToolbarTitle: string;
  sortTable: (order: TableOrder, orderBy: OrderBy, rows: Row[]) => Row[];
  openEdit?: (row: Row) => void;
  openCreateNewVersion?: (row: Row) => void;
  onRemoveFromParent?: (row: Row) => void;
  onDelete?: (row: Row) => void;
  massDeletion?: {
    onMassDelete: (rowIds: string[]) => Promise<boolean>;
    tooltipTitle: string;
    iconType: 'delete' | 'remove';
  };
  onTrash?: (row: Row) => void;
  massTrash?: {
    onMassTrash: (rowIds: string[]) => Promise<boolean>;
    tooltipTitle: string;
    iconType: 'delete' | 'remove';
  };
  onRestore?: (row: Row) => void;
  trash?: boolean;
  createButtonTitle?: string;
  autocompleteButtonTitle?: string;
  defaultOrderBy?: OrderBy;
  openCreate?: () => void;
  getDetailsUrl?: (row: Row) => string;
  loadChildren?: (row: Row) => Promise<Children[]>;
  template?: {
    allOptions: Row[];
    label: string;
    buttonLabel: string;
    addChildToParent: (parent: Parent, selectedChild: Row) => void;
    groupList?: {
      groupBy: (option: Row) => string;
      renderGroup: (params: AutocompleteRenderGroupParams) => ReactNode;
    };
  };
  showHeaders?: OrderBy[];
  disableMultiSelect?: boolean;
}

interface ChildrenCollapseProps<Row extends BaseContentType, Children extends BaseContentType | undefined> {
  row: Row;
  allChildren: Record<string, Children[] | undefined>;
  openRows: string[];
}
function ChildrenCollapse<Row extends BaseContentType, Children extends BaseContentType | undefined>({
  row,
  allChildren,
  openRows,
  /*...props*/
}: ChildrenCollapseProps<Row, Children>) {
  const collapseIn = useMemo(() => {
    return openRows.some((rid) => rid === row.id);
  }, [row, openRows]);

  const children = useMemo(() => {
    return allChildren[row.id];
  }, [allChildren, row]);

  return (
    <Collapse in={collapseIn}>
      {!children && (
        <Paper sx={{ width: '100%', bgcolor: grey[50], px: 4 }}>
          <Box sx={{ p: 3 }}>
            <CircularProgress />
          </Box>
        </Paper>
      )}
      {children && <ChildrenTable<Children> rows={children ?? []} />}
    </Collapse>
  );
}

export function ContentTable<
  OrderBy extends BaseContentOrderBy | string | number | symbol,
  Row extends BaseContentType,
  Children extends BaseContentType | undefined = undefined,
  Parent extends BaseContentType | undefined = undefined,
>({
  parent,
  rows,
  totalCount,
  extraHeads,
  extraCells,
  tableToolbarTitle,
  massDeletion,
  massTrash,
  sortTable,
  openEdit,
  openCreateNewVersion: openCreateNewVersion,
  onRemoveFromParent,
  onDelete,
  onTrash,
  onRestore,
  trash,
  openCreate,
  loadChildren,
  createButtonTitle,
  autocompleteButtonTitle,
  defaultOrderBy,
  getDetailsUrl,
  template,
  showHeaders,
  disableMultiSelect,
}: ContentTableProps<OrderBy, Parent, Children, Row>) {
  const [order, setOrder] = useState<TableOrder>('asc');
  const [orderBy, setOrderBy] = useState<OrderBy | undefined>(defaultOrderBy);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ContentRowsPerPageDefault);
  const [openRows, setOpenRows] = useState<string[]>([]);
  const [allChildren, setAllChildren] = useState<Record<string, Children[] | undefined>>({});
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [searchInput, setSearchInput] = useState('');
  const [selectedChild, setSelectedChild] = useState<Row | null>(null);
  const [newChildName, setNewChildName] = useState('');
  const [showAutocompleteField, setShowAutocompleteField] = useState(false);
  const [filter, setFilter] = useState({ column: '', operator: 'contains', value: '' });
  const [searchFilter, setSearchFilter] = useState('contains');

  const filteredChildren = useMemo(() => {
    return template?.allOptions.filter((child) => !rows?.some((it) => child.name === it.name)) ?? [];
  }, [template, rows]);

  const handleAddChildToParentButton = useCallback(() => {
    if (parent && selectedChild && template) {
      template.addChildToParent(parent, selectedChild);
    }
    setSelectedChild(null);
  }, [parent, selectedChild, template]);

  const navigate = useNavigate();
  const routeChange = (row: Row) => {
    if (getDetailsUrl) {
      const path = getDetailsUrl(row);

      navigate(path);
    }
  };

  const onSort = useCallback(
    (newOrderBy: OrderBy) => {
      const isAsc = orderBy === newOrderBy && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(newOrderBy);
    },
    [order, orderBy],
  );

  const toggleCollapse = useCallback(
    async (row: Row, index: number) => {
      const getChildren = async () => {
        if (loadChildren && (!(row.id in allChildren) || !allChildren[row.id])) {
          return loadChildren(row);
        }
        return row.id in allChildren ? allChildren[row.id] : undefined;
      };
      const newChildren = await getChildren();
      setOpenRows((openRows) => {
        if (!openRows.some((rid) => rid === row.id)) {
          setAllChildren((allChildren) => {
            return {
              ...allChildren,
              [row.id]: newChildren,
            };
          });
          return [...openRows, row.id];
        }
        return openRows.filter((rid) => rid !== row.id);
      });
    },
    [allChildren, loadChildren],
  );

  const handleCheckboxClick = useCallback(
    (_: any, id: string) => {
      const selectedIndex = selectedRows.indexOf(id);
      let newSelected: string[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selectedRows, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selectedRows.slice(1));
      } else if (selectedIndex === selectedRows.length - 1) {
        newSelected = newSelected.concat(selectedRows.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(selectedRows.slice(0, selectedIndex), selectedRows.slice(selectedIndex + 1));
      }
      setSelectedRows(newSelected);
    },
    [selectedRows],
  );

  const handleSelectedTrash = useCallback(async () => {
    const successfulTrashed = await massTrash?.onMassTrash(selectedRows);
    if (successfulTrashed) {
      setSelectedRows([]);
    }
  }, [selectedRows, massTrash]);
  const handleSelectedDelete = useCallback(async () => {
    const successfulDeleted = await massDeletion?.onMassDelete(selectedRows);
    if (successfulDeleted) {
      setSelectedRows([]);
    }
  }, [selectedRows, massDeletion]);

  const onPageChange = useCallback((_: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const onRowsPerPageChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const rowsState = useMemo(() => {
    if (rows === null) {
      return 'loading';
    }
    if (rows.length > 0) {
      return 'loaded';
    }
    return 'empty';
  }, [rows]);

  const filteredRows = useMemo(
    () =>
      rows
        ?.filter((row) => {
          if (filter.column && filter.value) {
            const filterOperators = extendStringFilterOperators();
            const filterOperator = filterOperators.find((fo) => fo.value === filter.operator);
            const filterFunction = filterOperator?.getApplyFilterFn(
              { field: filter.column, value: filter.value, operator: filter.operator },
              {
                field: filter.column,
              },
            );
            //NOTE: used any because filterFunction uses value, row and nothing else that we have access to here
            return filterFunction
              ? filterFunction({
                  value: filter.column in row ? row[filter.column as keyof BaseContentType] : '',
                  id: row.id,
                  row: row,
                } as any)
              : false;
          }
          return true;
        })
        .filter((row) => {
          return searchStarFilter(searchInput, [row.id, row.name, row.displayName]);
        }),
    [rows, searchInput, filter],
  );

  const visibleRows = useMemo(() => {
    if (filteredRows) {
      if (orderBy) {
        return sortTable(order, orderBy, filteredRows).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
      }
      return filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    }
  }, [order, orderBy, page, rowsPerPage, filteredRows, sortTable]);

  const handleSelectAllClick = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked && filteredRows) {
        const newSelected = filteredRows.map((n) => n.id);
        setSelectedRows(newSelected);
        return;
      }
      setSelectedRows([]);
    },
    [filteredRows],
  );

  const headCells = useMemo((): TableHeadCell<OrderBy>[] => {
    const leftHeads: TableHeadCell<OrderBy>[] = [
      !showHeaders || showHeaders.some((h) => h == 'name')
        ? {
            id: 'name' as OrderBy,
            numeric: false,
            disablePadding: false,
            label: 'ID',
          }
        : undefined,
      !showHeaders || showHeaders.some((h) => h == 'displayName')
        ? {
            id: 'displayName' as OrderBy,
            numeric: false,
            disablePadding: false,
            label: 'Name',
          }
        : undefined,
    ].filter((h) => h) as TableHeadCell<OrderBy>[];
    const rightHeads: TableHeadCell<OrderBy>[] = [
      (showHeaders === undefined && !trash) || showHeaders?.some((h) => h == 'latestUpdateAt')
        ? {
            id: 'latestUpdateAt' as OrderBy,
            numeric: false,
            disablePadding: false,
            label: 'Updated on',
          }
        : undefined,
      (showHeaders === undefined && !!trash) || showHeaders?.some((h) => h == 'trashedAt')
        ? {
            id: 'trashedAt' as OrderBy,
            numeric: false,
            disablePadding: false,
            label: 'Trashed on',
          }
        : undefined,
      (showHeaders === undefined && !trash) || (!trash && showHeaders?.some((h) => h == 'author'))
        ? {
            id: 'author' as OrderBy,
            numeric: false,
            disablePadding: false,
            label: 'Updated by',
          }
        : undefined,
      (showHeaders === undefined && !!trash) || (!!trash && showHeaders?.some((h) => h == 'author'))
        ? {
            id: 'author' as OrderBy,
            numeric: false,
            disablePadding: false,
            label: 'Trashed by',
          }
        : undefined,
      !showHeaders || showHeaders.some((h) => h == 'status')
        ? {
            id: 'status' as OrderBy,
            numeric: false,
            disablePadding: false,
            label: 'Status',
            align: 'left',
          }
        : undefined,
      // Actions
      {
        numeric: false,
        disablePadding: false,
        align: 'center',
      },
    ].filter((h) => h) as TableHeadCell<OrderBy>[];
    return extraHeads ? [...leftHeads, ...extraHeads, ...rightHeads] : [...leftHeads, ...rightHeads];
  }, [extraHeads, showHeaders, trash]);

  const extraTableCells = useCallback(
    (row: Row) => {
      if (extraCells) {
        const ret: ReactNode[] = [];
        for (const key in extraCells) {
          const getValue = extraCells[key];
          ret.push(
            <TableCell key={key} align='left'>
              {getValue(row, key)}
            </TableCell>,
          );
        }
        return ret;
      }
      return undefined;
    },
    [extraCells],
  );

  const isRowOpen = useCallback(
    (row: Row) => {
      return openRows.some((rid) => rid === row.id);
    },
    [openRows],
  );

  const openAllChildren = useCallback(() => {
    if (visibleRows) {
      setOpenRows(visibleRows.map((row) => row.id));
    }
  }, [visibleRows]);

  const closeAllChildren = useCallback(() => {
    setOpenRows([]);
  }, []);

  const DefaultButtonVariant = 'contained';
  const TableContainerMaxHeight = () => {
    if (parent) {
      return '35vh';
    } else return '75vh';
  }; //parent ? '35vh' : '75vh';

  return (
    <Stack spacing={2}>
      <ContentTableToolbar<Row>
        massTrash={
          !trash && massTrash
            ? {
                handleSelectedTrash,
                tooltipTitle: massTrash.tooltipTitle ?? 'Delete',
                iconType: massTrash.iconType ?? 'delete',
              }
            : undefined
        }
        massDeletion={
          trash && massDeletion
            ? {
                handleSelectedDelete,
                tooltipTitle: massDeletion.tooltipTitle ?? 'Delete',
                iconType: massDeletion.iconType ?? 'delete',
              }
            : undefined
        }
        title={tableToolbarTitle}
        totalCount={totalCount}
        numSelected={selectedRows.length}
        tableData={rows}
        setSearchInput={setSearchInput}
        searchFilter={searchFilter}
        setSearchFilter={setSearchFilter}
      />
      {rowsState === 'loading' && (
        <Box
          sx={{
            display: 'flex',
            minHeight: TableContainerMaxHeight(),
            maxHeight: TableContainerMaxHeight(),
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {rowsState === 'empty' && (
        <Box
          sx={{
            display: 'flex',
            minHeight: TableContainerMaxHeight(),
            maxHeight: TableContainerMaxHeight(),
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Stack spacing={1}>
            <Typography align='center'>No Data</Typography>
            <FontAwesomeIcon size='5x' icon={faFileCircleXmark} />
          </Stack>
        </Box>
      )}
      {rowsState === 'loaded' && filteredRows && visibleRows && (
        <TableContainer sx={{ minHeight: TableContainerMaxHeight(), maxHeight: TableContainerMaxHeight() }}>
          <Table sx={{ minWidth: MainTableMinWidth }} aria-labelledby='Content Table' size='small'>
            <ContentTableHeader<OrderBy>
              setFilter={setFilter}
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onSort={onSort}
              numSelected={selectedRows.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={filteredRows.length}
              childrenOptions={{
                openRows: openRows,
                /// @TODO: open all children and fetch content
                //openAllChildren: openAllChildren,
                closeAllChildren: closeAllChildren,
              }}
              disableMultiSelect={disableMultiSelect}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = selectedRows.includes(row.id);
                const labelId = `content-table-checkbox-${index}`;

                return (
                  <>
                    <ContentTableRow
                      key={row.id}
                      hover
                      tabIndex={-1}
                      aria-checked={isItemSelected}
                      sx={{ cursor: 'pointer' }}
                      className={clsx(
                        { draft: row.status === ContentTypeStatus.Draft },
                        !trash ? row.status : undefined,
                      )}
                      selected={isItemSelected}
                    >
                      {!disableMultiSelect && (
                        <TableCell key='selectAll' padding='checkbox'>
                          <Checkbox
                            onClick={(event) => {
                              handleCheckboxClick(event, row.id);
                            }}
                            color='primary'
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          />
                        </TableCell>
                      )}
                      <TableCell key='toggleCollapse' align='left'>
                        {loadChildren && (
                          <IconButton aria-label='expand row' size='small' onClick={() => toggleCollapse(row, index)}>
                            {isRowOpen(row) ? (
                              <FontAwesomeIcon icon={faAngleDown} />
                            ) : (
                              <FontAwesomeIcon icon={faAngleRight} />
                            )}
                          </IconButton>
                        )}
                      </TableCell>
                      {(!showHeaders || showHeaders.some((h) => h == 'name')) && (
                        <TableCell
                          key='name'
                          align='left'
                          onClick={() => {
                            routeChange(row);
                          }}
                        >
                          {row.name}
                        </TableCell>
                      )}
                      {(!showHeaders || showHeaders.some((h) => h == 'displayName')) && (
                        <TableCell
                          key='displayName'
                          align='left'
                          onClick={() => {
                            routeChange(row);
                          }}
                        >
                          {row.displayName}
                        </TableCell>
                      )}
                      {extraTableCells(row)}
                      {((showHeaders === undefined && !trash) || showHeaders?.some((h) => h == 'latestUpdateAt')) && (
                        <TableCell
                          key='latestUpdateAt'
                          align='left'
                          onClick={() => {
                            routeChange(row);
                          }}
                        >
                          {row.latestUpdateAt ? new Date(row.latestUpdateAt).toLocaleString('en-GB') : ''}
                        </TableCell>
                      )}
                      {((showHeaders === undefined && !!trash) || showHeaders?.some((h) => h == 'trashedAt')) && (
                        <TableCell
                          key='latestTrashedAt'
                          align='left'
                          onClick={() => {
                            routeChange(row);
                          }}
                        >
                          {row.trashedAt ? new Date(row.trashedAt).toLocaleString('en-GB') : ''}
                        </TableCell>
                      )}
                      {(!showHeaders || showHeaders.some((h) => h == 'author')) && (
                        <TableCell
                          key='author'
                          align='left'
                          onClick={() => {
                            routeChange(row);
                          }}
                        >
                          {trash && row.author.trashedByUsername}
                          {!trash && row.author.latestEditorUsername}
                        </TableCell>
                      )}
                      {(!showHeaders || showHeaders.some((h) => h == 'status')) && (
                        <TableCell
                          key='status'
                          align='left'
                          onClick={() => {
                            routeChange(row);
                          }}
                        >
                          <ContentStatusChip content={row} />
                        </TableCell>
                      )}
                      <TableCell key='actions' align='center'>
                        <Stack marginRight={3} justifyContent='end' direction='row' spacing={2}>
                          {row.status === ContentTypeStatus.Draft && openEdit && (
                            <Tooltip title='Edit Version'>
                              <IconButton
                                sx={{ justifyContent: 'end' }}
                                size='small'
                                onClick={() => {
                                  openEdit(row);
                                }}
                              >
                                <FontAwesomeIcon icon={faPen} />
                              </IconButton>
                            </Tooltip>
                          )}
                          {(trash ||
                            row.status === ContentTypeStatus.Deleted ||
                            row.status === ContentTypeStatus.Deprecated) &&
                            onRestore && (
                              <Tooltip title='Restore'>
                                <IconButton
                                  size='small'
                                  onClick={() => {
                                    onRestore(row);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faRotateLeft} />
                                </IconButton>
                              </Tooltip>
                            )}
                          {parent &&
                            parent.status === ContentTypeStatus.Draft &&
                            row.status !== ContentTypeStatus.Draft &&
                            row.status !== ContentTypeStatus.Deleted &&
                            row.status !== ContentTypeStatus.Deprecated &&
                            openCreateNewVersion && (
                              <Tooltip title='Create new Version'>
                                <IconButton
                                  size='small'
                                  onClick={() => {
                                    openCreateNewVersion(row);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faPen} />
                                </IconButton>
                              </Tooltip>
                            )}
                          {!parent &&
                            row.status !== ContentTypeStatus.Draft &&
                            row.status !== ContentTypeStatus.Deleted &&
                            row.status !== ContentTypeStatus.Deprecated &&
                            openCreateNewVersion && (
                              <Tooltip title='Create new Version'>
                                <IconButton
                                  size='small'
                                  onClick={() => {
                                    openCreateNewVersion(row);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faPen} />
                                </IconButton>
                              </Tooltip>
                            )}
                          {parent?.status === ContentTypeStatus.Draft && onRemoveFromParent && (
                            <Tooltip title='Remove'>
                              <IconButton
                                size='small'
                                onClick={() => {
                                  onRemoveFromParent(row);
                                }}
                              >
                                <FontAwesomeIcon icon={faMinus} />
                              </IconButton>
                            </Tooltip>
                          )}
                          {!parent && onTrash && (
                            <Tooltip title='Trash'>
                              <IconButton
                                size='small'
                                onClick={() => {
                                  onTrash(row);
                                }}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </IconButton>
                            </Tooltip>
                          )}
                          {!parent && onDelete && (
                            <Tooltip title='Delete'>
                              <IconButton
                                size='small'
                                onClick={() => {
                                  onDelete(row);
                                }}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Stack>
                      </TableCell>
                    </ContentTableRow>
                    <ContentTableRow key={`children-${row.id}`} className={row.status}>
                      {/* headCells.length + 2 is needed because of the new checkbox cell and collapsible cell, which is not included in the headcells  */}
                      <TableCell colSpan={headCells.length + 2} sx={{ p: 0, border: '0px' }}>
                        {loadChildren && (
                          <ChildrenCollapse<Row, Children> row={row} allChildren={allChildren} openRows={openRows} />
                        )}
                      </TableCell>
                    </ContentTableRow>
                  </>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Grid container alignItems={'center'}>
        <Grid item xs={6} md={6}>
          {rows && (
            <Stack direction={'row'} spacing={2}>
              {(!parent || parent.status === ContentTypeStatus.Draft) && openCreate && (
                <Button
                  variant={DefaultButtonVariant}
                  color='success'
                  startIcon={<FontAwesomeIcon icon={faPlus} />}
                  onClick={openCreate}
                >
                  {createButtonTitle ?? 'Create'}
                </Button>
              )}
              {parent && template && !showAutocompleteField && parent.status === ContentTypeStatus.Draft && (
                <Button
                  variant={DefaultButtonVariant}
                  color='success'
                  startIcon={<FontAwesomeIcon icon={faArrowUp} />}
                  onClick={() => {
                    setShowAutocompleteField(!showAutocompleteField);
                  }}
                >
                  {autocompleteButtonTitle ?? 'Add existing'}
                </Button>
              )}
              {parent && template && showAutocompleteField && parent.status === ContentTypeStatus.Draft && (
                <Stack direction={'row'}>
                  <Autocomplete
                    size='small'
                    sx={{ width: TableAutocompleteFieldWidth }}
                    value={selectedChild}
                    onChange={(_, newSelectedChild: Row | null) => {
                      setSelectedChild(newSelectedChild);
                    }}
                    inputValue={newChildName}
                    onInputChange={(_, newChildName) => {
                      setNewChildName(newChildName);
                    }}
                    options={filteredChildren}
                    autoHighlight
                    getOptionLabel={(option) => `${option.name} - ${option.displayName}`}
                    groupBy={template.groupList?.groupBy}
                    renderGroup={(params) => {
                      if (template.groupList?.renderGroup) {
                        return template.groupList.renderGroup(params) ?? <GroupItems>{params.children}</GroupItems>;
                      }
                    }}
                    renderOption={(props, option) => (
                      <Box component='li' {...props}>
                        <div
                          style={{
                            maxWidth: '100%',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >{`${option.name} - ${option.displayName}`}</div>
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={template.label}
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                  <Tooltip title={template.buttonLabel}>
                    <IconButton disabled={!selectedChild} onClick={handleAddChildToParentButton}>
                      <FontAwesomeIcon icon={faArrowUp} />
                    </IconButton>
                  </Tooltip>
                </Stack>
              )}
            </Stack>
          )}{' '}
        </Grid>
        <Grid item xs={6} md={6}>
          <Box paddingX={2} width={'100%'} display={'flex'} justifyContent={'end'}>
            {rows && (
              <TablePagination
                rowsPerPageOptions={ContentRowsPerPageOptions}
                // @FIXME: p slots style
                // slotProps={{ selectLabel: { style: {paddingTop: '1rem'} }, displayedRows: { style: {paddingTop: '1rem' } } }}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
}
