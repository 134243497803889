import { faFunction, faCubes, faTriangleExclamation } from '@awesome.me/kit-6741fca89c/icons/classic/light';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Grid, Link, List, ListItem, Paper, Typography } from '@mui/material';
import { useMemo } from 'react';

import { LastChangedReportDto } from '../../../services/dto/reports/last-changed-content-report.dto';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface LastChangedListProps {
  lastChangedReportData: LastChangedReportDto[] | undefined;
  changed: number;
  height?: string | number;
}

const LastChangedList = ({ lastChangedReportData, changed, height }: LastChangedListProps) => {
  const linkList = useMemo(
    () =>
      lastChangedReportData?.map((item, index) => {
        switch (item.type) {
          case 'ruleset':
            return {
              prefix: `${index + 1}.`,
              href: `/rulesets/${item.id}`,
              displayName: item.displayName,
              icon: faCubes,
            };
          case 'risk':
            return {
              prefix: `${index + 1}.`,
              href: `/risks/${item.id}`,
              displayName: item.displayName,
              icon: faTriangleExclamation,
            };
          case 'function':
            return {
              prefix: `${index + 1}.`,
              href: `/functions/${item.id}`,
              displayName: item.displayName,
              icon: faFunction,
            };
        }
      }),
    [lastChangedReportData],
  );

  return (
    <Paper elevation={3} sx={{ p: 2 }}>
      <Typography sx={{ flex: '1 1 100%', mb: 2 }} variant='body1' id='lastChangedTitle' component='div'>
        Last {changed} Changed
      </Typography>
      <Box sx={{ height: height, width: '100%' }}>
        <List sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          {linkList?.map((item, index) => (
            <ListItem key={index}>
              <Grid container sx={{ display: 'flex', alignContent: 'space-around', alignItems: 'center' }}>
                <Grid item md={10}>
                  <Typography
                    sx={{
                      flex: '1 1 100%',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                    variant='subtitle1'
                    id='tableTitle3'
                    component='div'
                  >
                    {item?.prefix}

                    <Link color='inherit' href={item?.href}>
                      {' '}
                      {item?.displayName}
                    </Link>
                  </Typography>
                </Grid>
                <Grid item md={2} sx={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column' }}>
                  <FontAwesomeIcon size='2x' icon={item?.icon} />
                </Grid>
              </Grid>
            </ListItem>
          ))}
        </List>
      </Box>
    </Paper>
  );
};

export default LastChangedList;
