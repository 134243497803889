import { Fragment, FunctionComponent, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { clearUserData, setUserData } from '../../redux/slices/user-data.slice';
import { AuthService } from '../../services/auth.service';
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface LoadUserDataProps {
  redirectPath?: string;
}

const defaultHomePath = '/dashboard';

const LoadUserData: FunctionComponent<PropsWithChildren<LoadUserDataProps>> = ({
  redirectPath,
  children,
  /*...props*/
}) => {
  const dispatch = useDispatch();
  const [root, setRoot] = useState(<></>);
  //const navigate = useNavigate();
  const authService = useMemo(() => new AuthService(), []);

  const logout = useCallback(async () => {
    dispatch(clearUserData());
    authService.logout();
    setRoot(<Navigate to='/login' replace />);
  }, [authService, dispatch]);

  const fetchUserData = useCallback(async () => {
    const user = await authService.loadUserData().catch((error) => {
      if (error?.response) {
        if (error?.response?.status === 401) {
          return undefined;
        }
      }
      throw error;
    });

    if (user) {
      dispatch(setUserData(user));
      setRoot(<Navigate to={redirectPath ?? defaultHomePath} replace />);
      return;
    }

    logout();
    return;
  }, [authService, dispatch, logout, redirectPath]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  /// @TODO: make loading page for loading user data (after login)
  return <Fragment>{root}</Fragment>;
};

export default LoadUserData;
