import { RiskFunctionDto } from '../../../services/dto/functions/function.dto';
import { TableOrder, sortTableData } from '../../content-table-head';

export type RiskFunctionsOrder = TableOrder;
export type RiskFunctionsOrderBy = 'name' | 'displayName' | 'finalizedAt' | 'author' | 'status';

export function sortRiskFunctions(
  order: RiskFunctionsOrder,
  orderBy: RiskFunctionsOrderBy,
  riskFunctions: RiskFunctionDto[],
) {
  return sortTableData(order, orderBy, riskFunctions, (key, a, b) => {
    if (key === 'author') {
      return a.latestEditorUsername.localeCompare(b.latestEditorUsername);
    }
    return undefined;
  });
}
