import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AppState {
  appPage: string;
}

const initialState: AppState = {
  appPage: '',
};

export const appPageSlice = createSlice({
  name: 'appPage',
  initialState,
  reducers: {
    setAppPage: (state, action: PayloadAction<string>) => {
      state.appPage = action.payload;
    },
  },
});

export const { setAppPage } = appPageSlice.actions;

export default appPageSlice.reducer;
