import { faAngleDown, faAngleRight, faFilter, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Checkbox,
  IconButton,
  MenuItem,
  ModalProps,
  Popover,
  Stack,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';

import { ContentTypeStatus } from '../../services/models/content-type-status';

export type TableOrder = 'asc' | 'desc';

export interface TableHeadCell<OrderBy> {
  disablePadding: boolean;
  id?: OrderBy;
  label?: string;
  numeric: boolean;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
}

export interface HeadTableProps<OrderBy> {
  setFilter: Dispatch<
    SetStateAction<{
      column: string;
      operator: string;
      value: string;
    }>
  >;
  onSelectAllClick?: (event: ChangeEvent<HTMLInputElement>) => void;
  onSort: (newOrderBy: OrderBy) => void;
  order: TableOrder;
  orderBy?: OrderBy;
  headCells: TableHeadCell<OrderBy>[];
  numSelected: number;
  rowCount: number;
  childrenOptions?: {
    openRows: string[];
    openAllChildren?: () => void;
    closeAllChildren?: () => void;
  };
  disableMultiSelect?: boolean;
}

export interface ContentHeadTableProps<OrderBy> {
  onSort: (newOrderBy: OrderBy) => void;
  order: TableOrder;
  orderBy: OrderBy;
}

export function sortTableDataStatus<T extends Record<string, any>>(order: TableOrder, data: T[]) {
  return data.sort((a, b) => {
    if ('status' in a && 'status' in b) {
      switch (order) {
        case 'asc':
          if (a.status === ContentTypeStatus.Deleted || a.status === ContentTypeStatus.Deprecated) {
            return 1;
          }
          if (b.status === ContentTypeStatus.Deleted || b.status === ContentTypeStatus.Deprecated) {
            return -1;
          }
          return a.status.toString().localeCompare(b.status.toString());
        case 'desc':
          if (b.status === ContentTypeStatus.Deleted || b.status === ContentTypeStatus.Deprecated) {
            return 1;
          }
          if (a.status === ContentTypeStatus.Deleted || a.status === ContentTypeStatus.Deprecated) {
            return -1;
          }
          return b.status.toString().localeCompare(a.status.toString());
      }
    }

    return 0;
  });
}

export function sortTableData<
  OrderBy extends string | number | symbol,
  T extends Record<OrderBy, string | number | Date | undefined | object>,
>(
  order: TableOrder,
  orderBy: OrderBy,
  data: T[],
  predSort?: (key: string | number | symbol, a: any, b: any) => number | undefined,
) {
  const compareData = (key: OrderBy, a: T, b: T, dateFallbackCompare?: () => number) => {
    const value1 = a[key];
    const value2 = b[key];
    if (value1 && value2) {
      if (typeof value1 === 'string' && typeof value2 === 'string') {
        return value1.localeCompare(value2);
      } else if (typeof value1 === 'number' && typeof value2 === 'number') {
        return value1 - value2;
      } else if (value1 instanceof Date || value2 instanceof Date) {
        if (value1 instanceof Date && value2 instanceof Date) {
          if (!isNaN(value1.getTime()) && !isNaN(value2.getTime())) {
            return new Date(value1).toISOString().localeCompare(new Date(value2).toISOString()) || 0;
          } else if (!isNaN(value1.getTime()) && isNaN(value2.getTime())) {
            return -1;
          } else if (isNaN(value1.getTime()) && !isNaN(value2.getTime())) {
            return 1;
          }
        }
        /// @FIXME: compare invalid dates
        return dateFallbackCompare ? dateFallbackCompare() : 0;
      } else if (value1 && value2) {
        if (predSort) {
          const v1 = typeof value1 === 'object' ? JSON.stringify(value1) : value1.toString();
          const v2 = typeof value2 === 'object' ? JSON.stringify(value2) : value2.toString();
          return predSort(key, value1, value2) ?? v1.localeCompare(v2);
        }
        return JSON.stringify(value1).localeCompare(JSON.stringify(value2));
      }
    }

    if (value1 && !value2) {
      return 1;
    } else if (!value1 && value2) {
      return -1;
    }

    return 0;
  };

  const newData = data.sort((a, b) => {
    switch (order) {
      case 'asc':
        return compareData(orderBy, a, b, () => (a[orderBy] ? -1 : b[orderBy] ? 1 : 0));
      case 'desc':
        return compareData(orderBy, b, a, () => (a[orderBy] ? -1 : b[orderBy] ? 1 : 0));
    }
    return 0;
  });

  if (orderBy === 'status') {
    return sortTableDataStatus(order, newData);
  }

  return newData;
}

interface ContentTableHeaderFilterProps<OrderBy> {
  openPopover: (event: React.MouseEvent<HTMLButtonElement>, column: string) => void;
  headCell: TableHeadCell<OrderBy>;
  anchorEl: HTMLButtonElement | null;
  clearFilter: () => void;
  closePopover: ModalProps['onClose'];
  filterColumn: string;
  setFilterColumn: Dispatch<SetStateAction<string>>;
  headCells: TableHeadCell<OrderBy>[];
  filterOperator: string;
  setFilterOperator: Dispatch<SetStateAction<string>>;
  filterValue: string;
  setFilterValue: Dispatch<SetStateAction<string>>;
}
export function ContentTableHeaderFilter<OrderBy>({
  openPopover,
  headCell,
  anchorEl,
  clearFilter,
  closePopover,
  filterColumn,
  setFilterColumn,
  headCells,
  filterOperator,
  setFilterOperator,
  filterValue,
  setFilterValue,
  /*...props*/
}: ContentTableHeaderFilterProps<OrderBy>) {
  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);
  const popOverId = useMemo(() => (open ? 'simple-popover' : undefined), [open]);

  return (
    <Stack spacing={0}>
      <IconButton
        sx={{ alignItems: 'end' }}
        size='small'
        onClick={(event) => {
          openPopover(event, headCell.id as string);
        }}
      >
        <FontAwesomeIcon icon={faFilter} />
      </IconButton>
      <Popover
        id={popOverId}
        open={open}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        elevation={1}
      >
        <Box p={1} sx={{ width: '400px' }}>
          <Stack direction='row' spacing={1}>
            <IconButton
              size='small'
              onClick={() => {
                clearFilter();
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </IconButton>
            <TextField
              fullWidth
              variant='standard'
              id={`filter-column-${headCell.id ?? 'table-head'}`}
              select
              label='Columns'
              value={filterColumn}
              onChange={(event) => {
                setFilterColumn(event.target.value);
              }}
            >
              {headCells
                .filter((option) => option.id)
                .map((option) => (
                  <MenuItem key={option.id as string} value={option.id as string}>
                    {option.label}
                  </MenuItem>
                ))}
            </TextField>

            <TextField
              fullWidth
              variant='standard'
              id={`filter-operator-${headCell.id ?? 'table-head'}`}
              select
              label='Operator'
              value={filterOperator}
              onChange={(event) => {
                setFilterOperator(event.target.value);
              }}
            >
              <MenuItem key={'contains'} value={'contains'}>
                contains
              </MenuItem>
              <MenuItem key={'equals'} value={'equals'}>
                equals
              </MenuItem>
              <MenuItem key={'startsWith'} value={'startsWith'}>
                starts with
              </MenuItem>
              <MenuItem key={'endsWith'} value={'endsWith'}>
                ends with
              </MenuItem>
              <MenuItem key={'isEmpty'} value={'isEmpty'}>
                is empty
              </MenuItem>
              <MenuItem key={'isNotEmpty'} value={'isNotEmpty'}>
                is not empty
              </MenuItem>
              <MenuItem key={'isAnyOf'} value={'isAnyOf'}>
                is any of
              </MenuItem>
              <MenuItem key={'doesntContain'} value={'doesntContain'}>
                does not contain
              </MenuItem>
              <MenuItem key={'doesntEqual'} value={'doesntEqual'}>
                does not equal
              </MenuItem>
            </TextField>
            <TextField
              fullWidth
              variant='standard'
              id={`filter-value-${headCell.id ?? 'table-head'}`}
              label='Value'
              placeholder='Filter value'
              value={filterValue}
              onChange={(event) => {
                setFilterValue(event.target.value);
              }}
            />
          </Stack>
        </Box>
      </Popover>
    </Stack>
  );
}

export function ContentTableHeader<OrderBy>({
  headCells,
  order,
  orderBy,
  onSort,
  onSelectAllClick,
  setFilter,
  numSelected,
  rowCount,
  childrenOptions,
  disableMultiSelect,
  /*...props*/
}: HeadTableProps<OrderBy>) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [filterColumn, setFilterColumn] = useState('');
  const [filterOperator, setFilterOperator] = useState('contains');
  const [filterValue, setFilterValue] = useState('');

  const openPopover = (event: React.MouseEvent<HTMLButtonElement>, column: string) => {
    setAnchorEl(event.currentTarget);
    setFilterColumn(column);
    setFilterValue('');
  };

  const closePopover = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const clearFilter = useCallback(() => {
    setFilterColumn('');
    setFilterOperator('contains');
    setFilterValue('');
  }, []);

  const isSomeRowOpen = useMemo(() => {
    return (childrenOptions?.openRows.length ?? 0) > 0;
  }, [childrenOptions]);

  const toggleCollapseChildren = useCallback(() => {
    if (isSomeRowOpen && childrenOptions?.closeAllChildren) {
      childrenOptions.closeAllChildren();
      return;
    }
    if (childrenOptions?.openAllChildren) {
      childrenOptions.openAllChildren();
    }
  }, [childrenOptions, isSomeRowOpen]);

  useEffect(() => {
    setFilter({ column: filterColumn, operator: filterOperator, value: filterValue });
  }, [filterColumn, filterOperator, filterValue]);

  return (
    <TableHead>
      <TableRow>
        {!disableMultiSelect && (
          <TableCell key='selectAll' padding='checkbox'>
            {onSelectAllClick && (
              <Checkbox
                color='primary'
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{
                  'aria-label': 'select all rows',
                }}
              />
            )}
          </TableCell>
        )}
        <TableCell
          key='toggleCollapseChildren'
          padding='none'
          align='center'
          onClick={() => {
            toggleCollapseChildren();
          }}
        >
          {childrenOptions &&
            (isSomeRowOpen ? <FontAwesomeIcon icon={faAngleDown} /> : <FontAwesomeIcon icon={faAngleRight} />)}
        </TableCell>

        {headCells.map((headCell, index) => (
          <TableCell
            key={(headCell.id as string) ?? index.toString()}
            align={headCell.align ?? 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <Stack direction={'row'} spacing={1}>
              {headCell.label && headCell.id && (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={() => {
                    headCell.id ? onSort(headCell.id) : undefined;
                  }}
                  sx={{ fontWeight: 'bold' }}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              )}
              {headCell.label && !headCell.id && <strong>{headCell.label}</strong>}
              {headCell.id && (
                <ContentTableHeaderFilter
                  openPopover={openPopover}
                  headCell={headCell}
                  headCells={headCells}
                  anchorEl={anchorEl}
                  clearFilter={clearFilter}
                  closePopover={closePopover}
                  filterColumn={filterColumn}
                  setFilterColumn={setFilterColumn}
                  filterOperator={filterOperator}
                  setFilterOperator={setFilterOperator}
                  filterValue={filterValue}
                  setFilterValue={setFilterValue}
                />
              )}
            </Stack>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
