import clsx from 'clsx';
import { Dispatch, SetStateAction } from 'react';
import { SubMenu, MenuItem } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';

import { RouteMenuElement } from '../../routes/route-menu-element';

interface MainMenuElementProps {
  appPage: string;
  routes: RouteMenuElement[];
  sidebarCollapsed: boolean;
  currentSubMenuOpen: number | undefined;
  setCurrentSubMenuOpen: Dispatch<SetStateAction<number | undefined>>;
}

export function MainMenuElement({
  appPage,
  routes,
  sidebarCollapsed,
  currentSubMenuOpen,
  setCurrentSubMenuOpen,
  /*...props*/
}: MainMenuElementProps) {
  return (
    <>
      {routes.map((route, index) => {
        if (route.sidebarProps) {
          if ('text' in route.sidebarProps) {
            return (
              <li className='mx-4 my-2 ps-menuitem-root menu-item menu-item-header' key={route.path ?? index}>
                <div className='ps-menu-button'>
                  <span className={clsx({ 'menu-item-text': true, 'd-none': sidebarCollapsed })}>
                    {route.sidebarProps.text}
                  </span>
                </div>
              </li>
            );
          }

          const children = route.children?.filter((child) => child.sidebarProps);
          if (children?.length) {
            return (
              <SubMenu
                active={currentSubMenuOpen !== index && children.some((child) => child.path === appPage)}
                open={currentSubMenuOpen === index}
                onOpenChange={(open) => {
                  if (open) {
                    setCurrentSubMenuOpen(index);
                  } else {
                    setCurrentSubMenuOpen(undefined);
                  }
                }}
                className='sub-menu'
                icon={route.sidebarProps.icon}
                label={route.sidebarProps.title}
                key={route.path ?? index}
              >
                <MainMenuElement
                  key={route.path ?? index}
                  appPage={appPage}
                  routes={children}
                  sidebarCollapsed={sidebarCollapsed}
                  currentSubMenuOpen={currentSubMenuOpen}
                  setCurrentSubMenuOpen={setCurrentSubMenuOpen}
                />
              </SubMenu>
            );
          }

          if (route.path) {
            return (
              <MenuItem
                active={route.path === appPage}
                className='menu-item'
                icon={route.sidebarProps.icon}
                component={<Link to={route.path} />}
                disabled={route.sidebarProps.disabled}
                key={route.path ?? index}
              >
                {route.sidebarProps.title}
              </MenuItem>
            );
          }

          return (
            <MenuItem
              className='menu-item'
              icon={route.sidebarProps.icon}
              disabled={route.sidebarProps.disabled}
              key={index}
            >
              {route.sidebarProps.icon}
              {route.sidebarProps.title}
            </MenuItem>
          );
        }
        return <></>;
      })}
    </>
  );
}
