import { useMemo } from 'react';

import { AuthService } from './auth.service';
import { BusinessProcessService } from './business-process.service';
import { ReportsService } from './reports.service';
import { RiskFunctionService } from './risk-function.service';
import { RiskService } from './risk.service';
import { RulesetService } from './ruleset.service';

export function useRulesetService() {
  const rulesetService = useMemo(() => new RulesetService(), []);
  return rulesetService;
}
export function useRiskService() {
  const riskService = useMemo(() => new RiskService(), []);
  return riskService;
}
export function useRiskFunctionService() {
  const riskFunctionService = useMemo(() => new RiskFunctionService(), []);
  return riskFunctionService;
}
export function useAuthService() {
  const authService = useMemo(() => new AuthService(), []);
  return authService;
}

export function useBusinessProcessService() {
  const businessProcessService = useMemo(() => new BusinessProcessService(), []);
  return businessProcessService;
}

export function useReportService() {
  const reportsService = useMemo(() => new ReportsService(), []);
  return reportsService;
}
