import { FunctionComponent, useCallback, useEffect, PropsWithChildren, useMemo } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import MainMenu from '../../components/main-menu';
import { useSelectorUserData } from '../../redux/selector';
import { clearUserData, setUserData } from '../../redux/slices/user-data.slice';
import { AuthService } from '../../services/auth.service';
import MainContent from './content';
import MainFooter from './footer';
import MainHeader from './header';

import './index.scss';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AppLayoutProps {}

const MainLayout: FunctionComponent<PropsWithChildren<AppLayoutProps>> = ({ children /*...props*/ }) => {
  const dispatch = useDispatch();
  const { userData } = useSelectorUserData();
  const authService = useMemo(() => new AuthService(), []);
  const navigate = useNavigate();

  const logout = useCallback(() => {
    dispatch(clearUserData());
    authService.logout();
    navigate('/login', { replace: true });
  }, [authService, dispatch, navigate]);

  const fetchUserData = useCallback(async () => {
    if (!userData) {
      if (!authService.hasValidAccessToken()) {
        logout();
        return;
      }
      const user = await authService.loadUserData().catch((error) => {
        if (error?.response) {
          if (error?.response?.status === 401) {
            return undefined;
          }
        }
        throw error;
      });

      if (user) {
        dispatch(setUserData(user));
        return;
      }

      logout();
    }
  }, [authService, dispatch, logout, userData]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  return (
    <div className='main-wrapper main-wrapper-responsive-lg'>
      <MainMenu />
      <Container className='main-container' fluid='2xl'>
        <MainHeader />
        <MainContent />
        <MainFooter />
      </Container>
    </div>
  );
};
export default MainLayout;
