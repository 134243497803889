import { ImportCF, ImportCFCombo } from './dto/import/cf-import.dto';
import { HttpService } from './http.service';

export class ImportService {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(private httpService: HttpService = new HttpService('import')) {}

  async importCF(body: ImportCF) {
    const form = new FormData();
    if (body.cad) {
      form.append('cad', body.cad);
    }
    if (body.catt) {
      form.append('catt', body.catt);
    }
    if (body.cavd) {
      form.append('cavd', body.cavd);
    }
    if (body.cavtt) {
      form.append('cavtt', body.cavtt);
    }
    if (body.ccd) {
      form.append('ccd', body.ccd);
    }
    if (body.cctt) {
      form.append('cctt', body.cctt);
    }
    if (body.ccvd) {
      form.append('ccvd', body.ccvd);
    }
    if (body.ccvtt) {
      form.append('ccvtt', body.ccvtt);
    }
    const response = await this.httpService.postCreated('cf', form);
    return response.data;
  }
  async importCFCombo(body: ImportCFCombo) {
    const form = new FormData();
    if (body.cad) {
      form.append('cad', body.cad);
    }
    if (body.catt) {
      form.append('catt', body.catt);
    }
    if (body.cavd) {
      form.append('cavd', body.cavd);
    }
    if (body.cavtt) {
      form.append('cavtt', body.cavtt);
    }
    if (body.ccd) {
      form.append('ccd', body.ccd);
    }
    if (body.cctt) {
      form.append('cctt', body.cctt);
    }
    if (body.ccvd) {
      form.append('ccvd', body.ccvd);
    }
    if (body.ccvtt) {
      form.append('ccvtt', body.ccvtt);
    }
    if (body.name || body.displayName) {
      form.append('name', body.name);
      form.append('displayName', body.displayName);
    }
    const response = await this.httpService.postCreated('cf/combo', form);
    return response.data;
  }
}
