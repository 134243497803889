import { Box } from '@mui/material';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { FieldValues } from 'react-hook-form';

import { CreateFromExistingFunctionDto } from '../../services/dto/functions/create-from-existing-function.dto';
import { CreateRiskFunctionDto } from '../../services/dto/functions/create-function.dto';
import { RiskFunctionDto } from '../../services/dto/functions/function.dto';
import { UpdateRiskFunctionDto } from '../../services/dto/functions/update-function.dto';
import { RiskDto } from '../../services/dto/risks/risk.dto';
import { RulesetDto } from '../../services/dto/rulesets/ruleset.dto';
import { useRiskFunctionService, useRiskService } from '../../services/hooks';
import { CreateFormData, CreateModal } from '../create-modal';
import CreateNewVersionModal, { CreateNewVersionFormData } from '../create-new-version-modal';
import UpdateModal, { UpdateFormData } from '../update-modal';
import RiskFunctionsTable from './risk-functions-table';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RiskFunctionsComponentProps {
  ruleset?: RulesetDto;
  risk: RiskDto;
  riskFunctions: RiskFunctionDto[] | null;
  totalCount: number;
  rulesetsFromRisk: RulesetDto[];
  onRiskFunctionCreated: (newRiskFunction: RiskFunctionDto) => void;
  onRiskFunctionAdded: (riskId: string, functionId: string, riskFunction: RiskFunctionDto | null) => void;
  onRiskFunctionUpdated: (id: string, data: RiskFunctionDto) => void;
  onRiskFunctionRemoved: (riskId: string, functionId: string) => void;
  onRiskFunctionsRemoved: (functions: string[], riskId: string) => void;
  onRiskFunctionNewVersionCreated: (newRiskFunction: RiskFunctionDto, origin: RiskFunctionDto) => void;
}

const RiskFunctionsComponent: FunctionComponent<RiskFunctionsComponentProps> = ({
  ruleset,
  risk,
  riskFunctions,
  totalCount,
  onRiskFunctionCreated,
  onRiskFunctionAdded,
  onRiskFunctionUpdated,
  onRiskFunctionRemoved,
  onRiskFunctionsRemoved,
  onRiskFunctionNewVersionCreated,
  rulesetsFromRisk,
  /*...props*/
}) => {
  const [allRiskFunctions, setAllRiskFunctions] = useState<RiskFunctionDto[]>([]);
  const [modalRiskFunction, setModalRiskFunction] = useState<RiskFunctionDto | undefined>(undefined);
  const [currentOpenModal, setCurrentOpenModal] = useState<'create' | 'update' | 'createNewVersion' | undefined>(
    undefined,
  );
  const riskService = useRiskService();
  const riskFunctionService = useRiskFunctionService();

  const fetchAllRiskFunctions = useCallback(async () => {
    const riskFunctions = await riskFunctionService.getFunctions();
    setAllRiskFunctions(riskFunctions.data);
  }, [riskFunctionService]);

  const openCreateRiskFunction = useCallback(() => {
    setCurrentOpenModal('create');
  }, []);

  const openEditRiskFunction = useCallback((data: RiskFunctionDto) => {
    setModalRiskFunction(data);
    setCurrentOpenModal('update');
  }, []);

  const openCreateNewVersionRiskFunction = useCallback((data: RiskFunctionDto) => {
    setModalRiskFunction(data);
    setCurrentOpenModal('createNewVersion');
  }, []);

  const closeModal = useCallback(() => {
    setCurrentOpenModal(undefined);
    setModalRiskFunction(undefined);
  }, []);

  const onRiskFunctionCreate = useCallback(
    async (
      data: FieldValues,
      origin?: RiskFunctionDto,
      checkedChildren?: string[] /*@TODO: needed for order, better handling*/,
    ) => {
      const res = await (() => {
        if (origin) {
          return riskFunctionService.createFromExistingFunction(origin.id, {
            name: data.name,
            displayName: data.displayName,
            description: data.description,
            riskId: risk.id,
            keepReference: data.keepReference,
          });
        }
        return riskFunctionService.createFunction({
          name: data.name,
          displayName: data.displayName,
          description: data.description,
          riskId: risk.id,
        });
      })();
      onRiskFunctionCreated(res.data);
      closeModal();
    },
    [closeModal, onRiskFunctionCreated, risk.id, riskFunctionService],
  );

  const onRiskFunctionNewVersionCreate = useCallback(
    async (id: string, createNewVersionData: FieldValues, origin: RiskFunctionDto) => {
      const res = await riskFunctionService.createNewFunctionVersion(id, {
        displayName: createNewVersionData.displayName,
        description: createNewVersionData.description,
        riskId: risk.id,
      });
      onRiskFunctionNewVersionCreated(res.data, origin);
      closeModal();
    },
    [riskFunctionService, risk.id, onRiskFunctionNewVersionCreated, closeModal],
  );

  const onRiskFunctionUpdate = useCallback(
    async (id: string, data: FieldValues) => {
      const res = await riskFunctionService.updateFunction(id, {
        name: data.name,
        displayName: data.displayName,
        description: data.description,
      });
      onRiskFunctionUpdated(id, res.data);
      closeModal();
    },
    [closeModal, onRiskFunctionUpdated, riskFunctionService],
  );

  const removeRiskFunctionFromRisk = useCallback(
    async (risk: RiskDto, functionId: string) => {
      await riskService.removeFunctionFromRisk(risk.id, functionId);
      onRiskFunctionRemoved(risk.id, functionId);
    },
    [onRiskFunctionRemoved, riskService],
  );

  const removeMultipleRiskFunctionsFromRisk = useCallback(
    async (functionIds: string[]) => {
      await riskService.removeFunctionsFromRisk(risk.id, functionIds);
      onRiskFunctionsRemoved(functionIds, risk.id);
    },
    [onRiskFunctionsRemoved, risk.id, riskService],
  );

  const addRiskFunctionToRisk = useCallback(
    async (risk: RiskDto, riskFunction: RiskFunctionDto) => {
      if (risk && riskFunction) {
        await riskService.addFunctionToRisk(risk.id, riskFunction.id);
        onRiskFunctionAdded(risk.id, riskFunction.id, riskFunction);
      }
    },
    [onRiskFunctionAdded, riskService],
  );

  useEffect(() => {
    fetchAllRiskFunctions();
  }, [fetchAllRiskFunctions]);

  return (
    <Box>
      <RiskFunctionsTable
        removeRiskFunctionFromRisk={removeRiskFunctionFromRisk}
        massDelete={removeMultipleRiskFunctionsFromRisk}
        addRiskFunctionToRisk={addRiskFunctionToRisk}
        riskFunctions={riskFunctions}
        totalCount={totalCount}
        allRiskFunctions={allRiskFunctions}
        openEditRiskFunction={openEditRiskFunction}
        openCreateRiskFunction={openCreateRiskFunction}
        openCreateNewVersionRiskFunction={openCreateNewVersionRiskFunction}
        ruleset={ruleset}
        risk={risk}
        rulesetsFromRisk={rulesetsFromRisk}
      ></RiskFunctionsTable>
      <CreateModal<RiskFunctionDto, CreateRiskFunctionDto, CreateFormData>
        templates={allRiskFunctions}
        title='Create Function'
        open={currentOpenModal === 'create'}
        onClose={closeModal}
        onCreate={onRiskFunctionCreate}
      />
      {modalRiskFunction && (
        <UpdateModal<RiskFunctionDto, UpdateRiskFunctionDto, UpdateFormData>
          title='Update Function'
          data={modalRiskFunction}
          open={currentOpenModal === 'update'}
          onClose={closeModal}
          onUpdate={onRiskFunctionUpdate}
        />
      )}
      {modalRiskFunction && (
        <CreateNewVersionModal<RiskFunctionDto, CreateFromExistingFunctionDto, CreateNewVersionFormData>
          title='Create new version of Function'
          open={currentOpenModal === 'createNewVersion'}
          onClose={closeModal}
          onCreateNewVersion={onRiskFunctionNewVersionCreate}
          data={modalRiskFunction}
        />
      )}
    </Box>
  );
};

export default RiskFunctionsComponent;
