import { FunctionComponent } from 'react';

import {
  ContentHeadTableProps,
  ContentTableHeader,
  TableHeadCell,
  TableOrder,
} from '../../components/content-table-head';
import { UserDto } from '../../services/dto/users/user.dto';

export type UsersOrder = TableOrder;
export type UsersOrderBy = 'username' | 'name' | 'company' | 'role' | 'status';

export function sortUsers(order: UsersOrder, orderBy: UsersOrderBy, users: UserDto[]) {
  //if (orderBy === 'nr') {
  //  return users.reverse();
  //}
  return users.sort((a, b) => {
    switch (order) {
      case 'asc':
        switch (orderBy) {
          case 'username':
            return a.username.localeCompare(b.username);
          case 'name':
            return `${a.profile.lastname}, ${a.profile.firstname}`.localeCompare(
              `${b.profile.lastname}, ${b.profile.firstname}`,
            );
          case 'role':
            return a.role.name.localeCompare(b.role.name);
          case 'status':
            return a.state.localeCompare(b.state);
          case 'company':
            return a.company.displayName.localeCompare(b.company.displayName);
        }

        break;
      case 'desc':
        switch (orderBy) {
          case 'username':
            return b.username.localeCompare(a.username);
          case 'name':
            return `${b.profile.lastname}, ${b.profile.firstname}`.localeCompare(
              `${a.profile.lastname}, ${a.profile.firstname}`,
            );
          case 'role':
            return b.role.name.localeCompare(a.role.name);
          case 'status':
            return b.state.localeCompare(a.state);
          case 'company':
            return b.company.displayName.localeCompare(a.company.displayName);
        }
        break;
    }
    return 0;
  });
}

type UsersHeadTableProps = ContentHeadTableProps<UsersOrderBy>;
const UsersTableHead: FunctionComponent<UsersHeadTableProps> = ({ order, orderBy, onSort, ...props }) => {
  const headCells: TableHeadCell<UsersOrderBy>[] = [
    {
      id: 'username',
      numeric: false,
      disablePadding: false,
      label: 'Username',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'company',
      numeric: false,
      disablePadding: false,
      label: 'Company',
    },
    {
      id: 'role',
      numeric: false,
      disablePadding: false,
      label: 'Role',
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: 'Status',
    },
    {
      numeric: false,
      disablePadding: false,
      align: 'left',
    },
  ];

  return (
    <ContentTableHeader<UsersOrderBy>
      setFilter={() => {
        //@TODO user filter
      }}
      headCells={headCells}
      order={order}
      orderBy={orderBy}
      onSort={onSort}
      numSelected={0}
      rowCount={0}
    />
  );
};

export default UsersTableHead;
