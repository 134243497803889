import { grey } from '@mui/material/colors';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useMemo } from 'react';

import ContentStatusChip from '../content-table/content-status';

interface ChildrenTableProps<Children extends Record<string, any> | undefined> {
  rows: Children[];
}

export function ChildrenTable<Children extends Record<string, any> | undefined>({
  rows,
  /*...props*/
}: ChildrenTableProps<Children>) {
  const colSpan = useMemo(() => {
    return (
      6 + (rows.some((row) => row?.riskType) ? 1 : 0) + (rows.some((row) => row?.criticality !== undefined) ? 1 : 0)
    );
  }, [rows]);

  return (
    <TableContainer component={Paper} sx={{ width: '100%', bgcolor: grey[100], px: 4 }}>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell align='left' sx={{ maxWidth: '10px' }}>
              <b>ID</b>
            </TableCell>
            <TableCell align='left' sx={{ maxWidth: '100px' }}>
              <b>Name</b>
            </TableCell>
            {rows.some((row) => row?.riskType) && (
              <TableCell align='left' sx={{ maxWidth: '10px' }}>
                <b>Risk Type</b>
              </TableCell>
            )}
            {rows.some((row) => row?.criticality !== undefined) && (
              <TableCell align='left' sx={{ maxWidth: '10px' }}>
                <b>Risk Level</b>
              </TableCell>
            )}
            <TableCell align='left' sx={{ maxWidth: '10px' }}>
              <b>Status</b>
            </TableCell>
            <TableCell align='left' sx={{ maxWidth: '10px' }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows && rows.length === 0 && (
            <TableRow key={'empty-result'}>
              <TableCell colSpan={colSpan}>No records found</TableCell>
            </TableRow>
          )}
          {rows &&
            rows.length > 0 &&
            rows.map((row) => (
              <TableRow key={row?.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component='th' scope='row' sx={{ maxWidth: '10px' }}>
                  {row?.name}
                </TableCell>
                <TableCell align='left' sx={{ maxWidth: '100px' }}>
                  {row?.displayName}
                </TableCell>
                {row?.riskType && (
                  <TableCell align='left' sx={{ maxWidth: '10px' }}>
                    {row.riskType}
                  </TableCell>
                )}
                {row?.criticality >= 0 && (
                  <TableCell align='left' sx={{ maxWidth: '10px' }}>
                    {row?.criticality}
                  </TableCell>
                )}
                {/* colspan = 3 is needed to fill out the rest of the space created by the head headCells */}
                <TableCell colSpan={3} align='left' sx={{ maxWidth: '10px' }}>
                  {row && row.status && <ContentStatusChip content={row} />}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
