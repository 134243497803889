import { FunctionComponent } from 'react';

import FunctionsDataGrid, { FunctionDefinitionColumn } from '..';

import { AbapOpRowsContext, RiskFunctionValueAbapOpTypeName } from '../..';
import { RiskFunctionDto, RiskFunctionValueDto } from '../../../../services/dto/functions/function.dto';
import { RiskFunctionOperator } from '../../../../services/models/function-operator';
import { FunctionValueTableFieldWidth } from '../../../../styles/content-tables';

export interface AbapOpTableRow {
  id: string;
  group: string;
  position: number;
  operator: RiskFunctionOperator;
  object: string;
  field: string;
  low: string;
  high?: string;
  isNew?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AbapOpFunctionsDataGridProps {
  riskFunction: RiskFunctionDto;
  saveValues: () => Promise<RiskFunctionDto | undefined>;
  filter?: {
    search: string;
    operator: 'contains' | 'notContains' | string;
  };
}

const AbapOpFunctionsDataGrid: FunctionComponent<AbapOpFunctionsDataGridProps> = ({
  riskFunction,
  saveValues,
  filter,
  ...prop
}) => {
  const columns = [
    {
      field: 'group',
      headerName: 'Group',
      width: 100,
      editable: true,
      filterable: true,
    },
    {
      field: 'position',
      headerName: 'Position',
      width: 90,
      editable: true,
      type: 'number',
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'object',
      headerName: 'Object',
      width: FunctionValueTableFieldWidth,
      editable: true,
      filterable: true,
    },
    {
      field: 'field',
      headerName: 'Field',
      width: FunctionValueTableFieldWidth,
      editable: true,
      filterable: true,
    },
    {
      field: 'low',
      headerName: 'Low',
      width: FunctionValueTableFieldWidth,
      editable: true,
      filterable: true,
    },
    {
      field: 'high',
      headerName: 'High',
      width: FunctionValueTableFieldWidth,
      editable: true,
      filterable: true,
    },
    {
      field: 'operator',
      headerName: 'Operator',
      width: 100,
      editable: true,
      type: 'singleSelect',
      valueOptions: [RiskFunctionOperator.Or, RiskFunctionOperator.And],
    },
  ] as FunctionDefinitionColumn[];

  const defaultValue: AbapOpTableRow = {
    id: '',
    group: '',
    position: 0,
    operator: RiskFunctionOperator.Or,
    object: '',
    field: '',
    low: '',
    high: '',
  };

  const riskFunctionValueToRow = (value: RiskFunctionValueDto) => {
    return {
      id: value.id,
      type: value.type.name,
      group: value.group,
      position: value.position,
      object: value.fields.object,
      field: value.fields.field,
      low: value.fields.low,
      high: value.fields.high,
      operator: value.operator,
    } as AbapOpTableRow;
  };

  return (
    <FunctionsDataGrid<AbapOpTableRow>
      RowsContext={AbapOpRowsContext}
      contentTypeName={RiskFunctionValueAbapOpTypeName}
      riskFunctionDefinitionColumns={columns}
      riskFunction={riskFunction}
      saveValues={saveValues}
      riskFunctionValueToRow={riskFunctionValueToRow}
      defaultValue={defaultValue}
      filter={filter}
    />
  );
};

export default AbapOpFunctionsDataGrid;
