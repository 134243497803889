import { Save } from '@mui/icons-material';
import {
  Box,
  Button,
  CardActions,
  CardContent,
  CardHeader,
  TextField,
  Unstable_Grid2 as Grid,
  Alert,
} from '@mui/material';
import { FunctionComponent, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { useSelectorUserData } from '../../../redux/selector';
import { setUserData } from '../../../redux/slices/user-data.slice';
import { useAuthService } from '../../../services/hooks';
import { UserProfileData } from '../models/user-profile.model';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ProfileSettingsProps {}

const ProfileSettingsComponent: FunctionComponent<ProfileSettingsProps> = ({ ...props }) => {
  const dispatch = useDispatch();
  const { userData } = useSelectorUserData();
  const authService = useAuthService();

  const defaultValues: UserProfileData = {
    email: userData?.email || '',
    firstname: userData?.profile.firstname || '',
    lastname: userData?.profile.lastname || '',
    phone: '',
  };
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<UserProfileData>({
    defaultValues,
  });
  const [submitAlert, setSubmitAlert] = useState<{
    severity: 'error' | 'success';
    message: string;
  }>();

  const onSubmit = useCallback(
    async (data: UserProfileData) => {
      setSubmitAlert(undefined);
      if (userData) {
        const response = await authService.updateUserData(userData.id, {
          email: userData.email !== data.email ? data.email || '' : undefined,
          firstname: userData.profile.firstname !== data.firstname ? data.firstname || '' : undefined,
          lastname: userData.profile.lastname !== data.lastname ? data.lastname || '' : undefined,
        });
        dispatch(
          setUserData({
            ...userData,
            email: data.email ?? userData.email,
            profile: {
              ...userData.profile,
              firstname: data.firstname ?? userData.profile.firstname,
              lastname: data.lastname ?? userData.profile.lastname,
            },
          }),
        );
        setSubmitAlert({ severity: 'success', message: 'Profile Update' });
      }
    },
    [userData, dispatch],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CardHeader title='Account Details' />
      <CardContent>
        <Box sx={{ m: -1.5 }}>
          <Grid container spacing={5}>
            <Grid xs={12} md={6}>
              <TextField fullWidth label='First Name' {...register('firstname')} />
            </Grid>
            <Grid xs={12} md={6}>
              <TextField fullWidth label='Last Name' {...register('lastname')} />
            </Grid>
            <Grid xs={12} md={6}>
              <TextField
                fullWidth
                type='email'
                label='E-Mail'
                {...register('email', { required: 'Email is required' })}
                error={!!errors.email}
                helperText={errors.email?.message}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <TextField fullWidth label='Phone Number' {...register('phone')} />
            </Grid>
          </Grid>
          <Alert sx={{ mt: 2, display: submitAlert?.severity ? 'block' : 'none' }} severity={submitAlert?.severity}>
            {submitAlert?.message}
          </Alert>
        </Box>
      </CardContent>
      <CardActions sx={{ padding: '4px', justifyContent: 'flex-end' }}>
        <Button startIcon={<Save />} type='submit' variant='contained'>
          Save
        </Button>
      </CardActions>
    </form>
  );
};

export default ProfileSettingsComponent;
