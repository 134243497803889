import { Alert, DialogContentText, List, ListItem, ListItemText, Stack } from '@mui/material';
import { ConfirmOptions } from 'material-ui-confirm';

import { RiskDto } from '../../services/dto/risks/risk.dto';
import { RiskService } from '../../services/risk.service';
import { dialogActionButtonOptions } from '../dialog-action-buttons-options';

export async function confirmDeleteRisk(
  confirm: (options?: ConfirmOptions) => Promise<void>,
  riskService: RiskService,
  risk: RiskDto,
) {
  const rulesetsFromRisk = await riskService.getRulesetsFromRisk(risk.id);
  const content = (() => {
    if (rulesetsFromRisk.data.length === 0) {
      return <DialogContentText></DialogContentText>;
    }
    return (
      <Stack spacing={2}>
        <DialogContentText>
          <Stack spacing={1}>
            <Alert severity='warning'>The Risk will be removed from all Rulesets.</Alert>
            <Alert severity='info'>Only the selected version will be deleted.</Alert>
          </Stack>
        </DialogContentText>
        <DialogContentText>
          It is currently being used in the following Rulesets:
          <List dense>
            {rulesetsFromRisk.data.map((ruleset) => (
              <ListItem key={ruleset.id}>
                <ListItemText primary={ruleset.name} />
              </ListItem>
            ))}
          </List>
        </DialogContentText>
      </Stack>
    );
  })();

  return {
    dialog: confirm({
      title: `Are you sure you want to delete ${risk.name}?`,
      content: content,
      ...dialogActionButtonOptions,
    }),
  };
}

export async function confirmDeleteRisks(confirm: (options?: ConfirmOptions) => Promise<void>, riskIds: string[]) {
  return {
    dialog: confirm({
      title: `Are you sure you want to delete ${riskIds.length} Risks?`,
      ...dialogActionButtonOptions,
    }),
  };
}
