import { Box } from '@mui/material';
import { FunctionComponent, PropsWithChildren } from 'react';

interface TabPanelProps {
  index: number;
  value: number;
}

const TabPanel: FunctionComponent<PropsWithChildren<TabPanelProps>> = ({ children, value, index, ...props }) => {
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...props}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

export default TabPanel;
