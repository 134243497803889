import { BusinessProcessDto } from '@/services/dto/business-process/business-process.dto';
import { Paper, Skeleton, Stack } from '@mui/material';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import RiskBreadcrumbs from '../../components/breadcrumbs/risk';
import { sortTableDataStatus } from '../../components/content-table-head';
import EditRiskForm from '../../components/edit-risk';
import RiskFunctionsComponent from '../../components/functions/risk-functions';
import { RiskFunctionDto } from '../../services/dto/functions/function.dto';
import { RiskDto } from '../../services/dto/risks/risk.dto';
import { RulesetDto } from '../../services/dto/rulesets/ruleset.dto';
import { useBusinessProcessService, useRiskService, useRulesetService } from '../../services/hooks';
import { ContentTypeStatus } from '../../services/models/content-type-status';
import { ContentPagePaperElementStyle, ContentPageStackSpacing } from '../../styles/pages';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RiskDetailsPageProps {}

const RiskDetailsPage: FunctionComponent<RiskDetailsPageProps> = ({ ...props }) => {
  const routeParams = useParams();
  const [ruleset, setRuleset] = useState<RulesetDto | undefined>(undefined);
  const [risk, setRisk] = useState<RiskDto | undefined>(undefined);
  const [rulesetsFromRisk, setRulesetsFromRisk] = useState<RulesetDto[] | undefined>(undefined);
  const [riskFunctions, setRiskFunctions] = useState<RiskFunctionDto[] | null>(null);
  const [riskFunctionsTotalCount, setRiskFunctionsTotalCount] = useState(0);
  const [riskVersionHistory, setRiskVersionHistory] = useState<RiskDto[]>([]);
  const [allBusinessProcesses, setAllBusinessProcesses] = useState<BusinessProcessDto[]>([]);

  const rulesetService = useRulesetService();
  const riskService = useRiskService();
  const businessProcessService = useBusinessProcessService();

  const fetchRiskVersionHistory = useCallback(
    async (riskId: string) => {
      const riskVersionHistory = await riskService.getRiskVersionHistory(riskId);
      setRiskVersionHistory(riskVersionHistory.data);
    },
    [riskService],
  );

  const fetchFunctionsByRiskId = useCallback(
    async (riskId: string) => {
      const riskFunctions = await riskService.getFunctionsFromRisk(riskId);
      setRiskFunctions(sortTableDataStatus('asc', riskFunctions.data));
      setRiskFunctionsTotalCount(riskFunctions.meta?.totalCount ?? riskFunctions.data.length);
      return riskFunctions.data;
    },
    [riskService],
  );

  const fetchRelationsByRiskId = useCallback(
    async (riskId: string) => {
      const riskResult = await riskService.getRisk(riskId);
      const rulesetsFromRisk = await riskService.getRulesetsFromRisk(riskId);
      setRisk(riskResult.data);
      setRulesetsFromRisk(rulesetsFromRisk.data);
      fetchFunctionsByRiskId(riskId);
    },
    [fetchFunctionsByRiskId, riskService],
  );

  const fetchRulesetsFromRisk = useCallback(
    async (riskId: string) => {
      const rulesetsFromRisk = await riskService.getRulesetsFromRisk(riskId);
      setRulesetsFromRisk(rulesetsFromRisk.data);
    },
    [riskService],
  );

  const fetchRuleset = useCallback(
    async (rulesetId: string) => {
      const rulesetResult = await rulesetService.getRuleset(rulesetId);
      setRuleset(rulesetResult.data);
    },
    [rulesetService],
  );

  const fetchBusinessProcesses = useCallback(async () => {
    const businessProcesses = await businessProcessService.getBusinessProcesses();
    setAllBusinessProcesses(businessProcesses.data);
  }, [riskService]);

  const onSave = useCallback(
    async (data: FieldValues) => {
      try {
        if (risk) {
          const updateRisk = {
            name: risk.name != data.name ? data.name : risk.name,
            displayName: risk.displayName != data.displayName ? data.displayName : risk.displayName,
            description: risk.description != data.description ? data.description : risk.description,
            riskType: risk.riskType != data.riskType ? data.riskType : risk.riskType,
            criticality: risk.criticality != data.criticality ? data.criticality : risk.criticality,
            riskOwner: risk.riskOwner != data.riskOwner ? data.riskOwner : risk.riskOwner,
            businessProcessId:
              risk.businessProcess?.id != data.businessProcess ? data.businessProcess ?? null : undefined,
          };
          await riskService.updateRisk(risk.id, updateRisk);
          setRisk({
            ...risk,
            name: risk.name != data.name ? data.name : risk.name,
            displayName: risk.displayName != data.displayName ? data.displayName : risk.displayName,
            description: risk.description != data.description ? data.description : risk.description,
            riskType: risk.riskType != data.riskType ? data.riskType : risk.riskType,
            criticality: risk.criticality != data.criticality ? data.criticality : risk.criticality,
            riskOwner: risk.riskOwner != data.riskOwner ? data.riskOwner : risk.riskOwner,
            businessProcess:
              risk.businessProcess?.id != data.businessProcess
                ? allBusinessProcesses.find((bp) => bp.id === data.businessProcess) ?? null
                : risk.businessProcess,
          });
          fetchRiskVersionHistory(risk.id);
        }
      } catch (e) {
        /// @TODO: error handling
        console.error(e);
      }
    },
    [fetchRiskVersionHistory, risk, riskService, allBusinessProcesses],
  );

  const onConfirmFinalize = useCallback(
    async (risk: RiskDto, data: FieldValues) => {
      try {
        const updateRisk = {
          name: risk.name != data.name ? data.name : risk.name,
          displayName: risk.displayName != data.displayName ? data.displayName : risk.displayName,
          description: risk.description != data.description ? data.description : risk.description,
          riskType: risk.riskType != data.riskType ? data.riskType : risk.riskType,
          criticality: risk.criticality != data.criticality ? data.criticality : risk.criticality,
          riskOwner: risk.riskOwner != data.riskOwner ? data.riskOwner : risk.riskOwner,
          businessProcessId:
            risk.businessProcess?.id != data.businessProcess ? data.businessProcess ?? null : undefined,
          status: ContentTypeStatus.Final,
        };
        await riskService.updateRisk(risk.id, updateRisk);
        setRisk({
          ...risk,
          name: risk.name != data.name ? data.name : risk.name,
          displayName: risk.displayName != data.displayName ? data.displayName : risk.displayName,
          description: risk.description != data.description ? data.description : risk.description,
          riskType: risk.riskType != data.riskType ? data.riskType : risk.riskType,
          criticality: risk.criticality != data.criticality ? data.criticality : risk.criticality,
          riskOwner: risk.riskOwner != data.riskOwner ? data.riskOwner : risk.riskOwner,
          businessProcess:
            risk.businessProcess?.id != data.businessProcess
              ? allBusinessProcesses.find((bp) => bp.id === data.businessProcess) ?? null
              : risk.businessProcess,
          status: ContentTypeStatus.Final,
        });
        fetchFunctionsByRiskId(risk.id);
        fetchRiskVersionHistory(risk.id);
      } catch (e) {
        /// @TODO: error handling
        console.error(e);
      }
    },
    [fetchFunctionsByRiskId, fetchRiskVersionHistory, riskService, allBusinessProcesses],
  );

  const onConfirmRelease = useCallback(
    async (risk: RiskDto, data: FieldValues) => {
      try {
        const updateRisk = {
          status: ContentTypeStatus.Released,
        };
        await riskService.updateRisk(risk.id, updateRisk);
        setRisk({
          ...risk,
          status: ContentTypeStatus.Released,
        });
        fetchFunctionsByRiskId(risk.id);
        fetchRiskVersionHistory(risk.id);
      } catch (e) {
        /// @TODO: error handling
        console.error(e);
      }
    },
    [fetchFunctionsByRiskId, fetchRiskVersionHistory, riskService],
  );

  const onRiskFunctionCreated = useCallback(async (newRiskFunction: RiskFunctionDto) => {
    setRiskFunctions((riskFunctions) => [newRiskFunction, ...(riskFunctions || [])]);
  }, []);

  const onNewRiskFunctionVersion = useCallback(async (newRiskFunction: RiskFunctionDto, origin: RiskFunctionDto) => {
    setRiskFunctions((riskFunctions) =>
      [newRiskFunction, ...(riskFunctions || [])].filter((riskFunction) => riskFunction.id != origin.id),
    );
  }, []);

  const fetchRisk = useCallback(
    async (riskId: string) => {
      const riskResult = await riskService.getRisk(riskId);
      setRisk(riskResult.data);
      fetchRiskVersionHistory(riskId);
      fetchBusinessProcesses();
    },
    [fetchRiskVersionHistory, fetchBusinessProcesses, riskService],
  );

  const onRiskFunctionUpdated = useCallback(async (id: string, updateRiskFunction: RiskFunctionDto) => {
    setRiskFunctions((riskFunctions) => {
      if (riskFunctions) {
        return riskFunctions.map((riskFunction) => {
          if (riskFunction.id === id) {
            riskFunction.name = updateRiskFunction.name ?? riskFunction.name;
            riskFunction.displayName = updateRiskFunction.displayName ?? riskFunction.displayName;
            riskFunction.description = updateRiskFunction.description ?? riskFunction.description;
            riskFunction.latestUpdateAt = updateRiskFunction.latestUpdateAt;
            riskFunction.author = updateRiskFunction.author;
          }
          return riskFunction;
        });
      }
      return null;
    });
  }, []);

  const onRiskFunctionRemoved = useCallback((riskId: string, functionId: string) => {
    setRiskFunctions((riskFunctions) => {
      if (riskFunctions) {
        return riskFunctions.filter((riskFunction) => riskFunction.id !== functionId);
      }
      return null;
    });
    setRiskFunctionsTotalCount((oldTotalCount) => oldTotalCount - 1);
  }, []);

  const onRiskFunctionsRemoved = useCallback((functionIds: string[]) => {
    setRiskFunctions((riskFunctions) => {
      if (riskFunctions) {
        return riskFunctions.filter(
          (riskFunction: RiskFunctionDto) => !functionIds.some((fId) => fId === riskFunction.id),
        );
      }
      return null;
    });
    setRiskFunctionsTotalCount((oldTotalCount) => oldTotalCount - functionIds.length);
  }, []);

  const onRiskFunctionAdded = useCallback(
    async (riskId: string, functionId: string, riskFunction: RiskFunctionDto | null) => {
      if (riskFunction) {
        setRiskFunctions((riskFunctions) => {
          if (riskFunctions) {
            return [riskFunction, ...(riskFunctions ?? [])];
          }
          return null;
        });
        setRiskFunctionsTotalCount((oldTotalCount) => oldTotalCount + 1);
      }
    },
    [],
  );

  useEffect(() => {
    if (routeParams.rulesetId) {
      fetchRuleset(routeParams.rulesetId);
    } else {
      setRuleset(undefined);
    }

    if (routeParams.riskId) {
      fetchRisk(routeParams.riskId);
      fetchRelationsByRiskId(routeParams.riskId);
      fetchRulesetsFromRisk(routeParams.riskId);
    } else {
      setRisk(undefined);
      setRiskFunctions([]);
      setRulesetsFromRisk([]);
    }
  }, [fetchRisk, routeParams]);

  return (
    <Stack spacing={ContentPageStackSpacing}>
      <Paper sx={ContentPagePaperElementStyle}>
        {risk && <RiskBreadcrumbs ruleset={ruleset} risk={risk} />}
        {!risk && <Skeleton variant='rounded' height={56} />}
      </Paper>
      <Paper sx={ContentPagePaperElementStyle}>
        {risk && (
          <EditRiskForm
            riskVersionHistory={riskVersionHistory}
            risk={risk}
            riskFunctions={riskFunctions ?? []}
            rulesets={rulesetsFromRisk}
            allBusinessProcesses={allBusinessProcesses}
            onSave={onSave}
            onConfirmFinalize={onConfirmFinalize}
            onConfirmRelease={onConfirmRelease}
          />
        )}
        {!risk && <Skeleton variant='rounded' height={272} />}
      </Paper>
      <Paper sx={ContentPagePaperElementStyle}>
        {risk && rulesetsFromRisk && (
          <RiskFunctionsComponent
            ruleset={ruleset}
            risk={risk}
            riskFunctions={riskFunctions}
            totalCount={riskFunctionsTotalCount}
            rulesetsFromRisk={rulesetsFromRisk}
            onRiskFunctionAdded={onRiskFunctionAdded}
            onRiskFunctionUpdated={onRiskFunctionUpdated}
            onRiskFunctionRemoved={onRiskFunctionRemoved}
            onRiskFunctionsRemoved={onRiskFunctionsRemoved}
            onRiskFunctionCreated={onRiskFunctionCreated}
            onRiskFunctionNewVersionCreated={onNewRiskFunctionVersion}
          />
        )}
        {(!risk || !rulesetsFromRisk) && <Skeleton variant='rounded' height={300} />}
      </Paper>
    </Stack>
  );
};

export default RiskDetailsPage;
