import { Paper, Stack } from '@mui/material';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';

import GlobalFunctionsComponent from '../../components/functions/global-functions';
import { RiskFunctionDto } from '../../services/dto/functions/function.dto';
import { useRiskFunctionService } from '../../services/hooks';
import { afterRestoreContent, afterTrashContent } from '../../shared/common';
import { ContentPagePaperElementStyle, ContentPageStackSpacing } from '../../styles/pages';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FunctionsPageProps {}

// eslint-disable-next-line no-empty-pattern
const FunctionsPage: FunctionComponent<FunctionsPageProps> = ({ ...props }) => {
  const [riskFunctions, setRiskFunctions] = useState<RiskFunctionDto[] | null>(null);
  const [totalCount, setTotalCount] = useState(0);
  const riskFunctionService = useRiskFunctionService();

  const getRiskFunction = useCallback(
    async (id: string) => {
      const result = await riskFunctionService.getFunction(id);
      return result.data;
    },
    [riskFunctionService],
  );

  const fetchRiskFunctions = useCallback(async () => {
    const riskFunctions = await riskFunctionService.getFunctions();
    setRiskFunctions(riskFunctions.data);
    setTotalCount(riskFunctions.meta?.totalCount ?? riskFunctions.data.length);
    return riskFunctions.data;
  }, [riskFunctionService]);

  const onRiskFunctionCreated = useCallback(async (newRiskFunction: RiskFunctionDto) => {
    setRiskFunctions((riskFunctions) => [newRiskFunction, ...(riskFunctions ?? [])]);
  }, []);

  const onNewRiskFunctionVersion = useCallback(async (newRiskFunction: RiskFunctionDto, origin: RiskFunctionDto) => {
    setRiskFunctions((riskFunctions) =>
      [newRiskFunction, ...(riskFunctions ?? [])].filter((riskFunction) => riskFunction.id != origin.id),
    );
  }, []);

  const onRiskFunctionUpdated = useCallback(async (id: string, updateRiskFunction: RiskFunctionDto) => {
    setRiskFunctions((riskFunctions) => {
      if (riskFunctions) {
        return riskFunctions.map((riskFunction) => {
          if (riskFunction.id === id) {
            riskFunction.name = updateRiskFunction.name ?? riskFunction.name;
            riskFunction.displayName = updateRiskFunction.displayName ?? riskFunction.displayName;
            riskFunction.description = updateRiskFunction.description ?? riskFunction.description;
            riskFunction.latestUpdateAt = updateRiskFunction.latestUpdateAt;
            riskFunction.author = updateRiskFunction.author;
          }
          return riskFunction;
        });
      }
      return null;
    });
  }, []);

  const onRiskFunctionDeleted = useCallback(
    (id: string) => {
      afterTrashContent(id, getRiskFunction, setRiskFunctions);
    },
    [getRiskFunction],
  );

  const onRiskFunctionRestored = useCallback(
    (id: string) => {
      afterRestoreContent(id, getRiskFunction, setRiskFunctions);
    },
    [getRiskFunction],
  );

  const onRiskFunctionsDeleted = useCallback(
    (riskFunctionIds: string[]) => {
      fetchRiskFunctions();
      // @NOTE(workaround): fetch all, don't know which got deleted (removed) or updated (status)
      /*
    setRiskFunctions((riskFunctions) => {
      if (riskFunctions) {
        return riskFunctions.filter(
          (riskFunction: RiskFunctionDto) => !riskFunctionIds.some((fId) => fId == riskFunction.id),
        );
      }
      return null;
    });
    */
    },
    [fetchRiskFunctions],
  );

  useEffect(() => {
    fetchRiskFunctions();
  }, [fetchRiskFunctions]);

  return (
    <Stack spacing={ContentPageStackSpacing}>
      <Paper sx={ContentPagePaperElementStyle}>
        <GlobalFunctionsComponent
          riskFunctions={riskFunctions}
          totalCount={totalCount}
          onRiskFunctionCreated={onRiskFunctionCreated}
          onRiskFunctionUpdated={onRiskFunctionUpdated}
          onRiskFunctionDeleted={onRiskFunctionDeleted}
          onRiskFunctionsDeleted={onRiskFunctionsDeleted}
          onRiskFunctionRestored={onRiskFunctionRestored}
          onRiskFunctionNewVersionCreated={onNewRiskFunctionVersion}
        />
      </Paper>
    </Stack>
  );
};

export default FunctionsPage;
