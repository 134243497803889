import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SidebarState {
  sidebarCollapsed: boolean;
}

const initialState: SidebarState = {
  sidebarCollapsed: false,
};

export const sideBarCollapseSlice = createSlice({
  name: 'sidebarCollapsed',
  initialState,
  reducers: {
    setSidebarCollapsed: (state, action: PayloadAction<boolean>) => {
      state.sidebarCollapsed = action.payload;
    },
  },
});

export const { setSidebarCollapsed } = sideBarCollapseSlice.actions;

export default sideBarCollapseSlice.reducer;
