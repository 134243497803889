import { MenuItem, TextField } from '@mui/material';
import { FunctionComponent, useCallback, useState } from 'react';
import { Control, Controller, FieldErrors, FieldValues, UseFormRegister, UseFormSetValue } from 'react-hook-form';

import { RiskFunctionDto } from '../../services/dto/functions/function.dto';
import { CreateRiskDto } from '../../services/dto/risks/create-risk.dto';
import { RiskDto } from '../../services/dto/risks/risk.dto';
import { CreateModal } from '../create-modal';

interface RiskFormData {
  name?: string;
  displayName?: string;
  description?: string;
  riskType: string;
  criticality: number;
  riskOwner: string;
}

interface CreateRiskModalProps {
  currentOpenModal: 'create' | 'update' | 'createNewVersion' | 'trash' | 'restore' | undefined;
  closeModal: () => void;
  onRiskCreate: (data: FieldValues, origin?: RiskDto, checkedChildren?: string[]) => void;
  risks: RiskDto[];
  getRiskFunctionsFromRisk: (risk: RiskDto) => Promise<RiskFunctionDto[]>;
}

const CreateRiskModal: FunctionComponent<CreateRiskModalProps> = ({
  currentOpenModal,
  closeModal,
  getRiskFunctionsFromRisk,
  risks,
  ...props
}) => {
  const [riskType, setRiskType] = useState('CA');

  const onRiskCreate = useCallback(
    async (data: FieldValues, origin: RiskDto | undefined, functionIds: string[] | undefined) => {
      //@FIXME riskType is undefined<
      props.onRiskCreate({ ...data, criticality: parseInt(data.criticality) }, origin, functionIds);
    },
    [props],
  );

  const registerOptions = {
    riskType: { required: true },
    criticality: { required: true },
    riskOwner: undefined,
  };

  const extendRiskCreateModal = {
    extendForm: (
      register: UseFormRegister<RiskFormData | Record<string, any>>,
      control: Control<RiskFormData | Record<string, any>>,
      errors: FieldErrors<RiskFormData | Record<string, any>>,
      selectedTemplate: RiskDto | null,
    ) => {
      return [
        {
          xs: 4,
          field: (
            <Controller
              name='riskType'
              control={control}
              rules={registerOptions.riskType}
              render={({ field }) => (
                <TextField
                  key={'riskType'}
                  margin='dense'
                  select
                  label='Type'
                  fullWidth
                  error={!!errors.riskType}
                  helperText={errors.riskType?.message?.toString()}
                  {...field}
                >
                  <MenuItem key={'CA'} value={'CA'}>
                    CA
                  </MenuItem>
                  <MenuItem key={'SOD'} value={'SOD'}>
                    SOD
                  </MenuItem>
                </TextField>
              )}
            />
          ),
        },
        {
          xs: 4,
          field: (
            <Controller
              name='criticality'
              control={control}
              rules={registerOptions.criticality}
              render={({ field }) => (
                <TextField
                  key={'criticality'}
                  margin='dense'
                  label='Risk Level'
                  fullWidth
                  type='number'
                  error={!!errors.criticality}
                  helperText={errors.criticality?.message?.toString()}
                  {...field}
                />
              )}
            />
          ),
        },
        {
          xs: 4,
          field: (
            <Controller
              name='riskOwner'
              control={control}
              rules={registerOptions.riskOwner}
              render={({ field }) => (
                <TextField
                  key={'riskOwner'}
                  margin='dense'
                  label='Risk Owner'
                  fullWidth
                  error={!!errors.criticality}
                  helperText={errors.riskOwner?.message?.toString()}
                  {...field}
                />
              )}
            />
          ),
        },
      ];
    },
    defaultValues: {
      name: '',
      displayName: '',
      description: '',
      riskType: 'CA',
      criticality: 0,
      riskOwner: '',
    } as RiskFormData,
    onTemplateApplied: (
      selectedTemplate: RiskDto,
      register: UseFormRegister<RiskFormData | Record<string, any>>,
      setValue: UseFormSetValue<RiskFormData | Record<string, any>>,
    ) => {
      setValue('riskType', selectedTemplate.riskType);
      setValue('criticality', selectedTemplate.criticality);
      setValue('riskOwner', selectedTemplate.riskOwner);
    },
  };

  return (
    <CreateModal<RiskDto, CreateRiskDto, RiskFormData, RiskFunctionDto>
      templates={risks}
      title='Create Risk'
      open={currentOpenModal === 'create'}
      onClose={closeModal}
      onCreate={onRiskCreate}
      extend={extendRiskCreateModal}
      loadChildren={getRiskFunctionsFromRisk}
      childrenTitle='Functions'
    />
  );
};

export default CreateRiskModal;
