import { useSelector } from 'react-redux';

import { AppState } from './slices/app-page.slice';
import { SidebarState } from './slices/sidebar-collapse.slice';
import { UserDataState } from './slices/user-data.slice';
import { RootState } from './store';

export function useSelectorUserData() {
  return useSelector<RootState, UserDataState>((state: RootState) => state.userData);
}

export function useSelectorApp() {
  return useSelector<RootState, AppState>((state: RootState) => state.appPage);
}

export function useSelectorSidebarCollapsed() {
  return useSelector<RootState, SidebarState>((state: RootState) => state.sidebarCollapsed);
}
