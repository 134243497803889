import { Alert, DialogContentText } from '@mui/material';
import { ConfirmOptions } from 'material-ui-confirm';

import { finalizeDialogOptions, releaseDialogOptions } from '../../components/dialog-action-buttons-options';
import { RiskFunctionDto } from '../../services/dto/functions/function.dto';

export function confirmFinalizeFunction(
  confirm: (options?: ConfirmOptions) => Promise<void>,
  riskFunction: RiskFunctionDto,
) {
  const canFinalize = riskFunction.values.length > 0;
  const title = canFinalize ? 'Finalize the draft?' : 'Error';
  const content = (() => {
    if (canFinalize) {
      return (
        <DialogContentText>
          <Alert severity='warning'>You wont be able to change the values of this version afterwards.</Alert>
        </DialogContentText>
      );
    }
    return (
      <DialogContentText>
        <Alert severity='error'>The Function must contain at least one Rule.</Alert>
      </DialogContentText>
    );
  })();

  return {
    canFinalize,
    dialog: confirm({
      title: title,
      content: content,
      ...finalizeDialogOptions(canFinalize),
    }),
  };
}

export function confirmReleaseFunction(
  confirm: (options?: ConfirmOptions) => Promise<void>,
  riskFunction: RiskFunctionDto,
) {
  const canRelease = riskFunction.values.length > 0;
  const title = canRelease ? 'Release Content?' : 'Error';
  const content = (() => {
    if (canRelease) {
      return (
        <DialogContentText>
          <Alert severity='warning'>You wont be able to change the values of this version afterwards.</Alert>
        </DialogContentText>
      );
    }
    return (
      <DialogContentText>
        <Alert severity='error'>The Function must contain at least one Rule.</Alert>
      </DialogContentText>
    );
  })();

  return {
    canRelease,
    dialog: confirm({
      title: title,
      content: content,
      ...releaseDialogOptions(canRelease),
    }),
  };
}
