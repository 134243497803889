import { ContentTypeStatus } from '../services/models/content-type-status';

export async function afterTrashContent<
  T extends { id: string; name: string; status: ContentTypeStatus; trashedAt?: Date },
>(
  contentId: string,
  getContent: (id: string) => Promise<T>,
  setContent: React.Dispatch<React.SetStateAction<T[] | null>>,
  closeModal?: () => void,
) {
  const newContent = await getContent(contentId);
  setContent((content) => {
    if (content) {
      if (!newContent.trashedAt) {
        // content not trashed, update content (status)
        return content.map((c) => {
          if (c.id === contentId) {
            return newContent;
          }
          return c;
        });
      } else {
        // content trashed, remove content
        return content.filter((c) => c.id != contentId);
      }
    }
    return null;
  });
  if (closeModal) {
    closeModal();
  }
}

export async function trashContent<T extends { id: string; name: string; status: ContentTypeStatus; trashedAt?: Date }>(
  contentId: string,
  trashContentServiceCall: (id: string) => Promise<any>,
  getContent: (id: string) => Promise<T>,
  setContent: React.Dispatch<React.SetStateAction<T[] | null>>,
  closeModal?: () => void,
) {
  await trashContentServiceCall(contentId);
  return afterTrashContent(contentId, getContent, setContent, closeModal);
}

export async function afterRestoreContent<
  T extends { id: string; name: string; status: ContentTypeStatus; trashedAt?: Date },
>(
  contentId: string,
  getContent: (id: string) => Promise<T>,
  setContent: React.Dispatch<React.SetStateAction<T[] | null>>,
  closeModal?: () => void,
) {
  const newContent = await getContent(contentId);
  // refetch content
  setContent((content) => {
    if (content) {
      return content.map((c) => {
        if (c.id === contentId) {
          return newContent;
        }
        return c;
      });
    }
    return null;
  });
  if (closeModal) {
    closeModal();
  }
}

export async function restoreContent<
  T extends { id: string; name: string; status: ContentTypeStatus; trashedAt?: Date },
>(
  contentId: string,
  restoreContentServiceCall: (id: string) => Promise<any>,
  getContent: (id: string) => Promise<T>,
  setContent: React.Dispatch<React.SetStateAction<T[] | null>>,
  closeModal?: () => void,
) {
  await restoreContentServiceCall(contentId);
  return afterRestoreContent(contentId, getContent, setContent, closeModal);
}
