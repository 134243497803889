import { ConfirmOptions } from 'material-ui-confirm';

import { RiskDto } from '../../services/dto/risks/risk.dto';
import { dialogActionButtonOptions } from '../dialog-action-buttons-options';

export async function confirmRemoveRiskFromRuleset(
  confirm: (options?: ConfirmOptions) => Promise<void>,
  risk: RiskDto,
) {
  return {
    dialog: confirm({
      title: `Are you sure you want to remove the Risk ${risk.name} from the Ruleset?`,
      ...dialogActionButtonOptions,
    }),
  };
}

export async function confirmRemoveRisksFromRuleset(
  confirm: (options?: ConfirmOptions) => Promise<void>,
  riskIds: string[],
) {
  return {
    dialog: confirm({
      title: `Are you sure you want to remove ${riskIds.length} Risks from the Ruleset?`,
      ...dialogActionButtonOptions,
    }),
  };
}
