import { faFileZipper, faCloudArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Stack } from '@mui/material';
import { useCallback, useMemo } from 'react';

import { RulesetDto } from '../../../services/dto/rulesets/ruleset.dto';
import { GrcExportService } from '../../../services/export/grc-export.service';
import { TableAutocompleteFieldWidth } from '../../../styles/content-tables';

interface ExportGrcControlsProps {
  selectedRulesets: RulesetDto[];
  functionContentTypeName?: string;
  downloadFile: (blob: Blob, fileName: string) => void;
  formatFilename: (name: string, suffix: string, extension: string) => string;
  setAlert: React.Dispatch<
    React.SetStateAction<{
      success?: string | undefined;
      error?: string | undefined;
    }>
  >;
}

export function ExportGrcControls({
  selectedRulesets,
  functionContentTypeName,
  downloadFile,
  formatFilename,
  setAlert,
  /*...props*/
}: ExportGrcControlsProps) {
  const grcExportService = useMemo(() => new GrcExportService(), []);

  const onBpExport = useCallback(async () => {
    if (selectedRulesets.length && functionContentTypeName) {
      const blob = await grcExportService.exportBp(
        selectedRulesets.map((ruleset) => ruleset.id),
        functionContentTypeName,
      );
      downloadFile(blob, formatFilename(selectedRulesets[0].name, 'GRC_01_BP', 'txt'));
    }
  }, [selectedRulesets, functionContentTypeName, grcExportService, downloadFile, formatFilename]);

  const onFuncExport = useCallback(async () => {
    if (selectedRulesets.length && functionContentTypeName) {
      const blob = await grcExportService.exportFunc(
        selectedRulesets.map((ruleset) => ruleset.id),
        functionContentTypeName,
      );
      downloadFile(blob, formatFilename(selectedRulesets[0].name, 'GRC_02_FUNC', 'txt'));
    }
  }, [selectedRulesets, functionContentTypeName, grcExportService, downloadFile, formatFilename]);

  const onFuncBpExport = useCallback(async () => {
    if (selectedRulesets.length && functionContentTypeName) {
      const blob = await grcExportService.exportFuncBp(
        selectedRulesets.map((ruleset) => ruleset.id),
        functionContentTypeName,
      );
      downloadFile(blob, formatFilename(selectedRulesets[0].name, 'GRC_03_FUNC_BP', 'txt'));
    }
  }, [selectedRulesets, functionContentTypeName, grcExportService, downloadFile, formatFilename]);

  const onFuncActExport = useCallback(async () => {
    if (selectedRulesets.length && functionContentTypeName) {
      const blob = await grcExportService.exportFuncAct(
        selectedRulesets.map((ruleset) => ruleset.id),
        functionContentTypeName,
      );
      downloadFile(blob, formatFilename(selectedRulesets[0].name, 'GRC_04_FUNC_ACT', 'txt'));
    }
  }, [selectedRulesets, functionContentTypeName, grcExportService, downloadFile, formatFilename]);

  const onFuncPermExport = useCallback(async () => {
    if (selectedRulesets.length && functionContentTypeName) {
      const blob = await grcExportService.exportFuncPerm(
        selectedRulesets.map((ruleset) => ruleset.id),
        functionContentTypeName,
      );
      downloadFile(blob, formatFilename(selectedRulesets[0].name, 'GRC_05_FUNC_PERM', 'txt'));
    }
  }, [selectedRulesets, functionContentTypeName, grcExportService, downloadFile, formatFilename]);

  const onRulesExport = useCallback(async () => {
    if (selectedRulesets.length && functionContentTypeName) {
      const blob = await grcExportService.exportRules(
        selectedRulesets.map((ruleset) => ruleset.id),
        functionContentTypeName,
      );
      downloadFile(blob, formatFilename(selectedRulesets[0].name, 'GRC_06_RULES', 'txt'));
    }
  }, [selectedRulesets, functionContentTypeName, grcExportService, downloadFile, formatFilename]);

  const onRisksExport = useCallback(async () => {
    if (selectedRulesets.length && functionContentTypeName) {
      const blob = await grcExportService.exportRisks(
        selectedRulesets.map((ruleset) => ruleset.id),
        functionContentTypeName,
      );
      downloadFile(blob, formatFilename(selectedRulesets[0].name, 'GRC_07_RISKS', 'txt'));
    }
  }, [selectedRulesets, functionContentTypeName, grcExportService, downloadFile, formatFilename]);

  const onRiskDescExport = useCallback(async () => {
    if (selectedRulesets.length && functionContentTypeName) {
      const blob = await grcExportService.exportRiskDesc(
        selectedRulesets.map((ruleset) => ruleset.id),
        functionContentTypeName,
      );
      downloadFile(blob, formatFilename(selectedRulesets[0].name, 'GRC_08_RISK_DESC', 'txt'));
    }
  }, [selectedRulesets, functionContentTypeName, grcExportService, downloadFile, formatFilename]);

  const onRiskRulesetExport = useCallback(async () => {
    if (selectedRulesets.length && functionContentTypeName) {
      const blob = await grcExportService.exportRiskRuleset(
        selectedRulesets.map((ruleset) => ruleset.id),
        functionContentTypeName,
      );
      downloadFile(blob, formatFilename(selectedRulesets[0].name, 'GRC_09_RISK_RULESET', 'txt'));
    }
  }, [selectedRulesets, functionContentTypeName, grcExportService, downloadFile, formatFilename]);

  const onRiskOwnExport = useCallback(async () => {
    if (selectedRulesets.length && functionContentTypeName) {
      const blob = await grcExportService.exportRiskOwn(
        selectedRulesets.map((ruleset) => ruleset.id),
        functionContentTypeName,
      );
      downloadFile(blob, formatFilename(selectedRulesets[0].name, 'GRC_10_RISK_OWN', 'txt'));
    }
  }, [selectedRulesets, functionContentTypeName, grcExportService, downloadFile, formatFilename]);

  const onGrcZipExport = useCallback(async () => {
    if (selectedRulesets.length && functionContentTypeName) {
      try {
        const blob = await grcExportService.exportZip(
          selectedRulesets.map((ruleset) => ruleset.id),
          functionContentTypeName,
        );
        downloadFile(blob, formatFilename(selectedRulesets[0].name, 'GRC', 'zip'));
      } catch (e: any) {
        setAlert({ error: 'An error has occurred.' });
        /// @TODO: show better error message
        console.error(e);
      }
    }
  }, [selectedRulesets, functionContentTypeName, grcExportService, downloadFile, formatFilename, setAlert]);

  return (
    <Stack spacing={2} sx={{ width: 2.5 * TableAutocompleteFieldWidth }}>
      <Button
        variant={'contained'}
        color='success'
        // startIcon={}
        disabled={!selectedRulesets.length}
        onClick={onGrcZipExport}
        startIcon={<FontAwesomeIcon icon={faFileZipper} />}
      >
        Export all files
      </Button>
      <Stack direction='row' spacing={2}>
        <Button
          fullWidth
          variant={'contained'}
          color='primary'
          // startIcon={}
          disabled={!selectedRulesets.length}
          onClick={onBpExport}
          startIcon={<FontAwesomeIcon icon={faCloudArrowDown} />}
        >
          Export Business Process
        </Button>
        <Button
          fullWidth
          variant={'contained'}
          color='info'
          // startIcon={}
          disabled={!selectedRulesets.length}
          onClick={onFuncExport}
          startIcon={<FontAwesomeIcon icon={faCloudArrowDown} />}
        >
          Export Function
        </Button>
      </Stack>
      <Stack direction='row' spacing={2}>
        <Button
          fullWidth
          variant={'contained'}
          color='info'
          // startIcon={}
          disabled={!selectedRulesets.length}
          onClick={onFuncBpExport}
          startIcon={<FontAwesomeIcon icon={faCloudArrowDown} />}
        >
          Export Function Business Process
        </Button>
        <Button
          fullWidth
          variant={'contained'}
          color='info'
          // startIcon={}
          disabled={!selectedRulesets.length}
          onClick={onFuncActExport}
          startIcon={<FontAwesomeIcon icon={faCloudArrowDown} />}
        >
          Export Function Actions
        </Button>
      </Stack>
      <Stack direction='row' spacing={2}>
        <Button
          fullWidth
          variant={'contained'}
          color='info'
          // startIcon={}
          disabled={!selectedRulesets.length}
          onClick={onFuncPermExport}
          startIcon={<FontAwesomeIcon icon={faCloudArrowDown} />}
        >
          Export Function Permissions
        </Button>
        <Button
          fullWidth
          variant={'contained'}
          color='info'
          // startIcon={}
          disabled={!selectedRulesets.length}
          onClick={onRulesExport}
          startIcon={<FontAwesomeIcon icon={faCloudArrowDown} />}
        >
          Export Ruleset
        </Button>
      </Stack>
      <Stack direction='row' spacing={2}>
        <Button
          fullWidth
          variant={'contained'}
          color='info'
          // startIcon={}
          disabled={!selectedRulesets.length}
          onClick={onRisksExport}
          startIcon={<FontAwesomeIcon icon={faCloudArrowDown} />}
        >
          Export Risk
        </Button>
        <Button
          fullWidth
          variant={'contained'}
          color='info'
          // startIcon={}
          disabled={!selectedRulesets.length}
          onClick={onRiskDescExport}
          startIcon={<FontAwesomeIcon icon={faCloudArrowDown} />}
        >
          Export Risk Description
        </Button>
      </Stack>
      <Stack direction='row' spacing={2}>
        <Button
          fullWidth
          variant={'contained'}
          color='info'
          // startIcon={}
          disabled={!selectedRulesets.length}
          onClick={onRiskRulesetExport}
          startIcon={<FontAwesomeIcon icon={faCloudArrowDown} />}
        >
          Export Risk Ruleset Relationship
        </Button>
        <Button
          fullWidth
          variant={'contained'}
          color='info'
          // startIcon={}
          disabled={!selectedRulesets.length}
          onClick={onRiskOwnExport}
          startIcon={<FontAwesomeIcon icon={faCloudArrowDown} />}
        >
          Export Risk Owner
        </Button>
      </Stack>
    </Stack>
  );
}
