import { Box } from '@mui/material';
import { FunctionComponent, PropsWithChildren } from 'react';

interface MountedTabPanelProps {
  index: number;
  value: number;
}

const MountedTabPanel: FunctionComponent<PropsWithChildren<MountedTabPanelProps>> = ({
  children,
  value,
  index,
  ...props
}) => {
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...props}
    >
      <Box hidden={value !== index}>{children}</Box>
    </div>
  );
};

export default MountedTabPanel;
