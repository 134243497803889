import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UserDto } from '../../services/dto/users/user.dto';

export interface UserDataState {
  userData?: UserDto;
}

const initialState: UserDataState = {
  userData: undefined,
};

export const userDataSlice = createSlice({
  name: 'userData',
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<UserDto>) => {
      state.userData = action.payload;
    },
    clearUserData: (state) => {
      state.userData = undefined;
    },
  },
});

export const { setUserData, clearUserData } = userDataSlice.actions;

export default userDataSlice.reducer;
