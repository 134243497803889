import { RiskDto } from '../../../services/dto/risks/risk.dto';
import { TableHeadCell, TableOrder, sortTableData } from '../../content-table-head';

export type RisksOrder = TableOrder;
export type RisksOrderBy = 'name' | 'displayName' | 'riskType' | 'criticality' | 'finalizedAt' | 'author' | 'status';

export function sortRisks(order: RisksOrder, orderBy: RisksOrderBy, risks: RiskDto[]) {
  return sortTableData(order, orderBy, risks, (key, a, b) => {
    if (key === 'author') {
      return a.latestEditorUsername.localeCompare(b.latestEditorUsername);
    }
    return undefined;
  });
}

export const RiskContentTableExtraCells = {
  riskType: (risk: RiskDto, key: string) => {
    return risk.riskType;
  },
  criticality: (risk: RiskDto, key: string) => {
    return risk.criticality;
  },
};
export const RiskContentTableExtraHeads: TableHeadCell<RisksOrderBy>[] = [
  {
    id: 'riskType',
    numeric: false,
    disablePadding: false,
    label: 'Type',
  },
  {
    id: 'criticality',
    numeric: false,
    disablePadding: false,
    label: 'Risk Level',
  },
];
