import { UserCompanyDto } from './dto/users/user-company.dto';
import { HttpService } from './http.service';

export class CompanyService {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(private httpService: HttpService = new HttpService('companies')) {}

  async getCompanies() {
    const response = await this.httpService.get<UserCompanyDto[]>();
    return response.data;
  }
}
