import { Paper, Stack } from '@mui/material';
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { FieldValues } from 'react-hook-form';

import { CreateFormData, CreateModal } from '../../components/create-modal';
import CreateNewVersionModal, { CreateNewVersionFormData } from '../../components/create-new-version-modal';
import RestoreModal from '../../components/restore-modal';
import TrashModal from '../../components/trash-modal';
import UpdateModal, { UpdateFormData } from '../../components/update-modal';
import { RiskDto } from '../../services/dto/risks/risk.dto';
import { CreateFromExistingRulesetDto } from '../../services/dto/rulesets/create-from-existing-ruleset.dto';
import { CreateRulesetDto } from '../../services/dto/rulesets/create-ruleset.dto';
import { RulesetDto } from '../../services/dto/rulesets/ruleset.dto';
import { UpdateRulesetDto } from '../../services/dto/rulesets/update-ruleset.dto';
import { RulesetService } from '../../services/ruleset.service';
import { restoreContent, trashContent } from '../../shared/common';
import { ContentPagePaperElementStyle, ContentPageStackSpacing } from '../../styles/pages';
import { RulesetsTable } from './rulesets-table';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RulesetsPageProps {}

const RulesetsPage: FunctionComponent<RulesetsPageProps> = ({ ...props }) => {
  const [rulesets, setRulesets] = useState<RulesetDto[] | null>(null);
  const [totalCount, setTotalCount] = useState(0);
  const [modalRuleset, setModalRuleset] = useState<RulesetDto | undefined>(undefined);
  const [currentOpenModal, setCurrentOpenModal] = useState<
    'create' | 'update' | 'createNewVersion' | 'trash' | 'restore' | undefined
  >(undefined);
  const rulesetService = useMemo(() => new RulesetService(), []);

  const fetchRulesets = useCallback(async () => {
    const rulesets = await rulesetService.getRulesets();
    setRulesets(rulesets.data);
    setTotalCount(rulesets.meta?.totalCount ?? rulesets.data.length);
    return rulesets.data;
  }, [rulesetService]);

  const openCreateRuleset = useCallback(() => {
    setCurrentOpenModal('create');
  }, []);

  const openEditRuleset = useCallback((ruleset: RulesetDto) => {
    setModalRuleset(ruleset);
    setCurrentOpenModal('update');
  }, []);

  const openCreateNewVersionRuleset = useCallback((ruleset: RulesetDto) => {
    setModalRuleset(ruleset);
    setCurrentOpenModal('createNewVersion');
  }, []);

  const openTrashRuleset = useCallback((ruleset: RulesetDto) => {
    setModalRuleset(ruleset);
    setCurrentOpenModal('trash');
  }, []);

  const openRestoreRuleset = useCallback((ruleset: RulesetDto) => {
    setModalRuleset(ruleset);
    setCurrentOpenModal('restore');
  }, []);

  const closeModal = useCallback(() => {
    setCurrentOpenModal(undefined);
    setModalRuleset(undefined);
  }, []);

  const getRuleset = useCallback(
    async (id: string) => {
      const risksResult = await rulesetService.getRuleset(id);
      return risksResult.data;
    },
    [rulesetService],
  );

  const onRulesetCreate = useCallback(
    async (data: FieldValues, origin: RulesetDto | undefined, riskIds: string[] | undefined) => {
      const res = await (() => {
        if (origin) {
          return rulesetService.createFromExistingRuleset(origin.id, {
            name: data.name,
            displayName: data.displayName,
            description: data.description,
            riskIds: riskIds,
            keepReference: data.keepReference,
          });
        }
        return rulesetService.createRuleset({
          name: data.name,
          displayName: data.displayName,
          description: data.description,
        });
      })();
      setRulesets((rulesets) => [res.data, ...(rulesets ?? [])]);
      closeModal();
    },
    [closeModal, rulesetService],
  );

  const onCreateNewRulesetVersion = useCallback(
    async (id: string, data: FieldValues, origin: RulesetDto, riskIds?: string[]) => {
      const res = await rulesetService.createNewRulesetVersion(id, {
        displayName: data.displayName,
        description: data.description,
        riskIds: riskIds,
      });
      setRulesets((rulesets) => [res.data, ...(rulesets ?? [])].filter((ruleset) => ruleset.id != origin.id));
      closeModal();
    },
    [closeModal, rulesetService],
  );

  const onRulesetUpdate = useCallback(
    async (id: string, data: FieldValues, content: RulesetDto) => {
      const res = await rulesetService.updateRuleset(id, {
        name: data.name,
        displayName: data.displayName,
        description: data.description,
      });
      setRulesets((rulesets) => {
        if (rulesets) {
          return rulesets.map((ruleset) => {
            if (ruleset.id === id) {
              /// @TODO: update latest editor
              ruleset.name = data.name ?? content.name;
              ruleset.displayName = data.displayName ?? content.displayName;
              ruleset.description = data.description ?? content.description;
              ruleset.author = res.data.author;
              ruleset.latestUpdateAt = res.data.latestUpdateAt;
              ruleset.status = res.data.status;
            }
            return ruleset;
          });
        }
        return null;
      });
      /// @TODO: better modal form reset
      closeModal();
    },
    [closeModal, rulesetService],
  );

  const trashRuleset = useCallback(
    (rulesetId: string) => {
      trashContent(rulesetId, (id) => rulesetService.trashRuleset(id), getRuleset, setRulesets, closeModal);
    },
    [closeModal, getRuleset, rulesetService],
  );

  const restoreRuleset = useCallback(
    (rulesetId: string) => {
      restoreContent(rulesetId, (id) => rulesetService.restoreRuleset(id), getRuleset, setRulesets, closeModal);
    },
    [closeModal, getRuleset, rulesetService],
  );

  const onRulesetsDeleted = useCallback(
    async (rulesetIds: string[]) => {
      await rulesetService.trashRulesets(rulesetIds);
      fetchRulesets();
      // @NOTE(workaround): fetch all, don't know which got deleted (removed) or updated (status)
      /*
      setRulesets((rulesets) => {
        if (rulesets) {
          return rulesets.filter((ruleset) => !rulesetIds.some((rId) => rId == ruleset.id));
        }
        return null;
      });
      */
    },
    [fetchRulesets, rulesetService],
  );

  const onRulesetTrash = useCallback(
    (id: string) => {
      trashRuleset(id);
    },
    [trashRuleset],
  );

  const onRulesetRestore = useCallback(
    (id: string) => {
      restoreRuleset(id);
    },
    [restoreRuleset],
  );

  const getRisksFromRuleset = useCallback(
    async (ruleset: RulesetDto) => {
      const risksResult = await rulesetService.getRisksFromRuleset(ruleset.id);
      return risksResult.data;
    },
    [rulesetService],
  );

  useEffect(() => {
    fetchRulesets();
  }, [fetchRulesets]);

  return (
    <Stack spacing={ContentPageStackSpacing}>
      <Paper sx={ContentPagePaperElementStyle}>
        <RulesetsTable
          rulesets={rulesets}
          totalCount={totalCount}
          massDelete={onRulesetsDeleted}
          openTrashRuleset={openTrashRuleset}
          openRestoreRuleset={openRestoreRuleset}
          openCreateRuleset={openCreateRuleset}
          openEditRuleset={openEditRuleset}
          openCreateNewVersionRuleset={openCreateNewVersionRuleset}
          loadChildren={getRisksFromRuleset}
        ></RulesetsTable>
      </Paper>
      <CreateModal<RulesetDto, CreateRulesetDto, CreateFormData, RiskDto>
        templates={rulesets ?? []}
        title='Create Ruleset'
        open={currentOpenModal === 'create'}
        onClose={closeModal}
        onCreate={onRulesetCreate}
        loadChildren={getRisksFromRuleset}
        childrenTitle='Risks'
      />
      {modalRuleset && (
        <UpdateModal<RulesetDto, UpdateRulesetDto, UpdateFormData>
          title='Edit Ruleset'
          open={currentOpenModal === 'update'}
          onClose={closeModal}
          onUpdate={onRulesetUpdate}
          data={modalRuleset}
        />
      )}
      {modalRuleset && (
        <CreateNewVersionModal<RulesetDto, CreateFromExistingRulesetDto, CreateNewVersionFormData, RiskDto>
          title='Create new version of Ruleset'
          open={currentOpenModal === 'createNewVersion'}
          onClose={closeModal}
          onCreateNewVersion={onCreateNewRulesetVersion}
          loadChildren={getRisksFromRuleset}
          childrenTitle='Risks'
          data={modalRuleset}
        />
      )}
      {modalRuleset && (
        <TrashModal<RulesetDto, undefined>
          title='Trash Ruleset'
          contentName='Ruleset'
          contentId={modalRuleset.id}
          open={currentOpenModal === 'trash'}
          onClose={closeModal}
          onTrash={onRulesetTrash}
          getContent={getRuleset}
        />
      )}
      {modalRuleset && (
        <RestoreModal<RulesetDto, undefined>
          title='Restore Ruleset'
          contentName='Ruleset'
          contentId={modalRuleset.id}
          open={currentOpenModal === 'restore'}
          onClose={closeModal}
          onRestore={onRulesetRestore}
          getContent={getRuleset}
        />
      )}
    </Stack>
  );
};

export default RulesetsPage;
