import { Paper, Typography } from '@mui/material';
import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface CountBoxProps {
  count: number;
  subTitle: string;
}

const CountBox = ({ count, subTitle }: CountBoxProps) => {
  return (
    <Paper elevation={3} sx={{ p: 2 }}>
      <Typography
        sx={{ flex: '1 1 100%', display: 'flex', justifyContent: 'center' }}
        variant='h3'
        id='tableTitle3'
        component='div'
      >
        {count}
      </Typography>
      <Typography
        sx={{ flex: '1 1 100%', display: 'flex', justifyContent: 'center' }}
        variant='caption'
        component='div'
      >
        {subTitle}
      </Typography>
    </Paper>
  );
};

export default CountBox;
