import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  DialogActions,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  GridSize,
} from '@mui/material';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, FieldValues, UseFormRegister, Control, FieldErrors, Controller } from 'react-hook-form';

import { ContentTypeStatus } from '../../services/models/content-type-status';
import { childrenListMaxHeight } from '../../styles/create-update-modal';
import CreateEditModalActionButtons from '../create-edit-modal-action-buttons';

export interface ContentBaseDto {
  id: string;
  name: string;
  displayName: string;
  description: string;
}
interface CreateNewVersionBaseDto {
  name?: string;
  displayName?: string;
  description?: string;
}
export interface CreateNewVersionFormData {
  name?: string;
  displayName?: string;
  description?: string;
}

interface CreateNewVersionData {
  name: string;
  displayName: string;
  description: string;
  id: string;
  status: ContentTypeStatus;
}
interface CreateNewVersionChildData {
  name: string;
  displayName: string;
  id: string;
  status: ContentTypeStatus;
}

interface CreateNewVersionModalProps<
  ContentDto extends ContentBaseDto,
  FormData extends CreateNewVersionFormData = any,
  ChildData extends CreateNewVersionChildData | undefined = undefined,
> {
  title: string;
  data: ContentDto;
  open: boolean;
  onClose: () => void;
  onCreateNewVersion: (id: string, data: FieldValues, origin: ContentDto, children?: string[]) => void;
  extend?: {
    extendForm: (
      register: UseFormRegister<FormData | Record<string, any>>,
      control: Control<FormData | Record<string, any>>,
      errors: FieldErrors<FormData | Record<string, any>>,
    ) => { xs?: boolean | GridSize; field: ReactNode }[];
    defaultValues: FormData;
  };
  loadChildren?: (data: ContentDto) => Promise<ChildData[]>;
  childrenTitle?: string;
}

export function CreateNewVersionModal<
  ContentDto extends ContentBaseDto,
  CreateNewVersionDto extends CreateNewVersionBaseDto,
  FormData extends Record<string, any> | CreateNewVersionFormData = any,
  ChildData extends CreateNewVersionChildData | undefined = undefined,
>({
  title,
  data,
  open,
  onClose,
  onCreateNewVersion,
  extend,
  loadChildren,
  childrenTitle,
  /*...props*/
}: CreateNewVersionModalProps<ContentDto, FormData, ChildData>) {
  const {
    register,
    setValue,
    handleSubmit,
    control,
    watch,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm<FormData | Record<string, any>>({
    defaultValues: {
      name: data.name,
      displayName: data.displayName,
      description: data.description,
      ...extend?.defaultValues,
    },
  });

  const [children, setChildren] = useState<ChildData[] | undefined>(undefined);
  const [checkedChildren, setCheckedChildren] = useState<string[]>([]);

  const onSubmit = useCallback(
    async (values: FieldValues) => {
      onCreateNewVersion(data.id, values, data, checkedChildren);
      onClose();
      reset();
      /// @TODO: error handling
      //clearErrors();
    },
    [onCreateNewVersion, data, checkedChildren, onClose, reset],
  );
  const toggleChildren = useCallback((child: { id: string }) => {
    setCheckedChildren((checkedChildren) => {
      const currentIndex = checkedChildren.indexOf(child.id);
      const newChecked = [...checkedChildren];

      if (currentIndex === -1) {
        newChecked.push(child.id);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      return newChecked;
    });
  }, []);

  const ExtendForm = useMemo(() => {
    return extend?.extendForm(register, control, errors).map((field, index) => {
      return (
        <Grid item xs={field.xs ?? 12} key={index}>
          {field.field}
        </Grid>
      );
    });
  }, [extend, register, control, errors]);

  const fetchChildren = useCallback(async () => {
    if (loadChildren) {
      setChildren(await loadChildren(data));
    }
  }, [data, loadChildren]);

  useEffect(() => {
    setValue('name', data.name);
    setValue('displayName', data.displayName);
    setValue('description', data.description);
    fetchChildren();
  }, [data, fetchChildren, setValue]);

  useEffect(() => {
    setCheckedChildren((children?.map((child) => child?.id).filter((v) => v) as string[] | undefined) ?? []);
  }, [children]);

  const registerOptions = {
    name: undefined,
    displayName: undefined,
    description: undefined,
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
              <Controller
                name='name'
                control={control}
                rules={registerOptions.name}
                defaultValue={data.name}
                render={({ field }) => (
                  <TextField
                    margin='dense'
                    label='ID'
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                    disabled
                    placeholder={data.name}
                    error={!!errors.name}
                    helperText={errors.name?.message?.toString()}
                    {...field}
                    onChange={(event) => {
                      field.onChange(event.target.value.toUpperCase().trim());
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name='displayName'
                control={control}
                rules={registerOptions.displayName}
                defaultValue={data.displayName}
                render={({ field }) => (
                  <TextField
                    margin='dense'
                    label='Name*'
                    fullWidth
                    placeholder={data.displayName}
                    error={!!errors.displayName}
                    helperText={errors.displayName?.message?.toString()}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name='description'
                control={control}
                rules={registerOptions.description}
                defaultValue={data.description}
                render={({ field }) => (
                  <TextField
                    multiline={true}
                    minRows={3}
                    margin='dense'
                    label='Description'
                    fullWidth
                    placeholder={data.description}
                    error={!!errors.description}
                    helperText={errors.description?.message?.toString()}
                    {...field}
                  />
                )}
              />
            </Grid>
            {ExtendForm}
            {children && (
              <Grid item xs={12}>
                <Typography variant='h6' gutterBottom>
                  {childrenTitle}
                </Typography>
                <List sx={{ width: '100%', maxHeight: childrenListMaxHeight, overflow: 'auto' }}>
                  {children.map((child) => {
                    if (child) {
                      const labelId = `checkbox-list-label-${child.id}`;
                      return (
                        <ListItem key={child.id} disablePadding>
                          <ListItemButton
                            role={undefined}
                            onClick={() => {
                              toggleChildren(child);
                            }}
                            dense
                          >
                            <ListItemIcon>
                              <Checkbox
                                edge='start'
                                checked={checkedChildren.includes(child.id)}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': labelId }}
                              />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={child.displayName || child.name} />
                          </ListItemButton>
                        </ListItem>
                      );
                    }
                    return <></>;
                  })}
                </List>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <CreateEditModalActionButtons onClose={onClose} />
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default CreateNewVersionModal;
