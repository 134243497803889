import { Avatar, Box, Typography } from '@mui/material';
import { FunctionComponent } from 'react';

import { useSelectorUserData } from '../../redux/selector';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ProfileHeaderProps {}

const ProfileHeaderComponent: FunctionComponent<ProfileHeaderProps> = ({ ...props }) => {
  const { userData } = useSelectorUserData();

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Avatar
        sx={{
          height: 80,
          mb: 2,
          width: 80,
        }}
      />
      <Typography gutterBottom variant='h5'>
        {userData?.profile.firstname} {userData?.profile.lastname}
      </Typography>
      <Typography color='text.secondary' variant='body2'>
        Member since: {userData?.activatedAt?.toLocaleString()}
      </Typography>
    </Box>
  );
};

export default ProfileHeaderComponent;
