import { TableOrder, sortTableData } from '../../components/content-table-head';
import { RulesetDto } from '../../services/dto/rulesets/ruleset.dto';

export type RulesetsOrder = TableOrder;
export type RulesetsOrderBy = 'name' | 'displayName' | 'finalizedAt' | 'author' | 'status';

export function sortRulesets(order: RulesetsOrder, orderBy: RulesetsOrderBy, rulesets: RulesetDto[]) {
  return sortTableData(order, orderBy, rulesets, (key, a, b) => {
    if (key === 'author') {
      return a.latestEditorUsername.localeCompare(b.latestEditorUsername);
    }
    return undefined;
  });
}
