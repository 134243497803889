import { faFloppyDisk, faCheck, faTruck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Stack } from '@mui/material';
import { FunctionComponent } from 'react';

import { ContentTypeStatus } from '../../services/models/content-type-status';

interface EditFormActionButtonsProps {
  onFinalize: () => void;
  onRelease: () => void;
  onSave: () => void;
  data: { status: ContentTypeStatus };
}

const EditFormActionButtons: FunctionComponent<EditFormActionButtonsProps> = ({
  onFinalize,
  onRelease,
  onSave,
  data,
  /*...props*/
}) => {
  return (
    <Stack direction={'row'} spacing={2} marginTop={2}>
      <Button
        variant='contained'
        color='info'
        startIcon={<FontAwesomeIcon icon={faFloppyDisk} />}
        type='submit'
        disabled={data.status !== ContentTypeStatus.Draft}
        onClick={onSave}
      >
        Save
      </Button>
      {data.status === ContentTypeStatus.Draft && (
        <Button
          variant='contained'
          color='success'
          startIcon={<FontAwesomeIcon icon={faCheck} />}
          disabled={data.status !== ContentTypeStatus.Draft}
          onClick={onFinalize}
        >
          Finalize
        </Button>
      )}
      {data.status === ContentTypeStatus.Final && (
        <Button
          variant='contained'
          color='primary'
          startIcon={<FontAwesomeIcon icon={faTruck} />}
          disabled={data.status !== ContentTypeStatus.Final}
          onClick={onRelease}
        >
          Release
        </Button>
      )}
    </Stack>
  );
};

export default EditFormActionButtons;
