import { createTheme } from '@mui/material';
import { ConfirmProvider } from 'material-ui-confirm';
import { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import LoadUserData from './layouts/load-user-data';
import LoginLayout from './layouts/login';
import MainLayout from './layouts/main';
import { generateRoute } from './routes';
import { getAllMainRoutes } from './routes/main-routes';

const theme = createTheme({
  palette: {
    /*
    primary: {
      main: '#CD1316',
    },
    */
    secondary: {
      main: '#59595C',
    },
    /*
    error: {
      main: '#ff4136',
    },
    */
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained', color: 'primary' },
          style: {
            backgroundColor: '#CD1316',
          },
        },
        {
          props: { variant: 'text', color: 'primary' },
          style: {
            color: '#CD1316',
          },
        },
      ],
    },
  },
});

function App() {
  const [mainRoutes, setMainRoutes] = useState(generateRoute(getAllMainRoutes()));

  return (
    <ConfirmProvider>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<MainLayout />}>
            <Route index element={<LoadUserData />} />
            {mainRoutes}
          </Route>
          <Route path='/login' element={<LoginLayout />} />
        </Routes>
      </BrowserRouter>
    </ConfirmProvider>
  );
}

export default App;
