import { MenuItem, TextField } from '@mui/material';
import { FunctionComponent, useMemo } from 'react';
import { Control, Controller, FieldErrors, FieldValues, UseFormRegister } from 'react-hook-form';

import { RiskFunctionDto } from '../../services/dto/functions/function.dto';
import { CreateFromExistingRiskDto } from '../../services/dto/risks/create-from-existing-risk.dto';
import { RiskDto } from '../../services/dto/risks/risk.dto';
import CreateNewVersionModal from '../create-new-version-modal';

interface RiskFormData {
  name?: string;
  displayName?: string;
  description?: string;
  riskType?: string;
  criticality: number;
  riskOwner: string;
}

interface CreateNewVersionRiskModalProps {
  risk: RiskDto;
  currentOpenModal: 'create' | 'update' | 'createNewVersion' | 'trash' | 'restore' | undefined;
  closeModal: () => void;
  onCreateNewRiskVersion: (id: string, data: FieldValues, content: RiskDto, riskFunctionIds?: string[]) => void;
  getRiskFunctionsFromRisk: (risk: RiskDto) => Promise<RiskFunctionDto[]>;
}

const CreateNewVersionRiskModal: FunctionComponent<CreateNewVersionRiskModalProps> = ({
  risk,
  currentOpenModal,
  closeModal,
  onCreateNewRiskVersion,
  getRiskFunctionsFromRisk,
  /*...props*/
}) => {
  const registerOptions = useMemo(() => {
    return {
      riskType: { required: true },
      criticality: { required: true },
      riskOwner: undefined,
    };
  }, []);

  const extendRiskCreateNewVersionModal = useMemo(() => {
    return {
      extendForm: (
        register: UseFormRegister<RiskFormData | Record<string, any>>,
        control: Control<RiskFormData | Record<string, any>>,
        errors: FieldErrors<RiskFormData | Record<string, any>>,
      ) => {
        return [
          {
            xs: 4,
            field: (
              <Controller
                name='riskType'
                control={control}
                rules={registerOptions.riskType}
                defaultValue={risk.riskType}
                render={({ field }) => (
                  <TextField
                    key={'riskType'}
                    margin='dense'
                    select
                    label='Type'
                    fullWidth
                    placeholder={risk.riskType}
                    error={!!errors.riskType}
                    helperText={errors.riskType?.message?.toString()}
                    {...field}
                  >
                    <MenuItem key={'CA'} value={'CA'}>
                      CA
                    </MenuItem>
                    <MenuItem key={'SOD'} value={'SOD'}>
                      SOD
                    </MenuItem>
                  </TextField>
                )}
              />
            ),
          },
          {
            xs: 4,
            field: (
              <Controller
                name='criticality'
                control={control}
                rules={registerOptions.criticality}
                defaultValue={risk.criticality}
                render={({ field }) => (
                  <TextField
                    key={'criticality'}
                    margin='dense'
                    label='Risk Level'
                    fullWidth
                    placeholder={risk.criticality.toString()}
                    type='number'
                    error={!!errors.criticality}
                    helperText={errors.criticality?.message?.toString()}
                    {...field}
                  />
                )}
              />
            ),
          },
          {
            xs: 4,
            field: (
              <Controller
                name='riskOwner'
                control={control}
                rules={registerOptions.riskOwner}
                defaultValue={risk.riskOwner}
                render={({ field }) => (
                  <TextField
                    key={'riskOwner'}
                    margin='dense'
                    label='Risk Owner'
                    fullWidth
                    error={!!errors.criticality}
                    helperText={errors.riskOwner?.message?.toString()}
                    {...field}
                  />
                )}
              />
            ),
          },
        ];
      },
      defaultValues: {
        name: risk.name,
        displayName: risk.displayName,
        description: risk.description,
        riskType: risk.riskType,
        criticality: risk.criticality,
        riskOwner: risk.riskOwner,
      } as RiskFormData,
    };
  }, [risk, registerOptions]);

  return (
    <CreateNewVersionModal<RiskDto, CreateFromExistingRiskDto, RiskFormData, RiskFunctionDto>
      title='Create new version of Risk'
      open={currentOpenModal === 'createNewVersion'}
      onClose={closeModal}
      onCreateNewVersion={onCreateNewRiskVersion}
      loadChildren={getRiskFunctionsFromRisk}
      childrenTitle='Functions'
      data={risk}
      extend={extendRiskCreateNewVersionModal}
    />
  );
};

export default CreateNewVersionRiskModal;
