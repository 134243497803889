import { useConfirm } from 'material-ui-confirm';
import { FunctionComponent, HTMLAttributes, ReactNode, useCallback } from 'react';
import { Container, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { clearUserData } from '../../../redux/slices/user-data.slice';
import { AuthService } from '../../../services/auth.service';
import { confirmLogout } from '../../logout';

import './sidebar-footer.scss';

interface SidebarFooterProps extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  rtl?: boolean;
}

const SidebarFooter: FunctionComponent<SidebarFooterProps> = ({ children, rtl /*...props*/ }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //const { userData } = useSelectorUserData();
  //const { sidebarCollapsed } = useSelector((state: RootState) => state.sidebarCollapsed);
  const confirm = useConfirm();
  const authService = new AuthService();

  const onLogout = useCallback(async () => {
    const { dialog } = await confirmLogout(confirm);
    dialog
      .then(async () => {
        await authService.logout();
        dispatch(clearUserData());
        navigate('/', { replace: true });
      })
      .catch(() => {
        /* ... */
      });
  }, [authService, confirm, dispatch, navigate]);

  return (
    <Container id='sidebar-footer-container' className='sidebar-footer position-absolute bottom-0'>
      <div className='sidebar-footer-button-container'>
        <Button variant='primary' size='sm' onClick={onLogout}>
          Logout
        </Button>
      </div>
    </Container>
  );
};

export default SidebarFooter;
