import { Alert } from '@mui/material';
import { ConfirmOptions } from 'material-ui-confirm';

import { dialogActionButtonOptions } from '../../components/dialog-action-buttons-options';

export function confirmDeleteContents(confirm: (options?: ConfirmOptions) => Promise<void>, contentIds: string[]) {
  const content = (() => {
    return <Alert severity='info'>Only the selected versions will be deleted.</Alert>;
  })();

  return {
    dialog: confirm({
      title: `Are you sure you want to DELETE ${contentIds.length} Content`,
      content: content,
      ...dialogActionButtonOptions,
    }),
  };
}
