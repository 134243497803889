import { ContentCountReportDto } from '@/services/dto/reports/content-types-count-report.dto';
import { RiskOwnersRisksReportDto } from '@/services/dto/reports/risk-owners-risks-report.dto';
import { RisksRiskLevelsReportDto } from '@/services/dto/reports/risks-risk-levels-report.dto';
import { RisksRiskTypesReportDto } from '@/services/dto/reports/risks-risk-types-report.dto';
import { RulesetsRisksReportDto } from '@/services/dto/reports/rulesets-risks-report.dto';
import { Chip, Grid, Stack, Typography } from '@mui/material';
import { Chart as ChartJS, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';

import { useSelectorUserData } from '../../redux/selector';
import { BusinessProcessesRisksReportDto } from '../../services/dto/reports/business-processes-risks-report.dto';
import { LastChangedReportDto } from '../../services/dto/reports/last-changed-content-report.dto';
import { useReportService } from '../../services/hooks';
import CountBox from './elements/CountBox';
import LastChangedList from './elements/LastChangedList';
import ProcessRiskCountChartBox from './elements/ProcessRiskCountChartBox';
import RiskLevelChartBox from './elements/RiskLevelChartBox';
import RiskOwnerRiskCountList from './elements/RiskOwnerRiskCountList';
import RiskTypeChartBox from './elements/RiskTypeChartBox';
import RulesetRiskCountChartBox from './elements/RulesetRiskCountChartBox';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DashboardProps {}

ChartJS.register(...registerables, ChartDataLabels);

const DashboardPage = (props: DashboardProps) => {
  const [businessProcessesRisksReportData, setBusinessProcessesRisksReportData] = useState<
    BusinessProcessesRisksReportDto[] | undefined
  >(undefined);
  const reportsService = useReportService();
  const { userData } = useSelectorUserData();
  const navigate = useNavigate();

  const [contentCountReportData, setContentCountReportData] = useState<ContentCountReportDto | undefined>(undefined);
  const [lastChangedReportData, setLastChangedReportData] = useState<LastChangedReportDto[] | undefined>(undefined);
  const [riskOwnersRisksReportData, setRiskOwnersRisksReportData] = useState<RiskOwnersRisksReportDto[] | undefined>(
    undefined,
  );
  const [risksRiskLevelsReportData, setRisksRiskLevelsReportData] = useState<RisksRiskLevelsReportDto | undefined>(
    undefined,
  );
  const [risksRiskTypesReportData, setRisksRiskTypesReportData] = useState<RisksRiskTypesReportDto | undefined>(
    undefined,
  );
  const [rulesetsRisksReportData, setRulesetsRisksReportData] = useState<RulesetsRisksReportDto[] | undefined>(
    undefined,
  );

  const [rulesetFilter, setRulesetFilter] = useState<{ label: string; value: string } | undefined>(undefined);
  const [businessProcessFilter, setBusinessProcessFilter] = useState<{ label: string; value: string } | undefined>(
    undefined,
  );
  const [riskTypeFilter, setRiskTypeFilter] = useState<{ label: string; value: string } | undefined>(undefined);
  const [riskLevelFilter, setRiskLevelFilter] = useState<{ label: string; value: string } | undefined>(undefined);

  const fetchChartsData = useCallback(async () => {
    const risksForBp = await reportsService.getBusinessProcessesRisksReport({
      rulesetId: rulesetFilter?.value ?? null,
      riskType: riskTypeFilter?.value ?? null,
      riskLevel: riskLevelFilter?.value ?? null,
    });
    const risksForRiskLevel = await reportsService.getRisksRiskLevelsReport({
      rulesetId: rulesetFilter?.value ?? null,
      businessProcessId: businessProcessFilter?.value ?? null,
      riskType: riskTypeFilter?.value ?? null,
    });
    const risksForRiskType = await reportsService.getRisksRiskTypesReport({
      rulesetId: rulesetFilter?.value ?? null,
      businessProcessId: businessProcessFilter?.value ?? null,
      riskLevel: riskLevelFilter?.value ?? null,
    });
    const risksForRiskOwner = await reportsService.getRiskOwnersRisksReport({
      rulesetId: rulesetFilter?.value ?? null,
      businessProcessId: businessProcessFilter?.value ?? null,
      riskType: riskTypeFilter?.value ?? null,
      riskLevel: riskLevelFilter?.value ?? null,
    });
    const risksForRulesets = await reportsService.getRulesetsRisksReport({
      businessProcessId: businessProcessFilter?.value ?? null,
      riskType: riskTypeFilter?.value ?? null,
      riskLevel: riskLevelFilter?.value ?? null,
    });
    const lastFiveChanged = await reportsService.getLastFiveChangedContentReport({
      rulesetId: rulesetFilter?.value ?? null,
      businessProcessId: businessProcessFilter?.value ?? null,
      riskType: riskTypeFilter?.value ?? null,
      riskLevel: riskLevelFilter?.value ?? null,
    });
    const contentMetrics = await reportsService.getContentTypesCountReport({
      rulesetId: rulesetFilter?.value ?? null,
      businessProcessId: businessProcessFilter?.value ?? null,
      riskType: riskTypeFilter?.value ?? null,
      riskLevel: riskLevelFilter?.value ?? null,
    });

    setBusinessProcessesRisksReportData(risksForBp.data);
    setRisksRiskLevelsReportData(risksForRiskLevel.data);
    setRisksRiskTypesReportData(risksForRiskType.data);
    setRiskOwnersRisksReportData(risksForRiskOwner.data);
    setRulesetsRisksReportData(risksForRulesets.data);
    setLastChangedReportData(lastFiveChanged.data);
    setContentCountReportData(contentMetrics.data);
  }, [
    businessProcessFilter?.value,
    reportsService,
    riskTypeFilter?.value,
    rulesetFilter?.value,
    riskLevelFilter?.value,
  ]);

  const onRulesetFilterChange = useCallback(() => {
    setRisksRiskLevelsReportData(undefined);
    setBusinessProcessesRisksReportData(undefined);
    setRisksRiskTypesReportData(undefined);
  }, []);

  const onBusinessProcessFilterChange = useCallback(() => {
    setRisksRiskLevelsReportData(undefined);
    setRulesetsRisksReportData(undefined);
    setRisksRiskTypesReportData(undefined);
  }, []);

  const onRiskTypeFilterChange = useCallback(() => {
    setRisksRiskLevelsReportData(undefined);
    setRulesetsRisksReportData(undefined);
    setBusinessProcessesRisksReportData(undefined);
  }, []);

  const onRiskLevelFilterChange = useCallback(() => {
    setRisksRiskTypesReportData(undefined);
    setRulesetsRisksReportData(undefined);
    setBusinessProcessesRisksReportData(undefined);
  }, []);

  const onRulesetFilterDelete = useCallback(() => {
    onRulesetFilterChange();
    setRulesetFilter(undefined);
  }, [onRulesetFilterChange]);

  const onBusinessProcessFilterDelete = useCallback(() => {
    onBusinessProcessFilterChange();
    setBusinessProcessFilter(undefined);
  }, [onBusinessProcessFilterChange]);

  const onRiskTypeFilterDelete = useCallback(() => {
    onRiskTypeFilterChange();
    setRiskTypeFilter(undefined);
  }, [onRiskTypeFilterChange]);

  const onRiskLevelFilterDelete = useCallback(() => {
    onRiskLevelFilterChange();
    setRiskLevelFilter(undefined);
  }, [onRiskLevelFilterChange]);

  const onRulesetFilter = useCallback(
    (rulesetId: string, rulesetDisplayName: string) => {
      if (rulesetFilter?.value !== rulesetId) {
        onRulesetFilterChange();
        setRulesetFilter({ label: rulesetDisplayName, value: rulesetId });
      }
    },
    [rulesetFilter, onRulesetFilterChange],
  );

  const onBusinessProcessesFilter = useCallback(
    (businessProcessesId: string, businessProcessDisplayName: string) => {
      if (businessProcessFilter?.value !== businessProcessesId) {
        onBusinessProcessFilterChange();
        setBusinessProcessFilter({ label: businessProcessDisplayName, value: businessProcessesId });
      }
    },
    [businessProcessFilter, onBusinessProcessFilterChange],
  );

  const onRiskTypeFilter = useCallback(
    (riskType: string) => {
      if (riskTypeFilter?.label !== riskType) {
        onRiskTypeFilterChange();
        setRiskTypeFilter({ label: riskType, value: riskType });
      }
    },
    [riskTypeFilter, onRiskTypeFilterChange],
  );

  const onRiskLevelFilter = useCallback(
    (riskLevel: string, riskLevelDisplayName: string) => {
      if (riskLevelFilter?.value !== riskLevel) {
        onRiskLevelFilterChange();
        setRiskLevelFilter({ label: riskLevelDisplayName, value: riskLevel });
      }
    },
    [riskLevelFilter, onRiskLevelFilterChange],
  );

  const filterSearchParams = useMemo(() => {
    const searchParams: URLSearchParams = new URLSearchParams();
    if (businessProcessFilter?.value) {
      searchParams.append('bpId', businessProcessFilter.value);
    }
    if (riskLevelFilter?.value) {
      searchParams.append('riskLevel', riskLevelFilter.value);
    }
    if (riskTypeFilter?.value) {
      searchParams.append('riskType', riskTypeFilter.value);
    }
    return searchParams;
  }, [businessProcessFilter?.value, riskLevelFilter?.value, riskTypeFilter?.value]);
  const onNavigateToRuleset = useCallback(
    (rulesetId: string) => {
      navigate({
        pathname: `/rulesets/${rulesetId}`,
        search: createSearchParams(filterSearchParams).toString(),
      });
    },
    [filterSearchParams, navigate],
  );
  const onNavigateWithFilters = useCallback(
    (extraParam?: { key: string; value: string }) => {
      const filterSearchParamsWithExtraParam: URLSearchParams = new URLSearchParams();
      filterSearchParams.forEach((value, key) => {
        filterSearchParamsWithExtraParam.append(key, value);
      });
      if (extraParam) {
        filterSearchParamsWithExtraParam.append(extraParam.key, extraParam.value);
      }
      if (rulesetFilter) {
        navigate({
          pathname: `/rulesets/${rulesetFilter.value}`,
          search: createSearchParams(filterSearchParamsWithExtraParam).toString(),
        });
        return;
      }

      navigate({
        pathname: `/risks`,
        search: createSearchParams(filterSearchParamsWithExtraParam).toString(),
      });
    },
    [rulesetFilter, navigate, filterSearchParams],
  );

  const clearFilter = useCallback(() => {
    setRulesetFilter(undefined);
    setBusinessProcessFilter(undefined);
    setRiskLevelFilter(undefined);
    setRiskTypeFilter(undefined);
  }, []);

  const welcomeUser = useMemo(() => {
    const firstname = userData?.profile.firstname ?? '';
    const lastname = userData?.profile.lastname ?? '';

    return `${firstname} ${lastname}`.trim();
  }, [userData]);

  useEffect(() => {
    fetchChartsData();
  }, [fetchChartsData]);

  const listBoxHeight = 250;
  const bubbleChartBoxWidth = 380;
  const chartBoxHeight = 380;

  return (
    <Stack sx={{ p: 2 }}>
      <Grid container>
        <Grid item xs={9} md={12}>
          <Typography sx={{ flex: '1 1 100%', mb: 1, mt: 2 }} variant='h5' id='welcomeTitle' component='div'>
            Welcome, {welcomeUser}
          </Typography>
          <Typography sx={{ flex: '1 1 100%', mb: 2 }} variant='subtitle2' id='welcomeSubTitle' component='div'>
            Take a look at your personalized dashboard
          </Typography>
        </Grid>

        {/* Filter */}
        <Grid item xs={9} md={12}>
          <Stack spacing={1} direction={'row'} sx={{ minHeight: 35, py: 1 }}>
            {rulesetFilter && <Chip label={`Ruleset: ${rulesetFilter.label}`} onDelete={onRulesetFilterDelete} />}
            {businessProcessFilter && (
              <Chip
                label={`Business Process: ${businessProcessFilter.label}`}
                onDelete={onBusinessProcessFilterDelete}
              />
            )}
            {riskLevelFilter && (
              <Chip label={`Risk Level: ${riskLevelFilter.label}`} onDelete={onRiskLevelFilterDelete} />
            )}
            {riskTypeFilter && <Chip label={`Risk Type: ${riskTypeFilter.label}`} onDelete={onRiskTypeFilterDelete} />}
          </Stack>
        </Grid>
      </Grid>

      {/* Counts */}
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={3} md={4}>
          <CountBox subTitle='Rulesets' count={contentCountReportData?.rulesets ?? 0} />
        </Grid>
        <Grid item xs={3} md={4}>
          <CountBox subTitle='Risks' count={contentCountReportData?.risks ?? 0} />
        </Grid>
        <Grid item xs={3} md={4}>
          <CountBox subTitle='Functions' count={contentCountReportData?.functions ?? 0} />
        </Grid>
      </Grid>

      {/* Charts */}
      <Grid container spacing={2}>
        <Grid item xs={6} md={3}>
          <RulesetRiskCountChartBox
            width={bubbleChartBoxWidth}
            height={chartBoxHeight}
            rulesetsRisksReportData={rulesetsRisksReportData}
            setRulesetFilter={onRulesetFilter}
            onDoubleClick={onNavigateToRuleset}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <ProcessRiskCountChartBox
            height={chartBoxHeight}
            businessProcessesRisksReportData={businessProcessesRisksReportData}
            setBusinessProcessesIdFilter={onBusinessProcessesFilter}
            onDoubleClick={onNavigateWithFilters}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <RiskLevelChartBox
            height={chartBoxHeight}
            risksRiskLevelsReportData={risksRiskLevelsReportData}
            setRiskLevelFilter={onRiskLevelFilter}
            onDoubleClick={onNavigateWithFilters}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <RiskTypeChartBox
            height={chartBoxHeight}
            risksRiskTypesReportData={risksRiskTypesReportData}
            setRiskTypeFilter={onRiskTypeFilter}
            onDoubleClick={onNavigateWithFilters}
          />
        </Grid>

        <Grid item xs={6} md={3}></Grid>
        <Grid item xs={6} md={3}>
          <RiskOwnerRiskCountList height={listBoxHeight} riskOwnersRisksReportData={riskOwnersRisksReportData} />
        </Grid>
        <Grid item xs={6} md={3}>
          <LastChangedList height={listBoxHeight} changed={5} lastChangedReportData={lastChangedReportData} />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default DashboardPage;
