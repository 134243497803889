import axios from 'axios';
import { ResponseType } from 'axios';

import { UserAccessTokenDto } from './dto/auth/access-token.dto';
import { JsonApiResult } from './dto/jsonapi-result.dto';

export class HttpService {
  readonly baseURL: string;
  readonly apiKey: string;

  constructor(private resource: string) {
    this.baseURL = `${process.env.REACT_APP_BACKEND_API_BASE_URL}/${this.resource}`;
    this.apiKey = process.env.REACT_APP_BACKEND_API_KEY ?? '';
  }

  post<T>(endpoint: string, body: any) {
    const url = endpoint ? `${this.baseURL}/${endpoint}` : this.baseURL;
    return axios.post<JsonApiResult<T>>(url, body, {
      headers: this.authHeader(),
    });
  }

  postAny<T>(endpoint: string, body: any, responseType?: ResponseType) {
    const url = endpoint ? `${this.baseURL}/${endpoint}` : this.baseURL;
    return axios.post<T>(url, body, {
      headers: this.authHeader(),
      responseType: responseType,
    });
  }

  postNoBody<T>(endpoint: string) {
    const url = endpoint ? `${this.baseURL}/${endpoint}` : this.baseURL;
    return axios.post<JsonApiResult<T>>(url, undefined, {
      headers: this.authHeader(),
    });
  }

  postNoJsonApiData<T>(endpoint: string, body: any) {
    const url = endpoint ? `${this.baseURL}/${endpoint}` : this.baseURL;
    return axios.post<T>(url, body, {
      headers: this.authHeader(),
    });
  }

  postNoData(endpoint: string) {
    const url = endpoint ? `${this.baseURL}/${endpoint}` : this.baseURL;
    return axios.post(url, undefined, {
      headers: this.authHeader(),
    });
  }

  postCreated(endpoint: string, body: any) {
    const url = endpoint ? `${this.baseURL}/${endpoint}` : this.baseURL;
    return axios.post(url, body, {
      headers: this.authHeader(),
    });
  }

  patch<T>(endpoint: string, body: any) {
    const url = endpoint ? `${this.baseURL}/${endpoint}` : this.baseURL;
    return axios.patch<JsonApiResult<T>>(url, body, {
      headers: this.authHeader(),
    });
  }

  patchNoContent(endpoint: string, body: any) {
    const url = endpoint ? `${this.baseURL}/${endpoint}` : this.baseURL;
    return axios.patch(url, body, {
      headers: this.authHeader(),
    });
  }

  get<T>(endpoint?: string, params?: any) {
    const url = endpoint ? `${this.baseURL}/${endpoint}` : this.baseURL;
    return axios.get<JsonApiResult<T>>(url, {
      headers: this.authHeader(),
      params: params,
    });
  }

  getAny<T>(endpoint?: string, responseType?: ResponseType) {
    const url = endpoint ? `${this.baseURL}/${endpoint}` : this.baseURL;
    return axios.get<T>(url, {
      headers: this.authHeader(),
      responseType: responseType,
    });
  }

  delete(endpoint?: string) {
    const url = endpoint ? `${this.baseURL}/${endpoint}` : this.baseURL;
    return axios.delete(url, {
      headers: this.authHeader(),
    });
  }

  deleteWithBody<T>(body: T, endpoint?: string) {
    const url = endpoint ? `${this.baseURL}/${endpoint}` : this.baseURL;
    return axios.delete(url, {
      headers: this.authHeader(),
      data: body,
    });
  }

  authHeader() {
    const access_token = this.getAccessToken();

    if (access_token) {
      return {
        Authorization: `Bearer ${access_token}`,
        'X-API-Key': this.apiKey,
      };
    } else {
      return {
        'X-API-Key': this.apiKey,
      };
    }
  }

  hasValidAccessToken(): boolean {
    return (
      !!this.getCurrentUserAccessToken().access_token &&
      Date.now() < new Date(this.getCurrentUserAccessToken().expired_at).getTime()
    );
  }

  getCurrentUserAccessToken(): UserAccessTokenDto {
    return JSON.parse(localStorage.getItem('user') ?? '{}');
  }

  private getAccessToken() {
    return this.getCurrentUserAccessToken().access_token;
  }
}
