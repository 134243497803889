import { ConfirmOptions } from 'material-ui-confirm';

import { RiskFunctionDto } from '../../services/dto/functions/function.dto';
import { dialogActionButtonOptions } from '../dialog-action-buttons-options';

export async function confirmRemoveRiskFunctionFromRisk(
  confirm: (options?: ConfirmOptions) => Promise<void>,
  riskFunction: RiskFunctionDto,
) {
  return {
    dialog: confirm({
      title: `Are you sure you want to remove the Function ${riskFunction.name} from the Risk?`,
      ...dialogActionButtonOptions,
    }),
  };
}

export async function confirmRemoveRiskFunctionsFromRisk(
  confirm: (options?: ConfirmOptions) => Promise<void>,
  riskFunctionIds: string[],
) {
  return {
    dialog: confirm({
      title: `Are you sure you want to remove ${riskFunctionIds.length} Functions from the Risk?`,
      ...dialogActionButtonOptions,
    }),
  };
}
