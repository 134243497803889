import { configureStore } from '@reduxjs/toolkit';

import appPageSlice from './slices/app-page.slice';
import sidebarCollapseSlice from './slices/sidebar-collapse.slice';
import userDataSlice from './slices/user-data.slice';

export const store = configureStore({
  reducer: {
    appPage: appPageSlice,
    sidebarCollapsed: sidebarCollapseSlice,
    userData: userDataSlice,
  } as any,
});

export type RootState = ReturnType<typeof store.getState>;
