import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  Button,
  Alert,
  DialogContentText,
} from '@mui/material';
import { PropsWithChildren, ReactNode, useCallback, useEffect, useState } from 'react';

import { ContentTypeStatus } from '../../services/models/content-type-status';

interface ContentBaseDto {
  id: string;
  name: string;
  displayName: string;
  description: string;
  canTrash?: boolean;
  canDelete?: boolean;
  status: ContentTypeStatus;
}

interface RestoreModalProps<
  ContentDto extends ContentBaseDto,
  ParentDto extends ContentBaseDto | undefined = undefined,
> {
  title: string;
  contentName: string;
  parentsName?: string;
  open: boolean;
  contentId: string;
  onClose: () => void;
  onRestore: (id: string, content: ContentDto) => void;
  getContent: (id: string) => Promise<ContentDto | undefined>;
  renderMoreInfo?: (content: ContentDto, parents?: ParentDto[]) => ReactNode;
}

export function RestoreModal<
  ContentDto extends ContentBaseDto,
  ParentDto extends ContentBaseDto | undefined = undefined,
>({
  title,
  contentName,
  parentsName,
  open,
  contentId,
  onClose,
  onRestore,
  getContent,
  renderMoreInfo,
  children /*...props*/,
}: PropsWithChildren<RestoreModalProps<ContentDto, ParentDto>>) {
  const [content, setContent] = useState<ContentDto | undefined>(undefined);

  const fetchContent = useCallback(async () => {
    const contentResult = await getContent(contentId);
    setContent(contentResult);
  }, [contentId, getContent]);

  const onSubmit = useCallback(() => {
    if (content) {
      onRestore(contentId, content);
    }
  }, [content, onRestore, contentId]);

  useEffect(() => {
    fetchContent();
  }, [fetchContent]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          {content && contentName && (
            <DialogContentText>
              <Stack spacing={1}>
                <Alert severity='info'>All Versions will get restored and set to the old status.</Alert>
              </Stack>
            </DialogContentText>
          )}
          {children}
          {content && renderMoreInfo && renderMoreInfo(content)}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction={'row'} spacing={1} marginX={2} marginBottom={2}>
          <Button
            type='button'
            onClick={() => {
              onClose();
            }}
            variant='contained'
            color='error'
          >
            Cancel
          </Button>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            onClick={() => {
              onSubmit();
            }}
            disabled={!content}
          >
            Restore
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

export default RestoreModal;
