import { BusinessProcessDto } from '@/services/dto/business-process/business-process.dto';
import { useConfirm } from 'material-ui-confirm';
import { FunctionComponent, useCallback } from 'react';
import { FieldValues } from 'react-hook-form';

import ContentHeader from '../../components/content-header';
import { BaseContentType } from '../../components/content-table';
import { RiskFunctionDto } from '../../services/dto/functions/function.dto';
import { RiskDto } from '../../services/dto/risks/risk.dto';
import { ContentTypeStatus } from '../../services/models/content-type-status';
import { confirmFinalizeFunction, confirmReleaseFunction } from './dialogs';

export interface EditFunctionFormData {
  name: string;
  displayName: string;
  description: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FunctionHeaderProps {
  riskFunction: RiskFunctionDto;
  riskFunctionVersionHistory: RiskFunctionDto[];
  risksFromRiskFunction?: RiskDto[];
  allBusinessProcesses: BusinessProcessDto[];
  onSave: (data: FieldValues) => void;
  onConfirmFinalize: (riskFunction: RiskFunctionDto, data: FieldValues) => void;
  onConfirmRelease: (riskFunction: RiskFunctionDto, data: FieldValues) => void;
}

const FunctionHeader: FunctionComponent<FunctionHeaderProps> = ({
  riskFunction,
  riskFunctionVersionHistory,
  risksFromRiskFunction,
  allBusinessProcesses,
  onSave,
  onConfirmFinalize,
  onConfirmRelease,
  /*...props*/
}) => {
  const confirm = useConfirm();

  const onFinalize = useCallback(
    (data: FieldValues) => {
      const { canFinalize, dialog } = confirmFinalizeFunction(confirm, riskFunction);
      dialog
        .then(() => {
          if (canFinalize) {
            onConfirmFinalize(riskFunction, {
              ...data,
              status: ContentTypeStatus.Final,
            });
          }
        })
        .catch(() => {
          /* ... */
        });
    },
    [confirm, onConfirmFinalize, riskFunction],
  );
  const onRelease = useCallback(
    (data: FieldValues) => {
      const { canRelease, dialog } = confirmReleaseFunction(confirm, riskFunction);
      dialog
        .then(() => {
          if (canRelease) {
            onConfirmRelease(riskFunction, {
              status: ContentTypeStatus.Released,
            });
          }
        })
        .catch(() => {
          /* ... */
        });
    },
    [confirm, onConfirmRelease, riskFunction],
  );

  const getFunctionDetailsUrl = (riskFunction: BaseContentType) => {
    return `/functions/${riskFunction.id}`;
  };

  const getRiskDetailsUrl = (risk: BaseContentType) => {
    return `/risks/${risk.id}`;
  };

  return (
    <ContentHeader<RiskFunctionDto, RiskDto, EditFunctionFormData>
      title='Function Header'
      content={riskFunction}
      versionHistory={riskFunctionVersionHistory}
      occurrences={risksFromRiskFunction}
      allBusinessProcesses={allBusinessProcesses}
      onSave={onSave}
      onFinalize={onFinalize}
      onRelease={onRelease}
      getVersionHistoryDetailsUrl={getFunctionDetailsUrl}
      getOccurrenceDetailsUrl={getRiskDetailsUrl}
    />
  );
};

export default FunctionHeader;
