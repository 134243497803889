import { RisksRiskTypesReportDto } from '@/services/dto/reports/risks-risk-types-report.dto';
import { faFileCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, CircularProgress, Paper, Stack, Typography } from '@mui/material';
import { Chart as ChartJS } from 'chart.js';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Pie, getElementAtEvent } from 'react-chartjs-2';

import { RiskType } from '../../../services/models/risk-type';
import { DASHBOARD_DOUBLE_CLICK_DEPLAY } from './constants';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RiskTypeChartBoxProps {
  risksRiskTypesReportData?: RisksRiskTypesReportDto;
  height?: string | number;
  setRiskTypeFilter: (riskType: RiskType) => void;
  onDoubleClick: (extraParam?: { key: string; value: string }) => void;
}

const RiskTypeChartBox = ({
  risksRiskTypesReportData,
  height,
  setRiskTypeFilter,
  onDoubleClick,
}: RiskTypeChartBoxProps) => {
  const risksForRiskTypeChartLabels = useMemo(() => ['SOD', 'CA'], []);
  const risksForRiskTypeChartDatasets = useMemo(
    () => [
      {
        label: 'Risks',
        data: [
          risksRiskTypesReportData?.riskTypes[RiskType.SegregationOfDuty],
          risksRiskTypesReportData?.riskTypes[RiskType.CriticalAuthorization],
        ],
        backgroundColor: ['#003f5c', '#665191'],
        borderColor: ['rgba(0, 0, 0, 0)'],
        borderWidth: 1,
        datalabels: {
          labels: {
            value: {
              align: 'center',
              borderWidth: 2,
              borderRadius: 4,
              color: (ctx: any) => {
                const value = ctx.dataset.data[ctx.dataIndex];
                return value > 0 ? 'white' : 'rgba(0, 0, 0, 0)';
              },
              formatter: (value: any) => {
                return value;
              },
              padding: 4,
            },
          },
        },
      },
    ],
    [risksRiskTypesReportData?.riskTypes],
  );
  const risksForRiskTypeChartData = useMemo(() => {
    return {
      labels: risksForRiskTypeChartLabels,
      datasets: risksForRiskTypeChartDatasets,
    };
  }, [risksForRiskTypeChartDatasets, risksForRiskTypeChartLabels]);

  const risksRiskTypeCountChartRef = useRef<ChartJS | any>();
  const risksRiskTypeChartSingleClick = useCallback(
    (event: React.MouseEventHandler<HTMLCanvasElement> | any) => {
      /// @FIXME: onClick event type vs. getElementAtEvent type (use any)
      const elementClicked = risksRiskTypeCountChartRef.current
        ? getElementAtEvent(risksRiskTypeCountChartRef.current, event)
        : undefined;
      if (elementClicked && elementClicked.length > 0) {
        const index = elementClicked[0].index;
        const datasetIndex = elementClicked[0].datasetIndex;

        if (datasetIndex === 0) {
          switch (index) {
            case 0:
              setRiskTypeFilter(RiskType.SegregationOfDuty);
              break;
            case 1:
              setRiskTypeFilter(RiskType.CriticalAuthorization);
              break;
          }
        }
      }
    },
    [setRiskTypeFilter],
  );
  const risksRiskTypeChartDoubleClick = useCallback(
    (event: React.MouseEventHandler<HTMLCanvasElement> | any) => {
      /// @FIXME: onClick event type vs. getElementAtEvent type (use any)
      const elementClicked = risksRiskTypeCountChartRef.current
        ? getElementAtEvent(risksRiskTypeCountChartRef.current, event)
        : undefined;
      if (elementClicked && elementClicked.length > 0) {
        const index = elementClicked[0].index;
        const datasetIndex = elementClicked[0].datasetIndex;

        if (datasetIndex === 0) {
          switch (index) {
            case 0:
              onDoubleClick({ key: 'riskType', value: RiskType.SegregationOfDuty });
              break;
            case 1:
              onDoubleClick({ key: 'riskType', value: RiskType.CriticalAuthorization });
              break;
          }
        }
      }
    },
    [onDoubleClick],
  );

  const [clickCounter, setClickCounter] = useState<{
    event: React.MouseEventHandler<HTMLCanvasElement> | any | undefined;
    counter: number;
  }>({ event: undefined, counter: 0 });
  const risksRiskTypeChartOnClick = useCallback((event: React.MouseEventHandler<HTMLCanvasElement> | any) => {
    setClickCounter((c) => {
      // inc. click counter and pass event
      return { event, counter: c.counter + 1 };
    });
  }, []);

  useEffect(() => {
    // has click event
    if (clickCounter.event && clickCounter.counter > 0) {
      const timer = setTimeout(() => {
        if (clickCounter.counter === 1) risksRiskTypeChartSingleClick(clickCounter.event);
        else if (clickCounter.counter === 2) risksRiskTypeChartDoubleClick(clickCounter.event);
        setClickCounter({ event: undefined, counter: 0 });
      }, DASHBOARD_DOUBLE_CLICK_DEPLAY);

      return () => {
        clearTimeout(timer);
      };
    }
  });

  const options = useMemo(() => {
    return {
      responsive: true,
      plugins: {
        legend: {
          display: true,
          onClick: (mouseEvent: any, legendItem: any) => {
            switch (legendItem.index) {
              case 0:
                setRiskTypeFilter(RiskType.SegregationOfDuty);
                break;
              case 1:
                setRiskTypeFilter(RiskType.CriticalAuthorization);
                break;
            }
          },
        },
      },
    };
  }, [setRiskTypeFilter]);

  const loadingState = useMemo(() => {
    if (risksRiskTypesReportData === undefined) {
      return 'loading';
    }

    const riskTypeCount = risksRiskTypesReportData.riskTypes.CA + risksRiskTypesReportData.riskTypes.SOD;
    if (riskTypeCount > 0) {
      return 'loaded';
    }
    return 'empty';
  }, [risksRiskTypesReportData]);

  return (
    <Paper elevation={3} sx={{ p: 2 }}>
      <Typography sx={{ flex: '1 1 100%', mb: 2 }} variant='body1' id='tableTitle3' component='div'>
        Risk Type
      </Typography>
      <Box sx={{ height: height, justifyContent: 'center', display: 'flex' }}>
        {loadingState === 'loading' && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {loadingState === 'empty' && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Stack spacing={1}>
              <Typography align='center'>No Data</Typography>
              <FontAwesomeIcon size='5x' icon={faFileCircleXmark} />
            </Stack>
          </Box>
        )}
        {risksRiskTypesReportData && loadingState === 'loaded' && (
          <Pie
            data={risksForRiskTypeChartData as any}
            options={options as any} ///< @NOTE: use any for plugin options
            ref={risksRiskTypeCountChartRef}
            onClick={risksRiskTypeChartOnClick}
          />
        )}
      </Box>
    </Paper>
  );
};

export default RiskTypeChartBox;
