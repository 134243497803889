import { Button, Stack } from '@mui/material';
import { FunctionComponent } from 'react';

interface EditFormActionButtonsProps {
  onClose: () => void;
}

const CreateEditModalActionButtons: FunctionComponent<EditFormActionButtonsProps> = ({ onClose /*...props*/ }) => {
  return (
    <Stack direction={'row'} spacing={1} marginX={2} marginBottom={2}>
      <Button
        type='button'
        onClick={() => {
          onClose();
        }}
        variant='contained'
        color='error'
      >
        Cancel
      </Button>
      <Button type='submit' variant='contained' color='success'>
        Save
      </Button>
    </Stack>
  );
};

export default CreateEditModalActionButtons;
