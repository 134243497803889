import { Stack } from '@mui/material';
import { FunctionComponent, useCallback, useState, PropsWithChildren, useMemo } from 'react';
import { Alert, Button, Form } from 'react-bootstrap';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import WhiteLogo from '../../assets/img/white-logo.svg';
import { AuthService } from '../../services/auth.service';

import './index.scss';
interface LoginInputs {
  username: string;
  password: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface LoginLayoutProps {}

const LoginLayout: FunctionComponent<PropsWithChildren<LoginLayoutProps>> = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState(<></>);
  const authService = useMemo(() => new AuthService(), []);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<LoginInputs>();

  const onSubmit: SubmitHandler<LoginInputs> = useCallback(
    (data: LoginInputs) => {
      authService
        .login(data.username, data.password)
        .then(() => {
          setError(<></>);
          navigate('/', { replace: true });
        })
        .catch((err) => {
          /// @TODO: better error handling and messages
          if (err.response?.status === 400 || err.response?.status === 401) {
            setError(<Alert variant='error'>Wrong username or password</Alert>);
          }
        });
    },
    [authService, navigate],
  );

  ///@TODO: move login into component
  return (
    <Stack className='login-form-container'>
      <img src={WhiteLogo} />

      <Form className='login-form' onSubmit={handleSubmit(onSubmit)}>
        <div className='login-form-content'>
          <h2 className='login-form-title'>Login</h2>
          <span className='login-form-text'>
            Welcome to the Xiting Content Platform. Please login with your credentials, or{' '}
            <Link to='/'>request access now</Link>
          </span>
          <Form.Group className='mt-3' controlId='formLoginEmail'>
            <Form.Control className='mt-1' placeholder='Enter email' {...register('username', { required: true })} />
          </Form.Group>
          <Form.Group className='mt-3' controlId='formLoginPassword'>
            <Form.Control
              type='password'
              className='mt-1'
              placeholder='Enter Password'
              {...register('password', { required: true })}
            />
          </Form.Group>
          <div className='d-grid gap-2 mt-3'>
            <Button type='submit' variant='primary'>
              Submit
            </Button>
            {error}
          </div>
          <p className='forgot-password text-right mt-2'>
            <Link to='/'>Forgot your password?</Link>
          </p>
        </div>
      </Form>
    </Stack>
  );
};
export default LoginLayout;
