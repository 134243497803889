import { ConfirmOptions } from 'material-ui-confirm';

export async function confirmLogout(confirm: (options?: ConfirmOptions) => Promise<void>) {
  return {
    dialog: confirm({
      title: `Logout`,
      description: `Are you sure you want to Logout?`,
      confirmationText: 'Logout',
      cancellationText: 'Cancel',
      confirmationButtonProps: { variant: 'contained', color: 'primary' },
      //cancellationButtonProps: { variant: 'contained', color: 'error' },
    }),
  };
}

/// @FIXME: pass dispatch and navigate as parameter
/*
export async function openLogoutModal(confirm: (options?: ConfirmOptions, 
    dispatch: Dispatch<AnyAction>,
    navigate: NavigateFunction,
    authService: AuthService) => Promise<void>){
    const { dialog } = await confirmLogout(confirm);
    dialog.then(async () => {
        await authService.logout().catch((err) => console.log(err));
        dispatch(clearUserData());
        navigate('/', { replace: true });
    })
    .catch(() => {
        //
    });
}
*/
