import { Paper, Stack } from '@mui/material';
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';

import DeleteModal from '../../components/delete-modal';
import RestoreModal from '../../components/restore-modal';
import { RiskFunctionDto } from '../../services/dto/functions/function.dto';
import { RiskDto } from '../../services/dto/risks/risk.dto';
import { RulesetDto } from '../../services/dto/rulesets/ruleset.dto';
import { useRiskFunctionService, useRiskService, useRulesetService } from '../../services/hooks';
import { ContentPageStackSpacing, ContentPagePaperElementStyle } from '../../styles/pages';
import { TrashTable } from './trash-table';
import { TrashContentDto } from './trash-table-head';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface TrashPageProps {}

const TrashPage: FunctionComponent<TrashPageProps> = ({ ...props }) => {
  const [rulesets, setRulesets] = useState<RulesetDto[] | null>(null);
  const [rulesetsTotalCount, setRulesetsTotalCount] = useState(0);
  const [risks, setRisks] = useState<RiskDto[] | null>(null);
  const [risksTotalCount, setRisksTotalCount] = useState(0);
  const [riskFunctions, setRiskFunctions] = useState<RiskFunctionDto[] | null>(null);
  const [riskFunctionsTotalCount, setRiskFunctionsTotalCount] = useState(0);
  const [currentOpenModal, setCurrentOpenModal] = useState<'delete' | 'restore' | undefined>(undefined);

  /// @TODO: better down cast
  const [modalContent, setModalContent] = useState<
    any | TrashContentDto | RulesetDto | RiskDto | RiskFunctionDto | undefined
  >(undefined);
  const modalRuleset = useMemo<RulesetDto | undefined>(() => {
    if (modalContent && modalContent.type === 'Ruleset') {
      /// @TODO: better up cast
      return modalContent as RulesetDto;
    }
    return undefined;
  }, [modalContent]);
  const modalRisk = useMemo<RiskDto | undefined>(() => {
    if (modalContent && modalContent.type === 'Risk') {
      /// @TODO: better up cast
      return modalContent as RiskDto;
    }
    return undefined;
  }, [modalContent]);
  const modalRiskFunction = useMemo<RiskFunctionDto | undefined>(() => {
    if (modalContent && modalContent.type === 'Function') {
      /// @TODO: better up cast
      return modalContent as RiskFunctionDto;
    }
    return undefined;
  }, [modalContent]);

  const rulesetService = useRulesetService();
  const riskService = useRiskService();
  const riskFunctionService = useRiskFunctionService();

  const totalCount = useMemo(
    () => rulesetsTotalCount + risksTotalCount + riskFunctionsTotalCount,
    [riskFunctionsTotalCount, risksTotalCount, rulesetsTotalCount],
  );

  /// @TODO: better down cast
  const trashContent: (any | TrashContentDto | RulesetDto | RiskDto | RiskFunctionDto)[] | null = useMemo(() => {
    if (rulesets && risks && riskFunctions) {
      return [
        ...rulesets.map((ruleset) => {
          return {
            ...ruleset,
            type: 'Ruleset',
          };
        }),
        ...risks.map((risk) => {
          return {
            ...risk,
            type: 'Risk',
          };
        }),
        ...riskFunctions.map((riskFunction) => {
          return {
            ...riskFunction,
            type: 'Function',
          };
        }),
      ];
    }

    return null;
  }, [riskFunctions, risks, rulesets]);

  const fetchRulesets = useCallback(async () => {
    const rulesets = await rulesetService.getRulesetsTrashed();
    setRulesets(rulesets.data);
    setRulesetsTotalCount(rulesets.meta?.totalCount ?? rulesets.data.length);
    return rulesets.data;
  }, [rulesetService]);

  const fetchRisks = useCallback(async () => {
    const risks = await riskService.getRisksTrashed();
    setRisks(risks.data);
    setRisksTotalCount(risks.meta?.totalCount ?? risks.data.length);
    return risks.data;
  }, [riskService]);

  const fetchRiskFunctions = useCallback(async () => {
    const riskFunctions = await riskFunctionService.getFunctionsTrashed();
    setRiskFunctions(riskFunctions.data);
    setRiskFunctionsTotalCount(riskFunctions.meta?.totalCount ?? riskFunctions.data.length);
    return riskFunctions.data;
  }, [riskFunctionService]);

  const closeModal = useCallback(() => {
    setCurrentOpenModal(undefined);
    setModalContent(undefined);
  }, []);

  const deleteContent = useCallback(
    async (id: string, type: 'Ruleset' | 'Risk' | 'Function') => {
      const content = trashContent?.find((c) => c.id === id);
      if (content) {
        switch (type) {
          case 'Ruleset':
            await rulesetService.deleteRuleset(content.id).then((res) => {
              setRulesets((rulesets) => (rulesets ? rulesets.filter((ruleset) => ruleset.id !== id) : null));
              setRulesetsTotalCount((count) => (count > 0 ? count - 1 : count));
              closeModal();
            });
            break;
          case 'Risk':
            await riskService.deleteRisk(content.id).then((res) => {
              setRisks((risks) => (risks ? risks.filter((risk) => risk.id !== id) : null));
              setRisksTotalCount((count) => (count > 0 ? count - 1 : count));
              closeModal();
            });
            break;
          case 'Function':
            await riskFunctionService.deleteFunction(content.id).then((res) => {
              setRiskFunctions((riskFunctions) =>
                riskFunctions ? riskFunctions.filter((riskFunction) => riskFunction.id !== id) : null,
              );
              setRiskFunctionsTotalCount((count) => (count > 0 ? count - 1 : count));
              closeModal();
            });
            break;
        }
      }
    },
    [closeModal, riskFunctionService, riskService, rulesetService, trashContent],
  );

  const restoreContent = useCallback(
    async (id: string, type: 'Ruleset' | 'Risk' | 'Function') => {
      const content = trashContent?.find((c) => c.id === id);
      if (content) {
        switch (type) {
          case 'Ruleset':
            await rulesetService.restoreRuleset(content.id).then((res) => {
              setRulesets((rulesets) => (rulesets ? rulesets.filter((ruleset) => ruleset.id !== id) : null));
              setRulesetsTotalCount((count) => (count > 0 ? count - 1 : count));
              closeModal();
            });
            break;
          case 'Risk':
            await riskService.restoreRisk(content.id).then((res) => {
              setRisks((risks) => (risks ? risks.filter((risk) => risk.id !== id) : null));
              setRisksTotalCount((count) => (count > 0 ? count - 1 : count));
              closeModal();
            });
            break;
          case 'Function':
            await riskFunctionService.restoreFunction(content.id).then((res) => {
              setRiskFunctions((riskFunctions) =>
                riskFunctions ? riskFunctions.filter((riskFunction) => riskFunction.id !== id) : null,
              );
              setRiskFunctionsTotalCount((count) => (count > 0 ? count - 1 : count));
              closeModal();
            });
            break;
        }
      }
    },
    [closeModal, riskFunctionService, riskService, rulesetService, trashContent],
  );

  const massDeleteContent = useCallback(
    async (ids: string[]) => {
      const content = trashContent?.filter((c) => c.id in ids);
      if (content) {
        const deleteRulesets = content.filter((c) => c.type === 'Ruleset');
        const deleteRisks = content.filter((c) => c.type === 'Risk');
        const deleteFunctions = content.filter((c) => c.type === 'Function');

        if (deleteRulesets.length) {
          await rulesetService.deleteRulesets(deleteRulesets.map((r) => r.id)).then((res) => {
            setRulesets((rulesets) =>
              rulesets ? rulesets.filter((ruleset) => !deleteRulesets.some((r) => ruleset.id === r.id)) : null,
            );
            setRulesetsTotalCount((count) => (count > 0 ? count - deleteRulesets.length : count));
          });
        }
        if (deleteRisks.length) {
          await riskService.deleteRisks(deleteRisks.map((r) => r.id)).then((res) => {
            setRisks((risks) => (risks ? risks.filter((risk) => !deleteRisks.some((r) => risk.id === r.id)) : null));
            setRisksTotalCount((count) => (count > 0 ? count - deleteRisks.length : count));
          });
        }
        if (deleteFunctions.length) {
          await riskFunctionService.deleteFunctions(deleteFunctions.map((r) => r.id)).then((res) => {
            setRiskFunctions((riskFunctions) =>
              riskFunctions
                ? riskFunctions.filter((riskFunction) => !deleteFunctions.some((rf) => riskFunction.id === rf.id))
                : null,
            );
            setRiskFunctionsTotalCount((count) => (count > 0 ? count - deleteFunctions.length : count));
          });
        }
      }
    },
    [riskFunctionService, riskService, rulesetService, trashContent],
  );

  const openDeleteRuleset = useCallback((ruleset: RulesetDto) => {
    setModalContent(ruleset);
    setCurrentOpenModal('delete');
  }, []);

  const openDeleteRisk = useCallback((risk: RiskDto) => {
    setModalContent(risk);
    setCurrentOpenModal('delete');
  }, []);

  const openDeleteRiskFunction = useCallback((riskFunction: RiskFunctionDto) => {
    setModalContent(riskFunction);
    setCurrentOpenModal('delete');
  }, []);

  const openRestoreRuleset = useCallback((ruleset: RulesetDto) => {
    setModalContent(ruleset);
    setCurrentOpenModal('restore');
  }, []);

  const openRestoreRisk = useCallback((risk: RiskDto) => {
    setModalContent(risk);
    setCurrentOpenModal('restore');
  }, []);

  const openRestoreRiskFunction = useCallback((riskFunction: RiskFunctionDto) => {
    setModalContent(riskFunction);
    setCurrentOpenModal('restore');
  }, []);

  const openDeleteContent = useCallback(
    (content: any) => {
      switch (content.type) {
        case 'Ruleset':
          openDeleteRuleset(content);
          break;
        case 'Risk':
          openDeleteRisk(content);
          break;
        case 'Function':
          openDeleteRiskFunction(content);
          break;
      }
    },
    [openDeleteRisk, openDeleteRiskFunction, openDeleteRuleset],
  );

  const openRestoreContent = useCallback(
    (content: any) => {
      switch (content.type) {
        case 'Ruleset':
          openRestoreRuleset(content);
          break;
        case 'Risk':
          openRestoreRisk(content);
          break;
        case 'Function':
          openRestoreRiskFunction(content);
          break;
      }
    },
    [openRestoreRisk, openRestoreRiskFunction, openRestoreRuleset],
  );

  const getContentDetailsUrl = useCallback((content: TrashContentDto) => {
    switch (content.type) {
      case 'Ruleset':
        return `/rulesets/${content.id}`;
      case 'Risk':
        return `/risks/${content.id}`;
      case 'Function':
        return `/functions/${content.id}`;
    }
  }, []);

  const getRiskFunctionsFromRisk = useCallback(
    async (risk: RiskDto) => {
      const riskFunctions = await riskService.getFunctionsFromRisk(risk.id);
      return riskFunctions.data;
    },
    [riskService],
  );

  const getRulesetsFromRisk = useCallback(
    async (id: string) => {
      const result = await riskService.getRulesetsFromRisk(id);
      return result.data;
    },
    [riskService],
  );

  const getRisk = useCallback(
    async (id: string) => {
      const result = await riskService.getRisk(id);
      return result.data;
    },
    [riskService],
  );

  const getRisksFromRuleset = useCallback(
    async (ruleset: RulesetDto) => {
      const risksResult = await rulesetService.getRisksFromRuleset(ruleset.id);
      return risksResult.data;
    },
    [rulesetService],
  );

  const getRuleset = useCallback(
    async (id: string) => {
      const risksResult = await rulesetService.getRuleset(id);
      return risksResult.data;
    },
    [rulesetService],
  );

  const getRiskFunction = useCallback(
    async (id: string) => {
      const result = await riskFunctionService.getFunction(id);
      return result.data;
    },
    [riskFunctionService],
  );

  const getRisksFromRiskFunction = useCallback(
    async (id: string) => {
      const result = await riskFunctionService.getRisksFromFunction(id);
      return result.data;
    },
    [riskFunctionService],
  );

  useEffect(() => {
    fetchRulesets();
    fetchRisks();
    fetchRiskFunctions();
  }, [fetchRiskFunctions, fetchRisks, fetchRulesets]);

  return (
    <Stack spacing={ContentPageStackSpacing}>
      <Paper sx={ContentPagePaperElementStyle}>
        <TrashTable
          content={trashContent}
          totalCount={totalCount}
          massDelete={massDeleteContent}
          openDeleteContent={openDeleteContent}
          openRestoreContent={openRestoreContent}
          getDetailsUrl={getContentDetailsUrl}
        ></TrashTable>
      </Paper>
      {modalRuleset && (
        <DeleteModal<RulesetDto, undefined>
          title='Delete Ruleset'
          contentName='Ruleset'
          contentId={modalRuleset.id}
          open={currentOpenModal === 'delete'}
          onClose={closeModal}
          onDelete={(id) => deleteContent(id, 'Ruleset')}
          getContent={getRuleset}
        />
      )}
      {modalRisk && (
        <DeleteModal<RiskDto, RulesetDto>
          title='Delete Risk'
          contentName='Risk'
          parentsName='Rulesets'
          contentId={modalRisk.id}
          open={currentOpenModal === 'delete'}
          onClose={closeModal}
          onDelete={(id) => deleteContent(id, 'Risk')}
          getContent={getRisk}
          getParents={getRulesetsFromRisk}
        />
      )}
      {modalRiskFunction && (
        <DeleteModal<RiskFunctionDto, RiskDto>
          title='Delete Function'
          contentName='Function'
          parentsName='Risks'
          contentId={modalRiskFunction.id}
          open={currentOpenModal === 'delete'}
          onClose={closeModal}
          onDelete={(id) => deleteContent(id, 'Function')}
          getContent={getRiskFunction}
          getParents={getRisksFromRiskFunction}
        />
      )}
      {modalRuleset && (
        <RestoreModal<RulesetDto, undefined>
          title='Restore Ruleset'
          contentName='Ruleset'
          contentId={modalRuleset.id}
          open={currentOpenModal === 'restore'}
          onClose={closeModal}
          onRestore={(id) => restoreContent(id, 'Ruleset')}
          getContent={getRuleset}
        />
      )}
      {modalRisk && (
        <RestoreModal<RiskDto, RulesetDto>
          title='Restore Risk'
          contentName='Risk'
          parentsName='Rulesets'
          contentId={modalRisk.id}
          open={currentOpenModal === 'restore'}
          onClose={closeModal}
          onRestore={(id) => restoreContent(id, 'Risk')}
          getContent={getRisk}
        />
      )}
      {modalRiskFunction && (
        <RestoreModal<RiskFunctionDto, RiskDto>
          title='Restore Function'
          contentName='Function'
          parentsName='Risks'
          contentId={modalRiskFunction.id}
          open={currentOpenModal === 'restore'}
          onClose={closeModal}
          onRestore={(id) => restoreContent(id, 'Function')}
          getContent={getRiskFunction}
        />
      )}
    </Stack>
  );
};

export default TrashPage;
