import './index.scss';
import { Box, Card, CardContent, Divider, Stack } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { FunctionComponent, SyntheticEvent, useCallback, useState } from 'react';

import TabPanel from '../../components/tab-panel';
import ProfileDetailsComponent from './profile-details';
import ProfileHeaderComponent from './profile-header';
import ProfileSettingsComponent from './profile-settings';
import ProfileSettingsPasswordComponent from './profile-settings-password';

function a11yProps(index: number) {
  return {
    id: `user-profile-tab-${index}`,
    'aria-controls': `user-profile-tab-panel-${index}`,
  };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ProfileProps {}

const ProfilePage: FunctionComponent<ProfileProps> = ({ ...props }) => {
  const [value, setValue] = useState(0);
  //const { userData } = useSelectorUserData();

  const onTabChange = useCallback(
    (event: SyntheticEvent, newValue: number) => {
      setValue(newValue);
    },
    [setValue],
  );

  return (
    <Stack spacing={2}>
      <Card>
        <CardContent>
          <ProfileHeaderComponent></ProfileHeaderComponent>
        </CardContent>
        <Divider variant='middle'></Divider>

        <Box sx={{ width: '100%' }}>
          <Box sx={{}}>
            <Tabs centered value={value} onChange={onTabChange} aria-label='user profile tabs'>
              <Tab label='Profile' {...a11yProps(0)} />
              <Tab label='Account Settings' {...a11yProps(1)} />
            </Tabs>
          </Box>
        </Box>
      </Card>
      <div className='divider-box'></div>
      <Card>
        <CardContent>
          <Box>
            <TabPanel value={value} index={0}>
              <ProfileDetailsComponent />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Stack spacing={2}>
                <ProfileSettingsComponent />
                <ProfileSettingsPasswordComponent />
              </Stack>
            </TabPanel>
          </Box>
        </CardContent>
      </Card>
    </Stack>
  );
};

export default ProfilePage;
