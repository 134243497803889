import { ReactNode } from 'react';
import { Route, Routes } from 'react-router-dom';

import ProtectedRoute from '../components/protected-route';
import { RouteMenuElement } from './route-menu-element';

const defaultRedirectPath = '/login';

export function generateRoute(routeElements: RouteMenuElement[], parent?: RouteMenuElement): ReactNode {
  return routeElements
    .map((route, index) => {
      if (route.element) {
        return route.index ? (
          <Route
            index
            path={route.path}
            element={
              <ProtectedRoute
                pageKey={route.path}
                redirectPath={route.access?.redirectPath ?? defaultRedirectPath}
                roles={route.access?.roles}
                permissions={route.access?.permissions}
              >
                {route.element}
              </ProtectedRoute>
            }
            key={index}
          />
        ) : (
          <Route
            path={route.path}
            element={
              <ProtectedRoute
                pageKey={route.path}
                redirectPath={route.access?.redirectPath ?? defaultRedirectPath}
                roles={route.access?.roles}
                permissions={route.access?.permissions}
              >
                {route.element}
              </ProtectedRoute>
            }
            key={index}
          >
            {route.children && generateRoute(route.children, route)}
          </Route>
        );
      }
      if (route.children) {
        return generateRoute(route.children);
      }
      return undefined;
    })
    .filter(function (elem) {
      return elem !== undefined;
    });
}

export function generateRoutes(routeElements: RouteMenuElement[]) {
  return <Routes>{generateRoute(routeElements)}</Routes>;
}
