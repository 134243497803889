export const TableButtonsMinWidth = 120;

export const TableRowHeight = 80;

export const TableAutocompleteFieldWidth = 400;

export const TableAutocompleteButtonWidth = 25;

export const MainTableMinWidth = 750;

export const ContentRowsPerPageOptions = [10, 25, 50, 100];

export const ContentRowsPerPageDefault = 25;

export const UserRowsPerPageOptions = [10, 25, 50, 100];

export const UserRowsPerPageDefault = 25;

export const FunctionValueTableFieldWidth = 150;

export const FunctionValueTableActionsWidth = 100;

export const FunctionValueTableMinWidth = 500;

export const TemplateApplyButtonMinHeight = 55;
