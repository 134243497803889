import { BusinessProcessesRisksReportDto } from '@/services/dto/reports/business-processes-risks-report.dto';
import { faFileCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, CircularProgress, Paper, Stack, Typography } from '@mui/material';
import { Chart as ChartJS } from 'chart.js';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Doughnut, getElementAtEvent } from 'react-chartjs-2';

import { DASHBOARD_DOUBLE_CLICK_DEPLAY } from './constants';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ProcessRiskCountChartBoxProps {
  businessProcessesRisksReportData: BusinessProcessesRisksReportDto[] | undefined;
  height?: string | number;
  setBusinessProcessesIdFilter: (businessProcessesId: string, businessProcessDisplayName: string) => void;
  onDoubleClick: (extraParam?: { key: string; value: string }) => void;
}

const ProcessRiskCountChartBox = ({
  businessProcessesRisksReportData,
  height,
  setBusinessProcessesIdFilter,
  onDoubleClick,
}: ProcessRiskCountChartBoxProps) => {
  const risksForBpChartLabels = useMemo(
    () => businessProcessesRisksReportData?.map((data) => data.businessProcessName),
    [businessProcessesRisksReportData],
  );

  const risksForBpChartDataset = useMemo(
    () => [
      {
        label: 'Risks',
        data: businessProcessesRisksReportData?.map((data) => data.riskCount),
        backgroundColor: ['#003f5c', '#2f4b7c', '#665191', '#a05195', '#d45087', '#f95d6a', '#ff7c43', '#ffa600'],
        borderColor: [
          'rgba(0, 0, 0, 0)',
          'rgba(0, 0, 0, 0)',
          'rgba(0, 0, 0, 0)',
          'rgba(0, 0, 0, 0)',
          'rgba(0, 0, 0, 0)',
          'rgba(0, 0, 0, 0)',
          'rgba(0, 0, 0, 0)',
          'rgba(0, 0, 0, 0)',
        ],
        borderWidth: 1,
        datalabels: {
          labels: {
            // name: {
            //   align: () => {
            //     return 'center';
            //   },
            //   offset: 10,
            //   font: { size: 14 },
            //   color: (ctx: any) => {
            //     return 'white';
            //   },
            //   formatter: (value: any, ctx: any) => {
            //      if (risksForBpChartLabels) {
            //       return
            //       // risksForBpChartLabels[ctx.dataIndex]
            //      }
            //      return
            //   },
            // },
            value: {
              align: 'center',
              borderWidth: 2,
              borderRadius: 4,
              color: (ctx: any) => {
                const value = ctx.dataset.data[ctx.dataIndex];
                return value > 0 ? 'white' : 'rgba(0, 0, 0, 0)';
              },
              formatter: (value: any, ctx: any) => {
                if (risksForBpChartLabels) {
                  return `${risksForBpChartLabels[ctx.dataIndex]}\n${value}`;
                }
              },
              padding: 4,
              textAlign: 'center',
            },
          },
        },
      },
    ],
    [businessProcessesRisksReportData, risksForBpChartLabels],
  );
  const risksForBpChartData = useMemo(() => {
    return {
      labels: risksForBpChartLabels,
      datasets: risksForBpChartDataset,
    };
  }, [risksForBpChartDataset, risksForBpChartLabels]);

  const loadingState = useMemo(() => {
    if (businessProcessesRisksReportData === undefined) {
      return 'loading';
    }
    if (businessProcessesRisksReportData.length > 0) {
      return 'loaded';
    }
    return 'empty';
  }, [businessProcessesRisksReportData]);

  //businessProcess onClick event
  const businessProcessRiskCountChartRef = useRef<ChartJS | any>();
  const businessProcessRiskChartSingleClick = useCallback(
    (event: React.MouseEventHandler<HTMLCanvasElement> | any) => {
      if (businessProcessesRisksReportData) {
        /// @FIXME: onClick event type vs. getElementAtEvent type (use any)
        const elementClicked = businessProcessRiskCountChartRef.current
          ? getElementAtEvent(businessProcessRiskCountChartRef.current, event)
          : undefined;
        if (elementClicked && elementClicked.length > 0) {
          const index = elementClicked[0].index;
          const datasetIndex = elementClicked[0].datasetIndex;
          if (datasetIndex === 0) {
            ///< dataset 0 is 'Risks' data
            const businessProcessId = businessProcessesRisksReportData[index]?.businessProcessId;
            const businessProcessDisplayName = businessProcessesRisksReportData[index]?.businessProcessDisplayName;
            setBusinessProcessesIdFilter(businessProcessId, businessProcessDisplayName);
          }
        }
      }
    },
    [businessProcessesRisksReportData, setBusinessProcessesIdFilter],
  );
  const businessProcessRiskChartDoubleClick = useCallback(
    (event: React.MouseEventHandler<HTMLCanvasElement> | any) => {
      if (businessProcessesRisksReportData) {
        /// @FIXME: onClick event type vs. getElementAtEvent type (use any)
        const elementClicked = businessProcessRiskCountChartRef.current
          ? getElementAtEvent(businessProcessRiskCountChartRef.current, event)
          : undefined;
        if (elementClicked && elementClicked.length > 0) {
          const index = elementClicked[0].index;
          const datasetIndex = elementClicked[0].datasetIndex;
          if (datasetIndex === 0) {
            ///< dataset 0 is 'Risks' data
            const businessProcessId = businessProcessesRisksReportData[index]?.businessProcessId;
            onDoubleClick({ key: 'bpId', value: businessProcessId });
          }
        }
      }
    },
    [businessProcessesRisksReportData, onDoubleClick],
  );

  const [clickCounter, setClickCounter] = useState<{
    event: React.MouseEventHandler<HTMLCanvasElement> | any | undefined;
    counter: number;
  }>({ event: undefined, counter: 0 });
  const businessProcessRiskChartOnClick = useCallback((event: React.MouseEventHandler<HTMLCanvasElement> | any) => {
    setClickCounter((c) => {
      // inc. click counter and pass event
      return { event, counter: c.counter + 1 };
    });
  }, []);

  useEffect(() => {
    // has click event
    if (clickCounter.event && clickCounter.counter > 0) {
      const timer = setTimeout(() => {
        if (clickCounter.counter === 1) businessProcessRiskChartSingleClick(clickCounter.event);
        else if (clickCounter.counter === 2) businessProcessRiskChartDoubleClick(clickCounter.event);
        setClickCounter({ event: undefined, counter: 0 });
      }, DASHBOARD_DOUBLE_CLICK_DEPLAY);

      return () => {
        clearTimeout(timer);
      };
    }
  });

  const options = {
    responsive: true,
    cutout: 70,
    plugins: {
      legend: {
        // labels: {
        //   boxWidth: 20,
        // },
        display: false,
      },
      datalabels: {
        font: {
          size: 12,
          lineHeight: 1.3,
        },
      },
    },
  };

  return (
    <Paper elevation={3} sx={{ p: 2 }}>
      <Typography sx={{ flex: '1 1 100%', mb: 2 }} variant='body1' id='processRiskCountChartBoxTitle' component='div'>
        Process/Risk Count
      </Typography>
      <Box sx={{ pt: '2rem', height: height, display: 'flex', justifyContent: 'center' }}>
        {loadingState === 'loading' && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {loadingState === 'empty' && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Stack spacing={1}>
              <Typography align='center'>No Data</Typography>
              <FontAwesomeIcon size='5x' icon={faFileCircleXmark} />
            </Stack>
          </Box>
        )}
        {businessProcessesRisksReportData && businessProcessesRisksReportData.length > 0 && (
          <Doughnut
            data={risksForBpChartData as any}
            options={options as any} ///< @NOTE: use any for plugin options
            ref={businessProcessRiskCountChartRef}
            onClick={businessProcessRiskChartOnClick}
          />
        )}
      </Box>
    </Paper>
  );
};

export default ProcessRiskCountChartBox;
