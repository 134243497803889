import { ConfirmOptions } from 'material-ui-confirm';

export const dialogActionButtonOptions: ConfirmOptions = {
  confirmationText: 'Yes',
  cancellationText: 'Cancel',
  confirmationButtonProps: { variant: 'contained', color: 'success' },
  cancellationButtonProps: { variant: 'contained', color: 'error' },
};

export function finalizeDialogOptions(canFinalize: boolean): ConfirmOptions {
  return { ...dialogActionButtonOptions, confirmationText: canFinalize ? 'Yes' : 'Ok' };
}

export function releaseDialogOptions(canRelease: boolean): ConfirmOptions {
  return { ...dialogActionButtonOptions, confirmationText: canRelease ? 'Yes' : 'Ok' };
}

export function deleteDialogOptions(canDelete: boolean): ConfirmOptions {
  return { ...dialogActionButtonOptions, confirmationText: canDelete ? 'Yes' : 'Ok' };
}
