import { Dialog, DialogTitle, DialogContent, Grid, TextField, DialogActions, GridSize } from '@mui/material';
import { ReactNode, useCallback, useEffect, useMemo } from 'react';
import {
  useForm,
  FieldValues,
  UseFormRegister,
  Control,
  FieldErrors,
  Controller,
  UseFormSetValue,
} from 'react-hook-form';

import CreateEditModalActionButtons from '../create-edit-modal-action-buttons';

interface ContentBaseDto {
  id: string;
  name: string;
  displayName: string;
  description: string;
}
interface UpdateBaseDto {
  name?: string;
  displayName?: string;
  description?: string;
}
export interface UpdateFormData {
  name?: string;
  displayName?: string;
  description?: string;
}

interface UpdateModalProps<ContentDto extends ContentBaseDto, FormData extends UpdateFormData = any> {
  title: string;
  data: ContentDto;
  open: boolean;
  onClose: () => void;
  onUpdate: (id: string, data: FieldValues, content: ContentDto) => void;
  extend?: {
    extendForm: (
      register: UseFormRegister<FormData | Record<string, any>>,
      control: Control<FormData | Record<string, any>>,
      errors: FieldErrors<FormData | Record<string, any>>,
    ) => { xs?: boolean | GridSize; field: ReactNode }[];
    defaultValues: FormData;
    initForm: (
      data: ContentDto,
      register: UseFormRegister<FormData | Record<string, any>>,
      setValue: UseFormSetValue<FormData | Record<string, any>>,
    ) => void;
  };
}

export function UpdateModal<
  ContentDto extends ContentBaseDto,
  UpdateDto extends UpdateBaseDto,
  FormData extends Record<string, any> | UpdateFormData = any,
>({ title, data, open, onClose, onUpdate, extend /*...props*/ }: UpdateModalProps<ContentDto, FormData>) {
  const {
    register,
    setValue,
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm<FormData | Record<string, any>>({
    defaultValues: {
      name: data.name,
      displayName: data.displayName,
      description: data.description,
      ...extend?.defaultValues,
    },
  });

  const ExtendForm = useMemo(() => {
    return extend?.extendForm(register, control, errors).map((field, index) => {
      return (
        <Grid item xs={field.xs ?? 12} key={index}>
          {field.field}
        </Grid>
      );
    });
  }, [extend, register, control, errors]);

  const onSubmit = useCallback(
    (values: FieldValues) => {
      onUpdate(
        data.id,
        {
          ...values,
          name: data.name !== values.name ? values.name : undefined,
          displayName: data.displayName !== values.displayName ? values.displayName : undefined,
          description: data.description !== values.description ? values.description : undefined,
        },
        data,
      );
      onClose();
      reset();
    },
    [data, onClose, onUpdate, reset],
  );

  useEffect(() => {
    setValue('name', data.name);
    setValue('displayName', data.displayName);
    setValue('description', data.description);
    if (extend) {
      extend.initForm(data, register, setValue);
    }
  }, [data, extend, register, setValue]);

  const registerOptions = {
    name: { required: true },
    displayName: { required: true },
    description: undefined,
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
              <Controller
                name='name'
                control={control}
                rules={registerOptions.name}
                render={({ field }) => (
                  <TextField
                    placeholder={data.name}
                    margin='dense'
                    label='ID*'
                    fullWidth
                    error={!!errors.name}
                    helperText={errors.name?.message?.toString()}
                    {...field}
                    onChange={(event) => {
                      field.onChange(event.target.value.toUpperCase().trim());
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name='displayName'
                control={control}
                rules={registerOptions.displayName}
                render={({ field }) => (
                  <TextField
                    placeholder={data.displayName}
                    margin='dense'
                    label='Name*'
                    fullWidth
                    error={!!errors.displayName}
                    helperText={errors.displayName?.message?.toString()}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name='description'
                control={control}
                rules={registerOptions.description}
                render={({ field }) => (
                  <TextField
                    placeholder={data.description}
                    multiline={true}
                    minRows={3}
                    margin='dense'
                    label='Description'
                    fullWidth
                    error={!!errors.description}
                    helperText={errors.description?.message?.toString()}
                    {...field}
                  />
                )}
              />
            </Grid>
            {ExtendForm}
          </Grid>
        </DialogContent>
        <DialogActions>
          <CreateEditModalActionButtons onClose={onClose} />
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default UpdateModal;
