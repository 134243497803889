import { faFileZipper, faCloudArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Stack } from '@mui/material';
import { useCallback, useMemo } from 'react';

import { RulesetDto } from '../../../services/dto/rulesets/ruleset.dto';
import { CfExportService } from '../../../services/export/cf-export.service';
import { TableAutocompleteFieldWidth } from '../../../styles/content-tables';

interface ExportCfControlsProps {
  selectedRulesets: RulesetDto[];
  downloadFile: (blob: Blob, fileName: string) => void;
  formatFilename: (name: string, suffix: string, extension: string) => string;
  setAlert: React.Dispatch<
    React.SetStateAction<{
      success?: string | undefined;
      error?: string | undefined;
    }>
  >;
}

export function ExportCfControls({
  selectedRulesets,
  downloadFile,
  formatFilename,
  setAlert,
  ...props
}: ExportCfControlsProps) {
  const cfExportService = useMemo(() => new CfExportService(), []);

  const onCadExport = useCallback(async () => {
    if (selectedRulesets.length) {
      const blob = await cfExportService.exportCAD(selectedRulesets.map((ruleset) => ruleset.id));
      downloadFile(blob, formatFilename(selectedRulesets[0].name, 'CF_CAD', 'csv'));
    }
  }, [selectedRulesets, cfExportService, downloadFile, formatFilename]);
  const onCattExport = useCallback(async () => {
    if (selectedRulesets.length) {
      const blob = await cfExportService.exportCATT(selectedRulesets.map((ruleset) => ruleset.id));
      downloadFile(blob, formatFilename(selectedRulesets[0].name, 'CF_CATT', 'csv'));
    }
  }, [selectedRulesets, cfExportService, downloadFile, formatFilename]);

  const onCavdExport = useCallback(async () => {
    if (selectedRulesets.length) {
      const blob = await cfExportService.exportCAVD(selectedRulesets.map((ruleset) => ruleset.id));
      downloadFile(blob, formatFilename(selectedRulesets[0].name, 'CF_CAVD', 'csv'));
    }
  }, [selectedRulesets, cfExportService, downloadFile, formatFilename]);
  const onCavttExport = useCallback(async () => {
    if (selectedRulesets.length) {
      const blob = await cfExportService.exportCAVTT(selectedRulesets.map((ruleset) => ruleset.id));
      downloadFile(blob, formatFilename(selectedRulesets[0].name, 'CF_CAVTT', 'csv'));
    }
  }, [selectedRulesets, cfExportService, downloadFile, formatFilename]);

  const onCcdExport = useCallback(async () => {
    if (selectedRulesets.length) {
      const blob = await cfExportService.exportCCD(selectedRulesets.map((ruleset) => ruleset.id));
      downloadFile(blob, formatFilename(selectedRulesets[0].name, 'CF_CCD', 'csv'));
    }
  }, [selectedRulesets, cfExportService, downloadFile, formatFilename]);
  const onCcttExport = useCallback(async () => {
    if (selectedRulesets.length) {
      const blob = await cfExportService.exportCCTT(selectedRulesets.map((ruleset) => ruleset.id));
      downloadFile(blob, formatFilename(selectedRulesets[0].name, 'CF_CCTT', 'csv'));
    }
  }, [selectedRulesets, cfExportService, downloadFile, formatFilename]);

  const onCcvdExport = useCallback(async () => {
    if (selectedRulesets.length) {
      const blob = await cfExportService.exportCCVD(selectedRulesets.map((ruleset) => ruleset.id));
      downloadFile(blob, formatFilename(selectedRulesets[0].name, 'CF_CCVD', 'csv'));
    }
  }, [selectedRulesets, cfExportService, downloadFile, formatFilename]);
  const onCcvttExport = useCallback(async () => {
    if (selectedRulesets.length) {
      const blob = await cfExportService.exportCCVTT(selectedRulesets.map((ruleset) => ruleset.id));
      downloadFile(blob, formatFilename(selectedRulesets[0].name, 'CF_CCVTT', 'csv'));
    }
  }, [selectedRulesets, cfExportService, downloadFile, formatFilename]);

  const onCfZipExport = useCallback(async () => {
    if (selectedRulesets.length) {
      const blob = await cfExportService.exportZip(selectedRulesets.map((ruleset) => ruleset.id));
      downloadFile(blob, formatFilename(selectedRulesets[0].name, 'CF', 'zip'));
    }
  }, [selectedRulesets, cfExportService, downloadFile, formatFilename]);

  return (
    <Stack spacing={2} sx={{ width: 2.5 * TableAutocompleteFieldWidth }}>
      <Button
        variant={'contained'}
        color='success'
        // startIcon={}
        disabled={!selectedRulesets.length}
        onClick={onCfZipExport}
        startIcon={<FontAwesomeIcon icon={faFileZipper} />}
      >
        Export all files
      </Button>
      <Stack direction='row' spacing={2}>
        <Button
          fullWidth
          variant={'contained'}
          color='primary'
          // startIcon={}
          disabled={!selectedRulesets.length}
          onClick={onCattExport}
          startIcon={<FontAwesomeIcon icon={faCloudArrowDown} />}
        >
          Export Authorization ID Header
        </Button>
        <Button
          fullWidth
          variant={'contained'}
          color='info'
          // startIcon={}
          disabled={!selectedRulesets.length}
          onClick={onCadExport}
          startIcon={<FontAwesomeIcon icon={faCloudArrowDown} />}
        >
          Export Authorization ID Definition
        </Button>
      </Stack>
      <Stack direction='row' spacing={2}>
        <Button
          fullWidth
          variant={'contained'}
          color='info'
          // startIcon={}
          disabled={!selectedRulesets.length}
          onClick={onCavttExport}
          startIcon={<FontAwesomeIcon icon={faCloudArrowDown} />}
        >
          Export Authorization Variant Header
        </Button>
        <Button
          fullWidth
          variant={'contained'}
          color='info'
          // startIcon={}
          disabled={!selectedRulesets.length}
          onClick={onCavdExport}
          startIcon={<FontAwesomeIcon icon={faCloudArrowDown} />}
        >
          Export Authorization Variant Definition
        </Button>
      </Stack>
      <Stack direction='row' spacing={2}>
        <Button
          fullWidth
          variant={'contained'}
          color='info'
          // startIcon={}
          disabled={!selectedRulesets.length}
          onClick={onCcttExport}
          startIcon={<FontAwesomeIcon icon={faCloudArrowDown} />}
        >
          Export Combination ID Header
        </Button>
        <Button
          fullWidth
          variant={'contained'}
          color='info'
          // startIcon={}
          disabled={!selectedRulesets.length}
          onClick={onCcdExport}
          startIcon={<FontAwesomeIcon icon={faCloudArrowDown} />}
        >
          Export Combination ID Definition
        </Button>
      </Stack>
      <Stack direction='row' spacing={2}>
        <Button
          fullWidth
          variant={'contained'}
          color='info'
          // startIcon={}
          disabled={!selectedRulesets.length}
          onClick={onCcvttExport}
          startIcon={<FontAwesomeIcon icon={faCloudArrowDown} />}
        >
          Export Combination Variant Header
        </Button>
        <Button
          fullWidth
          variant={'contained'}
          color='info'
          // startIcon={}
          disabled={!selectedRulesets.length}
          onClick={onCcvdExport}
          startIcon={<FontAwesomeIcon icon={faCloudArrowDown} />}
        >
          Export Combination Variant Definition
        </Button>
      </Stack>
    </Stack>
  );
}
