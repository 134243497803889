import { CreateUserDto } from './dto/users/create-user.dto';
import { UpdateUserPasswordDto } from './dto/users/update-user-password.dto';
import { UpdateUserDto } from './dto/users/update-user.dto';
import { UserDto } from './dto/users/user.dto';
import { HttpService } from './http.service';

export class UserService {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(private httpService: HttpService = new HttpService('users')) {}

  async getUsers(params?: {
    search?: string;
    page?: {
      offset?: number;
      limit?: number;
    };
    //sort?: string;
  }) {
    const response = await this.httpService.get<UserDto[]>(undefined, params);
    return response.data;
  }
  async getUser(userId: string) {
    const response = await this.httpService.get<UserDto>(userId);
    return response.data;
  }

  async createUser(body: CreateUserDto) {
    const response = await this.httpService.post<UserDto>('', body);
    return response.data;
  }
  async updateUser(userId: string, body: UpdateUserDto) {
    const response = await this.httpService.patchNoContent(userId, body);
    return response.data;
  }
  async updateUserPassword(userId: string, body: UpdateUserPasswordDto) {
    const response = await this.httpService.patchNoContent(`${userId}/password`, body);
    return response.data;
  }

  async deleteUser(userId: string) {
    const response = await this.httpService.delete(userId);
    return response.data;
  }
}
