import { LockReset } from '@mui/icons-material';
import { Box, Button, CardActions, CardContent, CardHeader, TextField, Stack, Alert } from '@mui/material';
import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';

import { useSelectorUserData } from '../../../redux/selector';
import { UserService } from '../../../services/user.service';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ProfileSettingsPasswordProps {}

const ProfileSettingsPasswordComponent: FunctionComponent<ProfileSettingsPasswordProps> = ({ ...props }) => {
  const { userData } = useSelectorUserData();
  const userService = useMemo(() => new UserService(), []);

  const {
    register,
    setValue,
    handleSubmit,
    setError,
    watch,
    formState: { errors },
  } = useForm<{
    currentPassword: string;
    newPassword: string;
    newPasswordConfirm: string;
  }>();
  const [submitAlert, setSubmitAlert] = useState<{
    severity: 'error' | 'success';
    message: string;
  }>();

  const onSubmit = useCallback(
    async (data: FieldValues) => {
      setSubmitAlert(undefined);

      if (userData) {
        if (data.newPassword !== data.newPasswordConfirm) {
          setError('newPasswordConfirm', {
            type: 'manual',
            message: 'Password must match',
          });
          return;
        }

        userService
          .updateUserPassword(userData.id, {
            oldPassword: data.currentPassword,
            password: data.newPassword,
          })
          .then((res) => {
            setSubmitAlert({ severity: 'success', message: 'Set New Password' });
          })
          .catch((err) => {
            setSubmitAlert({ severity: 'error', message: 'Set Password Error' });
          });
      }
    },
    [userData, userService, setError],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CardHeader title='Change Password' />
      <CardContent>
        <Box sx={{ m: -1.5 }}>
          <Stack spacing={2}>
            <TextField
              fullWidth
              type='password'
              label='Current Password'
              {...register('currentPassword', { required: true })}
              error={!!errors.currentPassword}
              helperText={errors.currentPassword?.message}
            />
            <TextField
              fullWidth
              type='password'
              label='New Password'
              {...register('newPassword', { required: true })}
              error={!!errors.newPassword}
              helperText={errors.newPassword?.message}
            />
            <TextField
              fullWidth
              type='password'
              label='New Password (Confirm)'
              {...register('newPasswordConfirm', { required: true })}
              error={!!errors.newPasswordConfirm}
              helperText={errors.newPasswordConfirm?.message}
            />
            <Alert sx={{ mt: 2, display: submitAlert?.severity ? 'block' : 'none' }} severity={submitAlert?.severity}>
              {submitAlert?.message}
            </Alert>
          </Stack>
        </Box>
      </CardContent>
      <CardActions sx={{ padding: '4px', justifyContent: 'flex-end' }}>
        <Button startIcon={<LockReset />} type='submit' variant='contained'>
          Change Password
        </Button>
      </CardActions>
    </form>
  );
};

export default ProfileSettingsPasswordComponent;
